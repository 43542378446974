import React from 'react';

import { formatPrice } from '../../utils/util';

const DurationAndPriceByOffer = (props) => {
  const { duration, durationType, price, bold } = props;

  return (
    <span style={bold ? { fontWeight: 'bold' } : {}}>
      {duration} {durationType} {formatPrice(price)}
    </span>
  );
};

export default DurationAndPriceByOffer;
