import React, { useEffect } from 'react';
import { Grid, Message } from 'semantic-ui-react';

import HeaderBar from '../main/HeaderBar';

import * as ga from '../../utils/googleAnalytics';
import t from '../../locales/fi';
import Footer from '../main/Footer';

const FailurePage = () => {
  useEffect(() => {
    ga.googleAnalyticsPageView();
  }, []);

  return (
    <div className="layout-container">
      <div id="failurePage">
        <HeaderBar />

        <Grid centered stackable className="padding">
          <Grid.Row>
            <Message negative>
              <p>
                Harmi, tarjousta ei löydy. Katso{' '}
                <a
                  href={t.OTAVAMEDIA_ORDER_MAGAZINES_LINK}
                  className="clickable-link"
                >
                  tästä
                </a>{' '}
                uusi tarjous tai ota yhteys{' '}
                <a href={t.OTAVAMEDIA_CUSTOMER_SERVICE_URL}>
                  asiakaspalveluun.
                </a>
              </p>
            </Message>
          </Grid.Row>
        </Grid>
      </div>
      <div className="footer-section">
        <Footer />
      </div>
    </div>
  );
};

export default FailurePage;
