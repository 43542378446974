import React from 'react';

const ArrowIcon = () => (
  <i className="icon" style={{ float: 'left', height: '100%' }}>
    <img
      style={{ width: 14, height: 14, position: 'absolute' }}
      alt="arrow-icon"
      src="https://otavamedia.fi/wp-content/themes/om-otavamedia-theme/assets/img/icon-arrow.svg"
    />
  </i>
);

export default ArrowIcon;
