import { useEffect, useRef, useState } from 'react';

const useElementOnScreen = (elements) => {
  const targets = elements.map((e) => ({
    name: e.name,
    targetRef: useRef(null),
  }));
  const options = {
    root: null,
    rootMargin: '0px 0px -50% 0px', // fire event when target reaches middle of viewport
    threshold: 1.0,
  };
  const [observedElements, setObservedElements] = useState([]);

  const callBack = (entries) => {
    const result = entries.filter((e) => e.isIntersecting);
    if (result.length > 0) {
      setObservedElements(result.map((e) => e.target.getAttribute('id')));
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callBack, options);
    targets.forEach((t) => {
      if (t.targetRef.current) observer.observe(t.targetRef.current);
    });

    return () => {
      targets.forEach((t) => {
        if (t.targetRef.current) observer.unobserve(t.targetRef.current);
      });
    };
  }, []);

  return [targets, observedElements];
};

export const getTargetRefByName = (targets, name) => {
  const target = targets.find((t) => t.name === name);
  if (target) return target.targetRef;
  return null;
};

export default useElementOnScreen;
