import * as api from '../api/Api';
import {
  BACKEND_IN_MAINTENANCE_MODE,
  OFFER_FETCH_COMPLETE,
  OFFER_SEND_POLL,
  OFFER_SEND_POLL_FAILURE,
} from './actionTypes';

export const sendPollDetails = async (data, offerDispatch) => {
  offerDispatch({
    type: OFFER_SEND_POLL,
  });
  const response = await api.post('/poll', data);
  if (response.error) {
    // OTVMSUP-9, backend in maintenance mode
    if (response.status && response.status === 503) {
      offerDispatch({ type: BACKEND_IN_MAINTENANCE_MODE });
      return;
    }
    offerDispatch({
      type: OFFER_SEND_POLL_FAILURE,
    });
    return;
  }
  offerDispatch({
    type: OFFER_FETCH_COMPLETE,
  });
};
