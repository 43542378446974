import React from 'react';

const DurationAndPriceBySubscription = ({ subscription }) => {
  let durationText;
  if (subscription.months && parseInt(subscription.months, 10) > 0) {
    durationText = `${subscription.months} kk`;
  } else if (subscription.days && parseInt(subscription.days, 10) > 0) {
    durationText = `${subscription.days} päivää`;
  } else {
    durationText = `${subscription.issueQuantity} nroa`;
  }
  // priceAmountIncludingVat should be in form of "xx.xx"
  let price = parseFloat(subscription.priceAmountIncludingVat);
  price = Number.isInteger(price)
    ? price.toFixed(0)
    : price.toFixed(2).replace('.', ',');
  return (
    <b>
      {durationText} {price}
      {' €'}
    </b>
  );
};

export default DurationAndPriceBySubscription;
