import React from 'react';
import LoadingSpinner from './LoadingSpinner';

import {
  CONFIRM_SUBSCRIPTION_CONFIRM_SUCCESSFUL,
  CONFIRM_SUBSCRIPTION_CONFIRMING,
  CONFIRM_SUBSCRIPTION_FETCH_SUCCESSFUL,
} from '../../actions/actionTypes';
import { hardcodedSampleOfferingConfig } from '../../utils/util';

const shouldShowSampleMagazineLink = (unconfirmedSubscriptions) =>
  unconfirmedSubscriptions.some((uncSub) => {
    const { sSalesno } = uncSub.processable.entities.subscription;
    return hardcodedSampleOfferingConfig.HARDCODED_SALES_NUMBERS.includes(
      sSalesno,
    );
  });

const ConfirmSubscriptionStatus = (props) => {
  const {
    confirmSubscription: { state, unconfirmedSubscriptions },
  } = props;
  const loadingText = 'Tilaustasi vahvistetaan..';
  const infoText = 'Kiitos, tilauksesi on nyt vahvistettu.';

  switch (state) {
    case CONFIRM_SUBSCRIPTION_FETCH_SUCCESSFUL:
    case CONFIRM_SUBSCRIPTION_CONFIRMING:
      return <LoadingSpinner label={loadingText} textOnTop />;
    case CONFIRM_SUBSCRIPTION_CONFIRM_SUCCESSFUL:
      return (
        <>
          <p>{infoText}</p>
          {shouldShowSampleMagazineLink(unconfirmedSubscriptions) && (
            <p>
              <a href={hardcodedSampleOfferingConfig.SAMPLE_OFFERING_LINK}>
                Voit valita tilaukseesi kuuluvan etulehden täältä.
              </a>
            </p>
          )}
        </>
      );
    default:
      return null;
  }
};

export default ConfirmSubscriptionStatus;
