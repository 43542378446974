import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Checkbox, Form } from 'semantic-ui-react';

const CheckBoxField = (props) => {
  const { label, name, legal } = props;
  const { control } = useFormContext();
  const { field, fieldState } = useController({ control, name });

  return (
    <div className="terms-input mobilepadding">
      <Form.Field>
        <Checkbox
          label={label}
          name={field.name}
          onChange={(e, { checked }) => {
            field.onChange(checked);
          }}
          ref={field.ref}
        />
        {legal && (
          <>
            &nbsp;
            <a
              href="https://otavamedia.fi/tilaus-ja-kayttoehdot/"
              target="_blank"
              rel="noreferrer"
            >
              tilaus- ja käyttöehdot
            </a>
          </>
        )}
      </Form.Field>
      {fieldState.error && (
        <span className="errorText">{fieldState.error.message}</span>
      )}
    </div>
  );
};

export default CheckBoxField;
