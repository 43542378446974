import React from 'react';

import PopupTrigger from './PopupTrigger';

import { POPUP_TYPE } from '../../../utils/util';

const GiftImage = ({ gift, className }) => (
  <div id="magazineImage" className={className}>
    <PopupTrigger type={POPUP_TYPE.GIFT_IMAGE} gift={gift} />
  </div>
);

export default GiftImage;
