import * as ga from '../utils/googleAnalytics';
import * as api from '../api/Api';
import {
  checkIfSingleBrand,
  getErrorText,
  hardcodedTestPaymentMethodsByOffering,
} from '../utils/util';
import {
  BACKEND_IN_MAINTENANCE_MODE,
  EMPTY_CUSTOMER,
  OFFER_ASK_LUKULAHJA,
  OFFER_ASK_TYRKYTE,
  OFFER_FETCH,
  OFFER_FETCH_COMPLETE,
  OFFER_FETCH_FAILURE,
  OFFER_FETCH_LUKULAHJA_FAILURE,
  OFFER_FETCH_TYRKYTE,
  OFFER_FETCH_TYRKYTE_FAILURE,
  OFFER_INITIAL_STATE,
  OFFER_SET_CUSTOMER,
  OFFER_SET_PARAMS,
  OFFER_SHOW_POLL,
} from './actionTypes';
import t from '../locales/fi';

/**
 * Used by LandingPage.jsx and TallennusPage.jsx.
 *
 * @param params
 * @param customer
 * @param offerDispatch Context API offerDispatch function
 * @return {function(*, *, *): Promise<undefined>}
 */
export const getOffer = async (params, customer, offerDispatch) => {
  offerDispatch({
    type: OFFER_SET_PARAMS,
    payload: {
      params,
    },
  });
  // If lukulahja, ask tyrkyte straight away
  if (params.offeringId === 'lukulahja') {
    offerDispatch({ type: OFFER_ASK_LUKULAHJA });
    return;
  }
  // Get offer from eapi
  let response = null;
  try {
    offerDispatch({ type: OFFER_FETCH });
    response = await api.get('/campaign/offer', params);
    if (response.error) {
      // OTVMSUP-9, backend in maintenance mode
      if (response.status && response.status === 503) {
        offerDispatch({ type: BACKEND_IN_MAINTENANCE_MODE });
        return;
      }
      const errorText = getErrorText(response.errorCode);
      offerDispatch({
        type: OFFER_FETCH_FAILURE,
        payload: {
          errorText,
        },
      });
      return;
    }
  } catch (e) {
    offerDispatch({
      type: OFFER_FETCH_FAILURE,
      payload: {
        error: e,
        errorText:
          params.host !== 'tallennus'
            ? t.DEFAULT_ERROR_HTML
            : t.COULD_NOT_FIND_OFFER,
        offeringId: params.offeringId,
      },
    });
    return;
  }
  const hardcodedPaymentMethods
    = hardcodedTestPaymentMethodsByOffering[response.offeringId];
  if (hardcodedPaymentMethods) {
    // muokkaa paymentMetodit tähän
    if (hardcodedPaymentMethods.kortti) {
      response.magazines[0].paymentMethodCreditCard = '1';
    }
    if (hardcodedPaymentMethods.lasku) {
      response.magazines[0].paymentMethodInvoice = '1';
    }
    if (hardcodedPaymentMethods.pankki) {
      response.magazines[0].paymentMethodBank = '1';
    }
  }

  if (params.receiveType === 'aspa') {
    response.magazines[0].paymentMethodCreditCard = '0';
    response.magazines[0].paymentMethodInvoice = '0';
    response.magazines[0].paymentMethodBank = '0';
  }
  /*
   * Ask tyrkyte (show tyrkyteForm) if:
   * - offer requires tyrkyte
   * - NOT preview
   * - NOT on TallennusPage
   * - tyrkyte skip for "order more" is NOT allowed
   * If offer requires tyrkyte and NOT preview or tallennusPage, ask for tyrkyte
   */
  if (
    response.requiresTyrkyte === '1'
    && !params.isPreview
    && params.host !== 'tallennus'
    && !customer.allowOrderMoreTyrkyteSkip
  ) {
    offerDispatch({
      type: OFFER_ASK_TYRKYTE,
      payload: {
        offeringId: response.offeringId,
        ...response,
      },
    });
    return;
  }
  /*
   * If tallennusPage and tyrkyte required, check if tyrkyte is disposable.
   * Those are not allowed on tallennusPage with only offeringId.
   * (Should use tyrkyte instead, which checks if tyrkyte is used)
   */
  if (response.requiresTyrkyte === '1' && params.host === 'tallennus') {
    if (response.disposableTyrkyte === '1') {
      offerDispatch({
        type: OFFER_FETCH_FAILURE,
        payload: {
          ...response,
          errorText: t.OFFER_REQUIRES_DISPOSABLE_TYRKYTE,
          offeringId: params.offeringId,
        },
      });
      return;
    }
  }
  /*
   * Else we got all we need. Set page title and inital payment method and render orderForm.
   */
  if (response.magazines) {
    if (response.magazines[0].siteTitle) {
      document.title = response.magazines[0].siteTitle;
    } else {
      document.title = response.options.siteTitle;
    }
  }
  offerDispatch({
    type: OFFER_FETCH_COMPLETE,
    payload: {
      ...response,
      offeringId: response.offeringId,
      params: { ...response.params, ...params },
    },
  });

  const magazineBrand = checkIfSingleBrand(response) || '';
  const offeringId = response?.offeringId || '';
  ga.googleAnalyticsOfferingIdAndBrand(offeringId, magazineBrand);
};

/**
 * Used by LandingPage and TallennusPage.
 *
 * LandingPage:
 *  - Used after tyrkyte is entered (lukulahja or getOffer returned that tyrkyte is needed)
 *  - If tyrkyte was given in url, this is called instead of getOffer
 * TallennusPage:
 *  - Called always with id OR tyrkyte
 *  TODO: Check if this is actually needed now that requests are proxied via backend again
 *
 * @return {function(*, *, *): Promise<undefined>}
 * @param params
 * @param offerDispatch
 * @param customer
 * @param customerDispatch
 */
export const getOfferWithTyrkyte = async (
  params,
  offerDispatch,
  customer,
  customerDispatch,
) => {
  offerDispatch({
    type: OFFER_SET_PARAMS,
    payload: {
      params,
    },
  });

  let response = null;
  try {
    offerDispatch({ type: OFFER_FETCH_TYRKYTE });
    response = await api.get('/campaign/offer', params);
    if (response.error) {
      // OTVMSUP-9, backend in maintenance mode
      if (response.status && response.status === 503) {
        offerDispatch({ type: BACKEND_IN_MAINTENANCE_MODE });
        return;
      }
      const errorText = getErrorText(response.errorCode);
      if (params.isLukulahja) {
        offerDispatch({
          type: OFFER_FETCH_LUKULAHJA_FAILURE,
          payload: {
            errorText,
          },
        });
      } else {
        offerDispatch({
          type: OFFER_FETCH_TYRKYTE_FAILURE,
          payload: {
            errorText,
          },
        });
      }
      return;
    }
  } catch (e) {
    offerDispatch({
      type: OFFER_FETCH_TYRKYTE_FAILURE,
      payload: {
        error: e,
        errorText: t.COULD_NOT_FIND_OFFER,
      },
    });
    return;
  }

  const hardcodedPaymentMethods
    = hardcodedTestPaymentMethodsByOffering[response.offeringId];
  if (hardcodedPaymentMethods) {
    if (hardcodedPaymentMethods.kortti) {
      response.magazines[0].paymentMethodCreditCard = '1';
    }
    if (hardcodedPaymentMethods.lasku) {
      response.magazines[0].paymentMethodInvoice = '1';
    }
    if (hardcodedPaymentMethods.pankki) {
      response.magazines[0].paymentMethodBank = '1';
    }
  }

  if (params.receiveType === 'aspa') {
    response.magazines[0].paymentMethodCreditCard = '0';
    response.magazines[0].paymentMethodInvoice = '0';
    response.magazines[0].paymentMethodBank = '0';
  }

  if (response.customer) {
    ga.googleAnalyticsMasterasiakasId(response.tyrkyte.masterasiakasid);
    // Only update customer if we didn't have info already
    if (!customer.tyrkyteId) {
      customerDispatch({
        type: OFFER_SET_CUSTOMER,
        payload: {
          customer: response.customer,
          tyrkyte: response.tyrkyte,
          tyrkyteId: params.tyrkyteId,
        },
      });
    }
  }

  if (response.magazines[0].requiresPoll === '1') {
    if (!response.customer) {
      offerDispatch({
        type: OFFER_FETCH_TYRKYTE_FAILURE,
        payload: {
          ...response,
          errorText: t.COULD_NOT_FIND_OFFER,
        },
      });
      return;
    }
    delete response.customer;
    delete response.tyrkyte;
    offerDispatch({
      type: OFFER_SHOW_POLL,
      payload: {
        offeringId: response.offeringId,
        ...response,
      },
    });
    return;
  }
  delete response.customer;
  delete response.tyrkyte;
  offerDispatch({
    type: OFFER_FETCH_COMPLETE,
    payload: {
      ...response,
      offeringId: response.offeringId,
      params: { ...response.params, ...params },
    },
  });
};

export const skipTyrkyte = async (offerDispatch) => {
  offerDispatch({ type: OFFER_FETCH_COMPLETE });
};

export const emptyOffer = async (offerDispatch) => {
  offerDispatch({
    type: OFFER_INITIAL_STATE,
  });
};

export const emptyCustomer = async (customerDispatch) => {
  customerDispatch({
    type: EMPTY_CUSTOMER,
    payload: {
      id: null,
    },
  });
};
