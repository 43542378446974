import React, { useContext, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Grid } from 'semantic-ui-react';

import Context from '../../context';
import GiftSelection from './formSections/GiftSelection';
import LoadingSpinner from '../main/LoadingSpinner';
import MagazineSelection from './formSections/MagazineSelection';
import RecipientAndPaymentSelection from './formSections/RecipientAndPaymentSelection';

import * as types from '../../actions/actionTypes';
import {
  Layout,
  Recipient,
  setCompanyDetails,
  setGifts,
  setSubscriberDetails,
  subscribeButtonDisabled,
} from '../../utils/util';
import { PaymentMethods } from '../../utils/consts';

import { TALLENNUS_PAGE_ORDER_FORM_SCHEMA } from '../../utils/formValidator';

const TallennusPageOrderForm = (props) => {
  const {
    isLoading,
    onSubmit,
    selectedMagazineId: propsSelectedMagazineId,
  } = props;
  const context = useContext(Context);
  const { offer, customer, order } = context;

  const {
    customer: {
      city,
      coName1,
      companyId,
      country,
      firstName,
      lastName,
      streetAddress,
      zipCode,
      phone,
    },
    tyrkyte,
  } = customer;
  const initialChosenOffer = offer.magazines?.[0];

  const formMethods = useForm({
    defaultValues: {
      isLoading: offer.isLoading,
      selectedMagazineId: propsSelectedMagazineId || initialChosenOffer.id,
      selectedGiftId: offer.gifts.length === 1 ? offer.gifts[0] : '',
      recipient:
        initialChosenOffer && initialChosenOffer.payerCustomerId
          ? Recipient.TO_FRIEND
          : Recipient.TO_SELF,
      paymentMethod: PaymentMethods.INVOICE, // Tallennus page is always default invoice
      numOfPayments: initialChosenOffer.numOfPayments,
      startingDate: '',
      subscriberDetails: {
        payerFirstName: firstName || '',
        payerLastName: lastName || '',
        payerStreetAddress: streetAddress || '',
        payerCountry: country || '',
        payerZipCode: zipCode || '',
        payerCity: city || '',
        payerPhoneNumber: phone || '',
        payerBirthday: '',
        payerEmail: '',
      },
      recipientDetails: {
        recipientFirstName: '',
        recipientLastName: '',
        recipientStreetAddress: '',
        recipientCountry: 'FI',
        recipientZipCode: '',
        recipientCity: '',
        recipientEmail: '',
      },
      companyDetails: {
        payerMasterAsiakasId: tyrkyte.masterasiakasid || '',
        payerLastName: lastName || '',
        payerCompanyId: companyId || '',
        payerStreetAddress: streetAddress || '',
        payerCountry: country || '',
        payerZipCode: zipCode || '',
        payerCity: city || '',
        payerCoName1: coName1 || '',
        payerPhoneNumber: phone || '',
        payerEmail: '',
      },
    },
    resolver: yupResolver(TALLENNUS_PAGE_ORDER_FORM_SCHEMA),
    reValidateMode: 'onSubmit',
    criteriaMode: 'all',
  });
  const { selectedMagazineId, selectedGiftId, paymentMethod, recipient }
    = formMethods.watch();

  const onKeyPress = () => {
    offer.postError = false;
  };

  // If offer changes, we need to update number of payments aswell.
  useEffect(() => {
    const numOfPaymentsBySelectedMagazineId = offer.magazines.find(
      ({ id }) => id === selectedMagazineId,
    )?.numOfPayments;
    formMethods.setValue('numOfPayments', numOfPaymentsBySelectedMagazineId);
  }, [selectedMagazineId]);

  const formSubmit = (data) => {
    if (recipient !== Recipient.TO_COMPANY) {
      onSubmit({
        ...setSubscriberDetails(customer, data.subscriberDetails),
        ...data.recipientDetails,
        ...data.GiftDetails,
        offeringId: offer.offeringId,
        selectedMagazineId: data.selectedMagazineId,
        mag: offer.magazines.find((x) => x.id === data.selectedMagazineId),
        payerCustomerId: offer.magazines[0].payerCustomerId,
        packageId: data.packageId,
        orderMoreLink: `${window.location.href.split('/')[2]}/${
          offer.offeringId
        }`,
        receiveType: '06',
        recipient: data.recipient,
        paymentMethod: data.paymentMethod,
        numOfPayments: data.numOfPayments,
        startingDate: data.startingDate,
        options: offer.options,
        customer: customer.customer,
        tyrkyteId: customer.tyrkyteId || customer.tyrkyte.tyrkyte,
        targetGroupId: customer.tyrkyte.targetGroupId
          ? customer.tyrkyte.targetGroupId
          : offer.magazines.find((x) => x.id === data.selectedMagazineId)
            .targetGroupId,
        gifts: setGifts(offer, data.selectedGiftId),
        emailConsent: true,
      });
    } else if (recipient === Recipient.TO_COMPANY) {
      onSubmit({
        ...setCompanyDetails(customer, data.companyDetails),
        ...data.recipientDetails,
        ...data.GiftDetails,
        offeringId: offer.offeringId,
        selectedMagazineId: data.selectedMagazineId,
        mag: offer.magazines.find((x) => x.id === data.selectedMagazineId),
        payerCustomerId: offer.magazines[0].payerCustomerId,
        packageId: data.packageId,
        orderMoreLink: `${window.location.href.split('/')[2]}/${
          offer.offeringId
        }`,
        receiveType: '06',
        recipient: data.recipient,
        paymentMethod: data.paymentMethod,
        numOfPayments: data.numOfPayments,
        startingDate: data.startingDate,
        options: offer.options,
        customer: customer.customer,
        tyrkyteId: customer.tyrkyteId,
        targetGroupId: customer.tyrkyte.targetGroupId
          ? customer.tyrkyte.targetGroupId
          : offer.magazines.find((x) => x.id === data.selectedMagazineId)
            .targetGroupId,
        gifts: setGifts(offer, data.selectedGiftId),
        emailConsent: true,
      });
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Form
        onKeyPress={onKeyPress}
        onSubmit={formMethods.handleSubmit((data) => formSubmit(data))}
        className="order-form"
      >
        <Grid stackable centered className="no-margin no-padding mobilepadding">
          <Grid.Row style={{ backgroundColor: 'white' }}>
            <Grid.Column floated="left" mobile={16} tablet={8} computer={8}>
              <MagazineSelection layout={Layout.DROPDOWN} />
            </Grid.Column>
            {offer.gifts.length > 0 && (
              <Grid.Column floated="left" mobile={16} tablet={8} computer={8}>
                <GiftSelection giftLayout={Layout.DROPDOWN} />
              </Grid.Column>
            )}
          </Grid.Row>

          <Grid.Row style={{ backgroundColor: 'white' }}>
            <Grid.Column width={16}>
              <RecipientAndPaymentSelection
                recipient={recipient}
                hidePaymentSelection
                emailRequired={false}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row
            centered
            style={{
              backgroundColor: 'white',
              marginBottom: 40,
              paddingBottom: 30,
            }}
          >
            <Grid.Column mobile={16} tablet={8} computer={8}>
              {order.state === types.ORDER_SUBSCRIBE ? (
                <LoadingSpinner label="Tilausta käsitellään" />
              ) : (
                <Button
                  disabled={subscribeButtonDisabled(
                    offer,
                    selectedMagazineId,
                    selectedGiftId,
                    paymentMethod,
                  )}
                  style={{ width: 280, margin: '0 auto', fontSize: 14 }}
                  type="submit"
                  id="submitOrder"
                  fluid
                  color="blue"
                >
                  {isLoading} TILAA
                  <img
                    alt="arrow icon"
                    style={{
                      position: 'absolute',
                      float: 'left',
                      marginLeft: -151,
                      marginTop: -2,
                      height: 17,
                      width: 17,
                      backgroundSize: 14,
                      content: '',
                      zIndex: 1,
                    }}
                    src="https://otavamedia.fi/wp-content/themes/om-otavamedia-theme/assets/img/icon-arrow.svg"
                  />
                </Button>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </FormProvider>
  );
};

export default TallennusPageOrderForm;
