import { PulseLoader } from 'react-spinners';
import React from 'react';

const LoadingSpinner = (props) => {
  const { label, className, textOnTop } = props;

  return (
    <div className={`loading-spinner${className ? ` ${className}` : ''}`}>
      {textOnTop && <span>{label}</span>}
      <PulseLoader color="#000000" />
      {!textOnTop && <span>{label}</span>}
    </div>
  );
};

export default LoadingSpinner;
