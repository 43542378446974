import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';

import AddCardForm from '../forms/AddCardForm';
import BankForm from '../forms/BankForm';
import EInvoiceForm from '../forms/EInvoiceForm';
import EMailForm from '../forms/EMailForm';
import InvoicingChangeSuccess from './InvoicingChangeSuccess';
import InvoicingSelection from '../forms/formSections/InvoicingSelection';
import LoadingSpinner from './LoadingSpinner';
import SubscriptionAndCustomerInfo from './SubscriptionAndCustomerInfo';

import {
  BACKEND_IN_MAINTENANCE_MODE,
  INVOICING_CHANGE_CARD_ADD_FAILURE,
  INVOICING_CHANGE_CARD_ADD_INIT,
  INVOICING_CHANGE_CARD_ADD_REDIRECT,
  INVOICING_CHANGE_CARD_EMAIL_FORM,
  INVOICING_CHANGE_CARD_ONLY_FORM,
  INVOICING_CHANGE_CHARGE_CARD,
  INVOICING_CHANGE_CHARGE_CARD_FAILURE,
  INVOICING_CHANGE_CHARGE_CARD_SUCCESS,
  INVOICING_CHANGE_E_INVOICING_CHOOSE_BANK,
  INVOICING_CHANGE_E_INVOICING_FAILURE,
  INVOICING_CHANGE_E_INVOICING_SEND,
  INVOICING_CHANGE_E_INVOICING_SSN_FORM,
  INVOICING_CHANGE_E_INVOICING_SUCCESS,
  INVOICING_CHANGE_INIT,
  INVOICING_CHANGE_INIT_FAILURE,
  INVOICING_CHANGE_MOBILEPAY_INVOICING_INIT,
  INVOICING_CHANGE_ORDER_FETCH,
  INVOICING_CHANGE_ORDER_FETCH_FAILURE,
  INVOICING_CHANGE_ORDER_FETCH_SUCCESSFUL,
  INVOICING_CHANGE_TYRKYTE_EXPIRED,
  INVOICING_CHANGE_TYRKYTE_USED,
} from '../../actions/actionTypes';
import Context from '../../context';
import ErrorMessageRow from './ErrorMessageRow';

const InvoicingChangeContent = (props) => {
  const { addCardInit, submitEInvoiceForm } = props;
  const context = useContext(Context);
  const { invoicingChange } = context;

  const showCardOption = invoicingChange?.subscription?.invoicingMode !== '11';
  const alreadyChangedErrorText1
    = 'Olemme vastaanottaneet maksutavan vaihtosi.';
  const expiredErrorText1 = 'Linkki ei ole enää voimassa.';

  switch (invoicingChange.state) {
    case INVOICING_CHANGE_INIT: {
      return <LoadingSpinner />;
    }
    case INVOICING_CHANGE_INIT_FAILURE: {
      return <ErrorMessageRow />;
    }
    case INVOICING_CHANGE_ORDER_FETCH: {
      return <LoadingSpinner />;
    }
    case INVOICING_CHANGE_TYRKYTE_EXPIRED: {
      return <ErrorMessageRow text1={expiredErrorText1} />;
    }
    case INVOICING_CHANGE_TYRKYTE_USED: {
      return <ErrorMessageRow text1={alreadyChangedErrorText1} />;
    }
    case INVOICING_CHANGE_ORDER_FETCH_FAILURE: {
      return <ErrorMessageRow />;
    }
    case INVOICING_CHANGE_ORDER_FETCH_SUCCESSFUL: {
      return (
        <>
          <Grid.Row id="subscriptionAndCustomerInfo">
            <SubscriptionAndCustomerInfo data={invoicingChange} />
          </Grid.Row>
          <InvoicingSelection card={showCardOption} eInvoicing />
        </>
      );
    }
    /*
     * Card invoicing states
     */
    case INVOICING_CHANGE_CARD_ONLY_FORM: {
      return <EMailForm onlyCard onSubmit={addCardInit} />;
    }
    case INVOICING_CHANGE_CARD_EMAIL_FORM: {
      return <EMailForm onSubmit={addCardInit} />;
    }
    case INVOICING_CHANGE_CARD_ADD_INIT: {
      return <LoadingSpinner />;
    }
    case INVOICING_CHANGE_CARD_ADD_FAILURE: {
      return <ErrorMessageRow />;
    }
    case INVOICING_CHANGE_CARD_ADD_REDIRECT: {
      return (
        <div style={{ minHeight: '5rem', minWidth: '0%', marginTop: '1rem' }}>
          <Grid.Row>
            {invoicingChange.paytrailParams ? (
              <div
                style={{ minHeight: '5rem', minWidth: '0%', marginTop: '3rem' }}
              >
                <AddCardForm urlParams={invoicingChange.paytrailParams} />
                <Grid.Row>
                  <div className="ui active loader" />
                </Grid.Row>
              </div>
            ) : (
              <ErrorMessageRow />
            )}
          </Grid.Row>
        </div>
      );
    }
    case INVOICING_CHANGE_CHARGE_CARD: {
      return <LoadingSpinner />;
    }
    case INVOICING_CHANGE_CHARGE_CARD_FAILURE: {
      return <ErrorMessageRow />;
    }
    case INVOICING_CHANGE_CHARGE_CARD_SUCCESS: {
      return <InvoicingChangeSuccess invoicingChange={invoicingChange} />;
    }
    /*
     * E-invoicing states
     */
    case INVOICING_CHANGE_E_INVOICING_CHOOSE_BANK: {
      return <BankForm />;
    }
    case INVOICING_CHANGE_E_INVOICING_SSN_FORM: {
      return (
        <EInvoiceForm
          submit={submitEInvoiceForm}
          options={invoicingChange.options}
          eInvoice={invoicingChange.eInvoice}
        />
      );
    }
    case INVOICING_CHANGE_E_INVOICING_SEND: {
      return <LoadingSpinner />;
    }
    case INVOICING_CHANGE_E_INVOICING_FAILURE: {
      return <ErrorMessageRow />;
    }
    case INVOICING_CHANGE_E_INVOICING_SUCCESS: {
      return <InvoicingChangeSuccess invoicingChange={invoicingChange} />;
    }
    /*
     * MobilePay invoicing states
     */
    case INVOICING_CHANGE_MOBILEPAY_INVOICING_INIT: {
      return (
        <Grid.Row>
          <h3>INVOICING_CHANGE_MOBILEPAY_INVOICING_INIT</h3>
        </Grid.Row>
      );
    }
    // OTVMSUP-9 Show maintenance mode info
    case BACKEND_IN_MAINTENANCE_MODE:
      return (
        <Grid.Row>
          <div style={{ textAlign: 'center', minWidth: '0%' }}>
            <h1>Päivitämme palvelua. Ole hyvä ja yritä myöhemmin uudelleen.</h1>
            <br />
          </div>
        </Grid.Row>
      );
    default: {
      return <ErrorMessageRow />;
    }
  }
};

export default InvoicingChangeContent;
