import {
  BACKEND_IN_MAINTENANCE_MODE,
  EMPTY_CUSTOMER,
  OFFER_SET_CUSTOMER,
  ORDER_SET_CUSTOMER,
} from '../actions/actionTypes';

const buildCustomer = (data) => ({
  firstName: data.firstName || '',
  lastName: data.lastName || '',
  companyId: data.companyId || '',
  streetAddress: data.streetAddress || '',
  country: data.country || '',
  zipCode: data.zipCode || '',
  city: data.city || '',
  coName1: data.coName1 || '',
  email: data.email || '',
  phoneNumber: data.phoneNumber || '',
  birthDay: data.birthDay || '',
});

const emptyCustomer = {
  firstName: null,
  lastName: null,
  companyId: null,
  streetAddress: null,
  country: 'FI',
  zipCode: null,
  city: null,
  coName1: null,
  email: null,
  phoneNumber: null,
  birthDay: null,
};

const emptyTyrkyte = {
  masterasiakasid: null,
  tarjouslehdetid: null,
  targetGroupId: null,
};

export const initialState = {
  customer: emptyCustomer,
  tyrkyte: emptyTyrkyte,
  tyrkyteId: null,
  isLoadingCustomer: false,
  allowOrderMoreTyrkyteSkip: false,
};

// eslint-disable-next-line default-param-last
export const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case OFFER_SET_CUSTOMER: {
      return {
        ...initialState,
        customer: buildCustomer(action.payload.customer),
        tyrkyte: action.payload.tyrkyte ? action.payload.tyrkyte : emptyTyrkyte,
        tyrkyteId: action.payload.tyrkyteId,
      };
    }
    case ORDER_SET_CUSTOMER: {
      return {
        ...initialState,
        customer: buildCustomer(action.payload.customer),
        allowOrderMoreTyrkyteSkip: action.payload.allowOrderMoreTyrkyteSkip,
      };
    }
    case EMPTY_CUSTOMER: {
      return {
        ...state,
        customer: emptyCustomer,
        tyrkyte: emptyTyrkyte,
        tyrkyteId: null,
        isLoadingCustomer: false,
        allowOrderMoreTyrkyteSkip: false,
      };
    }
    // OTVMSUP-9, dispatch this when backend returns 503
    case BACKEND_IN_MAINTENANCE_MODE: {
      return {
        ...state,
        state: BACKEND_IN_MAINTENANCE_MODE,
      };
    }
    default: {
      return state;
    }
  }
};
