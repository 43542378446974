import React, { useContext, useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import Context from '../../context';
import AddCardForm from '../forms/AddCardForm';
import HeaderBar from '../main/HeaderBar';
import OrderStatus from '../main/OrderStatus';

import * as orderActions from '../../actions/order';
import * as types from '../../actions/actionTypes';
import * as ga from '../../utils/googleAnalytics';
import Footer from '../main/Footer';
import { parseUrlParams, shouldShowHeaderAndFooter } from '../../utils/util';
import { PaymentMethods } from '../../utils/consts';

const OrderPage = () => {
  useEffect(() => {
    ga.googleAnalyticsPageView();
  }, []);
  const context = useContext(Context);
  const { order, orderDispatch, customerDispatch } = context;
  const { method } = useParams();
  const navigate = useNavigate();

  /*
   * Determine state of order by path param.
   */
  useEffect(() => {
    const urlParams = new URL(window.location.href).searchParams;
    const params = parseUrlParams(urlParams);

    switch (method) {
      case PaymentMethods.INVOICING_E_IDENT:
        // orderId is in "state" param when returning
        params.orderId = urlParams.get('state');
        if (urlParams.has('code') && urlParams.get('code') !== 'cancel') {
          params.paymentMethod = PaymentMethods.INVOICING_E_IDENT;
          orderActions.subscribe(params, orderDispatch).then();
        } else {
          orderActions
            .cancelOrder(urlParams.get('state'), orderDispatch)
            .then();
        }
        break;
      case PaymentMethods.INVOICING_PAYTRAIL: {
        if (urlParams.has('success') && urlParams.get('success') === 'true') {
          params.paymentMethod = PaymentMethods.INVOICING_PAYTRAIL;
          orderActions.subscribe(params, orderDispatch).then();
        } else {
          orderActions
            .cancelOrder(urlParams.get('orderId'), orderDispatch)
            .then();
        }
        break;
      }
      case PaymentMethods.CARD: {
        // After order init, this redirects with post request to Paytrail add card form
        if (!urlParams.has('redirect')) {
          orderActions.addCard(urlParams, orderDispatch).then();
          // Card added successfully -> continue normally
        } else if (
          urlParams.has('success') &&
          urlParams.get('success') === 'true'
        ) {
          params.paymentMethod = PaymentMethods.CARD;
          orderActions.subscribe(params, orderDispatch).then();
          // Something went wrong (cancelled, etc.)
        } else {
          orderActions
            .cancelOrder(urlParams.get('orderId'), orderDispatch)
            .then();
        }
        break;
      }
      case PaymentMethods.MOBILEPAY: {
        params.paymentMethod = PaymentMethods.MOBILEPAY;
        // Subscribes in the end but polls payment status first
        orderActions.pollMobilePayStatus(params, orderDispatch).then();
        break;
      }
      // Includes orders without payment method
      case PaymentMethods.INVOICE:
      case PaymentMethods.INVOICING_CALL:
      default:
        params.paymentMethod = method;
        orderActions.subscribe(params, orderDispatch).then();
        break;
    }
  }, [method]);

  const returnToOffer = async () => {
    await orderActions.setCustomerFromOrder(order.order, customerDispatch);
    const { offeringId, tyrkyteId } = order.order;
    // Redirect to /<offeringId>?<tyrkyteId> if tyrkyte order
    const tyrkyte = tyrkyteId ? `?${tyrkyteId}` : '';
    navigate(`/${offeringId}${tyrkyte}`);
  };

  return (
    <>
      <div className="layout-container">
        <div id="orderPage">
          {shouldShowHeaderAndFooter(order.state) && <HeaderBar />}

          <Grid centered className="padding">
            <OrderStatus onReturn={() => returnToOffer()} />
          </Grid>

          {order.submitAddCardForm && order.params && (
            // Idiotic style to redirect but Paytrail requires POST request
            <AddCardForm urlParams={order.params} />
          )}

          {order.state === types.ORDER_COMPLETE && (
            <Navigate to="/order/complete" />
          )}
        </div>
      </div>
      {shouldShowHeaderAndFooter(order.state) && (
        <div className="footer-section">
          <Footer />
        </div>
      )}
    </>
  );
};

export default OrderPage;
