import React from 'react';
import { Element } from 'react-scroll';

/**
 * OTVMSUP-758 Scroll element to define Intersection Observer target and scrolling anchor.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ScrollElement = (props) => {
  const { name, targetRef } = props;
  return (
    <div ref={targetRef || undefined} id={name}>
      <Element />
    </div>
  );
};

export default ScrollElement;
