import {
  BACKEND_IN_MAINTENANCE_MODE,
  ORDER_ADD_CARD,
  ORDER_ADD_CARD_FAILURE,
  ORDER_E_IDENT_FAILURE,
  ORDER_CANCEL_INIT,
  ORDER_CANCEL_FAILURE,
  ORDER_CANCEL_SUCCESS,
  ORDER_COMPLETE,
  ORDER_HANDLING_START,
  ORDER_INIT_SUCCESS,
  ORDER_SUBSCRIBE,
  ORDER_SUBSCRIBE_FAILURE,
  ORDER_INVOICE_CALL,
  ORDER_POLL_MOBILEPAY_STATUS,
  ORDER_POLL_MOBILEPAY_STATUS_FAILED,
} from '../actions/actionTypes';

export const initialState = {
  state: ORDER_HANDLING_START,
  errorText: null,
  params: null,
  error: null,
  submitAddCardForm: false,
  redirectUrl: null,
  subscription: null,
  payWallRedirectURL: null,
  order: null,
};

// eslint-disable-next-line default-param-last
export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_INIT_SUCCESS: {
      return {
        ...state,
        state: ORDER_INIT_SUCCESS,
        // Redirect url to order handling start
        redirectUrl: action.payload.redirectUrl,
        params: action.payload.params,
      };
    }
    case ORDER_HANDLING_START: {
      return {
        ...state,
      };
    }
    case ORDER_INVOICE_CALL: {
      return {
        ...state,
        state: ORDER_INVOICE_CALL,
      };
    }
    // If card order, toggle AddCardForm hidden form to redirect to paytrail card addition
    case ORDER_ADD_CARD: {
      return {
        ...state,
        state: ORDER_ADD_CARD,
        params: action.payload.urlParams,
        submitAddCardForm: true,
      };
    }
    case ORDER_ADD_CARD_FAILURE: {
      return {
        ...state,
        state: ORDER_ADD_CARD_FAILURE,
        // This is reset anyway when redirected back to OrderPage (initially false)
        submitAddCardForm: false,
      };
    }
    case ORDER_E_IDENT_FAILURE: {
      return {
        ...state,
        state: ORDER_E_IDENT_FAILURE,
      };
    }
    case ORDER_CANCEL_INIT: {
      return {
        ...state,
        state: ORDER_CANCEL_INIT,
      };
    }
    case ORDER_CANCEL_SUCCESS: {
      return {
        ...state,
        state: ORDER_CANCEL_SUCCESS,
        order: action.payload.order,
      };
    }
    case ORDER_CANCEL_FAILURE: {
      return {
        ...state,
        state: ORDER_CANCEL_FAILURE,
        errorText: action.payload.errorText,
      };
    }
    case ORDER_POLL_MOBILEPAY_STATUS: {
      return {
        ...state,
        state: ORDER_POLL_MOBILEPAY_STATUS,
        params: action.payload.urlParams
          ? action.payload.urlParams
          : state.params,
      };
    }
    case ORDER_POLL_MOBILEPAY_STATUS_FAILED: {
      return {
        ...state,
        state: ORDER_POLL_MOBILEPAY_STATUS_FAILED,
        errorText: action.payload?.errorText || null,
      };
    }
    case ORDER_SUBSCRIBE: {
      return {
        ...state,
        state: ORDER_SUBSCRIBE,
        params: action.payload.data,
      };
    }
    case ORDER_SUBSCRIBE_FAILURE: {
      return {
        ...state,
        state: ORDER_SUBSCRIBE_FAILURE,
        errorText: action.payload?.errorText,
        order: action.payload?.order,
      };
    }
    case ORDER_COMPLETE: {
      return {
        ...state,
        state: ORDER_COMPLETE,
        subscription: action.payload.response,
      };
    }
    // OTVMSUP-9, dispatch this when backend returns 503
    case BACKEND_IN_MAINTENANCE_MODE: {
      return {
        ...state,
        state: BACKEND_IN_MAINTENANCE_MODE,
      };
    }
    default: {
      return state;
    }
  }
};
