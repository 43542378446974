/**
 * Check if is valid Finnish ssn
 * Validated against SSN regex:
 * 1. Valid for every birthday from 1850 to 2050
 * 2. Checks middle character: "+" for 18xx, "-" for 19xx, "A"for 2000+
 * @param {string} allegedSsn;
 * @returns {boolean}
 */
const isValidSsn = (allegedSsn) => {
  const ssnRegex
    = /(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])([5-9]\d\+|\d\d-|[0-5]\dA)(\d{3}[\dA-Z])$/;
  return ssnRegex.test(allegedSsn);
};

/**
 * Check if is valid Email.
 * Validated against regex per HTML specs:
 * https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
 * @param {string} allegedEmail;
 * @returns {boolean}
 */
const isValidEmail = (allegedEmail) => {
  const emailRegex
    = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return emailRegex.test(allegedEmail);
};

/**
 * Check if is "valid" date. Meaning it's a date object & is not NaN.
 * @param {*} d
 */
const isValidDate = (d) => d instanceof Date && !Number.isNaN(d);

module.exports = {
  isValidSsn,
  isValidEmail,
  isValidDate,
};
