import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Element } from 'react-scroll';
import { Grid, Image } from 'semantic-ui-react';

import Context from '../../../context';
import MagazineSelectCarousel from './MagazineSelectCarousel';

import { SCROLL_ELEMENTS } from '../../../utils/scrollUtils';
import { getBackgroundColor } from '../../../utils/util';
import SliderArrow from './SliderArrow';

const MagazineSelectBrand = (props) => {
  const { selectedBrandPackage, setSelectedBrandPackage } = props;
  const context = useContext(Context);
  const { offer } = context;
  const { setValue } = useFormContext();

  /**
   * OTVMSUP-671
   * Mag needs to be reset if brand is changed
   */
  const resetSelectedMagazine = () => {
    const initialValue
      = offer.magazines.length === 1 ? offer.magazines[0].id : null;
    setValue('selectedMagazineId', initialValue);
  };

  const filteredBrands = [
    ...new Map(
      offer.brands
        .filter((b) => !b[0].packageId.includes('DIGI'))
        .map((item) => [item[0].brandCode, item]),
    ).values(),
  ];

  const brandMagazines = selectedBrandPackage
    ? offer.magazines.filter(
      (m) =>
        selectedBrandPackage.substring(0, 2) === m.packageId.substring(0, 2),
    )
    : null;

  const onBrandChange = (packageId) => {
    resetSelectedMagazine();
    setSelectedBrandPackage(packageId);
  };

  const sliderSettings = {
    arrows: true,
    infinite: brandMagazines?.length > 3,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          // if there are only 2 magazines slides are on the left not centered
          slidesToShow: brandMagazines?.length > 2 ? 3 : 2,
          slidesToScroll: brandMagazines?.length > 2 ? 3 : 2,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <SliderArrow direction="right" />,
    prevArrow: <SliderArrow direction="left" />,
  };

  return (
    <div id="magazineSelectBrand">
      <Grid centered stackable>
        <Grid.Row className="brand-select-row pb-0">
          {offer.brands.length === 1 && (
            <>
              {offer.brands.map((brand) =>
                brand.map((product) => (
                  <Grid.Column
                    key={product.productId}
                    mobile={8}
                    tablet={4}
                    computer={4}
                    style={
                      product.mainProduct !== 'Y' ? { display: 'none' } : null
                    }
                    onClick={() => onBrandChange(product.packageId)}
                    className="brand-select-column"
                  >
                    {product.mainProduct === 'Y' && (
                      <>
                        {product.packageImage && (
                          <Image
                            src={product.packageImage}
                            className="brand-select-image"
                          />
                        )}
                        {product.offerExtraImage && (
                          <Image
                            className="magazineLisakuva"
                            src={product.offerExtraImage}
                          />
                        )}
                        <div className="brand-name">
                          <p>{product.brandName}</p>
                        </div>
                      </>
                    )}
                  </Grid.Column>
                )),
              )}
            </>
          )}

          {offer.brands.length > 1 && (
            <>
              {filteredBrands.map((brand) =>
                brand.map((product) => (
                  <Grid.Column
                    key={product.productId}
                    mobile={8}
                    tablet={4}
                    computer={4}
                    style={
                      product.mainProduct !== 'Y' ? { display: 'none' } : null
                    }
                    onClick={() => onBrandChange(product.packageId)}
                    className="brand-select-column"
                  >
                    {product.mainProduct === 'Y' && (
                      <>
                        {product.packageImage && (
                          <div className="brand-select-image-container">
                            <Image
                              src={product.packageImage}
                              className="brand-select-image"
                            />
                          </div>
                        )}
                        {product.offerExtraImage && (
                          <Image
                            className="magazineLisakuva"
                            src={product.offerExtraImage}
                          />
                        )}
                        <div className="brand-name">
                          <p>{product.brandName}</p>
                        </div>
                      </>
                    )}
                  </Grid.Column>
                )),
              )}
            </>
          )}
        </Grid.Row>
      </Grid>

      <Element
        name={SCROLL_ELEMENTS.BRAND_SELECTION.name}
        id={SCROLL_ELEMENTS.BRAND_SELECTION.name}
      />
      {selectedBrandPackage && brandMagazines && (
        <Grid
          centered
          className="no-margin no-padding"
          style={{ background: getBackgroundColor(null, offer) }}
        >
          <Grid.Row>
            <Grid.Column width={16}>
              <h2 className={`${selectedBrandPackage ? '' : 'hidden'} pt-0`}>
                Valitse tilausjakso
              </h2>
            </Grid.Column>
          </Grid.Row>
          {brandMagazines && offer.magazines.length > 0 && (
            <Grid.Row>
              <Grid.Column width={16} className="no-padding">
                <MagazineSelectCarousel
                  magazines={brandMagazines}
                  isMagazineSelectBrand
                  sliderSettings={sliderSettings}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      )}
    </div>
  );
};

export default MagazineSelectBrand;
