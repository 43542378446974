import React, { useState } from 'react';
import { Form, Button, Grid, Icon } from 'semantic-ui-react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import InputWrapper from '../util/InputWrapper';
import LoadingSpinner from '../main/LoadingSpinner';

import { TALLENNUS_PAGE_OFFER_FORM_SCHEMA } from '../../utils/formValidator';

// eslint-disable-next-line import/no-mutable-exports
const TallennusPageOfferForm = (props) => {
  const { handleVoucherFormSubmit, resetNotification, isLoadingOffer } = props;

  const formMethods = useForm({
    defaultValues: {
      barcode: '',
      tyrkyteId: '',
      offeringId: '',
    },
    resolver: yupResolver(TALLENNUS_PAGE_OFFER_FORM_SCHEMA),
  });

  const [isValid, setIsValid] = useState(false);

  const onKeyPress = async (e) => {
    if (e && e.key === 'Enter') {
      handleVoucherFormSubmit();
    }
    await resetNotification();
  };

  const validate = () => {
    const { barcode, tyrkyteId, offeringId } = formMethods.getValues();
    if (!barcode && !tyrkyteId && !offeringId) setIsValid(false);
    else setIsValid(true);
  };

  return (
    <>
      <h1>Kuponkien tallennus</h1>
      <Grid stackable centered className="mobilepadding no-margin no-padding">
        <Grid.Row floated="left" mobile={16} tablet={16} computer={16}>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            style={{
              backgroundColor: 'white',
              paddingTop: 30,
              paddingBottom: 30,
              paddingLeft: 30,
              paddingRight: 30,
              marginBottom: 20,
            }}
          >
            <FormProvider className="voucher-form" {...formMethods}>
              <Form
                onKeyPress={onKeyPress}
                onChange={validate}
                onSubmit={formMethods.handleSubmit(() =>
                  handleVoucherFormSubmit(formMethods.getValues()),
                )}
                className="voucher-form"
              >
                <Form.Field>
                  <InputWrapper
                    active
                    autoFocus
                    label="Viivakoodi"
                    placeholder="Syötä viivakoodi"
                    name="barcode"
                    type="text"
                    customOnError={<></>} // Due to custom handling, do nothing.
                  />
                </Form.Field>
                <Form.Field>
                  <InputWrapper
                    label="Asiakkaan tyrkyte"
                    placeholder="Syötä tyrkyte"
                    name="tyrkyteId"
                    type="text"
                    customOnError={<></>} // Due to custom handling, do nothing.
                  />
                </Form.Field>
                <Form.Field>
                  <InputWrapper
                    label="Tarjooma ID"
                    placeholder="Syötä tarjoomakoodi"
                    name="offeringId"
                    type="text"
                    customOnError={<></>} // Due to custom handling, do nothing.
                  />
                </Form.Field>
                {isLoadingOffer ? (
                  <LoadingSpinner />
                ) : (
                  <Button
                    type="submit"
                    id="continue"
                    disabled={!isValid}
                    fluid
                    color="blue"
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                  >
                    JATKA »
                  </Button>
                )}
                <p>
                  Lue viivakoodi tai anna joko asiakkaan tyrkyte tai tarjooma-id
                  ja paina JATKA.
                </p>
                {!isValid && (
                  <p>
                    <Icon color="blue" name="info circle" />
                    Täytä vähintään yksi kenttä.
                  </p>
                )}
              </Form>
            </FormProvider>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default TallennusPageOfferForm;
