import { Grid } from 'semantic-ui-react';
import React from 'react';
import t from '../../locales/fi';

const ErrorMessageRow = (props) => {
  const { text1, text2 } = props;
  const defaultText1 = 'Jotain meni pieleen.';

  const title = text1 === null ? text1 : text1 || defaultText1;
  const paragraph = text2;

  return (
    <Grid.Row id="genericErrorMessage">
      <Grid.Column width={16} className="infoText-noBg" textAlign="center">
        <b>
          <p>{title}</p>
        </b>
      </Grid.Column>
      {paragraph && (
        <Grid.Column width={16} className="infoText-noBg" textAlign="center">
          <p>{paragraph}</p>
        </Grid.Column>
      )}
      <Grid.Column width={16} className="infoText-noBg" textAlign="center">
        <p>
          Tarvittaessa ota yhteyttä{' '}
          <a href={t.OTAVAMEDIA_CUSTOMER_SERVICE_URL}>asiakaspalveluun.</a>
        </p>
      </Grid.Column>
    </Grid.Row>
  );
};

export default ErrorMessageRow;
