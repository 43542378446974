import React from 'react';
import { Grid } from 'semantic-ui-react';
import otavamedia_logo from '../../public/images/otavamedia_logo_valkoinen.png';
import otava_tahtimerkki from '../../public/images/otava_tahtimerkki_valkoinen.png';

const Footer = () => (
  <footer id="footer">
    <Grid stackable centered>
      <Grid.Row width={16} style={{ paddingTop: 0, paddingBottom: 0 }}>
        <ul className="footer-menu">
          <li>
            <a href="https://otavamedia.fi/tietoa-meista/yhteystiedot/">
              Yhteystiedot
            </a>
          </li>
          <li>
            <a href="https://otavamedia.fi/tilaus-ja-kayttoehdot/">
              Tilaus- ja käyttöehdot
            </a>
          </li>
          <li>
            <a href="https://otavamedia.fi/evasteiden-kaytto/">
              Evästeiden käyttö
            </a>
          </li>
          <li>
            <a href="https://otavamedia.fi/tietosuoja/">Tietosuoja</a>
          </li>
        </ul>
      </Grid.Row>
    </Grid>
    <Grid stackable centered>
      <Grid.Row width={16} className="p-0">
        <a href="https://otavamedia.fi">
          <img src={otavamedia_logo} id="footer-logo" alt="Otavamedia" />
        </a>
      </Grid.Row>
    </Grid>
  </footer>
);

export default Footer;
