import React, { useContext } from 'react';

import Context from '../../../context';

const EmailInfoText = ({ infoText }) => {
  const context = useContext(Context);
  const { offer } = context;
  const text = infoText || offer?.options?.emailInfoText;

  if (text) {
    return <span dangerouslySetInnerHTML={{ __html: text }} />;
  }
  return (
    <>
      Antamalla sähköpostiosoitteesi saat tilausvahvistuksen sähköpostiisi ja
      tietoa asiakaseduista ja tarjouksista sähköisesti Otavamedia Oy:ltä. Voit
      halutessasi kieltää sähköisen tarjonnan vastaanottamasi tarjontaviestin
      kieltolinkistä. Katso tietosuojaseloste{' '}
      <a
        target="_blank"
        href="http://otavamedia.fi/tietosuoja"
        rel="noreferrer"
      >
        Otavamedia.fi/tietosuoja.
      </a>
    </>
  );
};

export default EmailInfoText;
