import { useCallback, useEffect, useState } from 'react';

export const SESSION_STORAGE_KEYS = {
  REFERRER_SID: 'sid',
  REFERRER_URL: 'referrer_url',
  SOURCE_SID: 'HITsessionId', // Placed by OM via script
  SOURCE_URL: 'source_url',
};

const DEFAULTS = {
  [SESSION_STORAGE_KEYS.REFERRER_SID]: '',
  [SESSION_STORAGE_KEYS.REFERRER_URL]: '',
  [SESSION_STORAGE_KEYS.SOURCE_URL]: '',
};

const useSessionStorage = (keys = Object.values(SESSION_STORAGE_KEYS)) => {
  const [sessionData, setSessionData] = useState({ ...DEFAULTS });

  const reload = useCallback(() => {
    setSessionData(() =>
      keys
        .filter((key) => Object.values(SESSION_STORAGE_KEYS).includes(key))
        .reduce((a, key) => ({ ...a, [key]: sessionStorage.getItem(key) || DEFAULTS[key]})
          , {}),
    );
  }, [keys, setSessionData]);

  const removeSessionItem = useCallback(
    (key) => {
      sessionStorage.removeItem(key);
      reload();
    },
    [reload],
  );

  const setSessionItem = useCallback(
    (key, value) => {
      sessionStorage.setItem(key, value);
      reload();
    },
    [reload],
  );

  const clearSession = useCallback(() => {
    keys.forEach((key) => sessionStorage.removeItem(key));
    reload();
  }, [keys, reload]);

  useEffect(() => {
    reload();
  }, []);;

  return {
    sessionData,
    removeSessionItem,
    setSessionItem,
    clearSession,
    reload,
  };
};

export default useSessionStorage;
