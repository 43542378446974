import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ValidationError } from '../../utils/formValidator';

/**
 * @param {FieldState} param0
 * @param {object} param0.error
 */
const defaultError = ({ error }) => {
  if (error.message === (ValidationError.INVALID_FIELD_CONTACT_SUPPORT)) {
    const [beforeSupportLink] = error.message.split('asiakaspalveluun');
    return (
    <span className="errorText-input-field">
        {beforeSupportLink}
        <a href="https://otavamedia.fi/tietoa-meista/yhteystiedot/" target="_blank">
          asiakaspalveluun
        </a>
    </span>)
  }
  return <span className="errorText-input-field">{error.message}</span>
};

const InputWrapper = (props) => {
  const {
    name,
    type,
    label,
    disabled,
    placeholder,
    tabindex,
    onInvalid,
    customOnError,
    autoFocus,
  } = props;
  const { control, setError, clearErrors } = useFormContext();
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <div>
      <div>{label && <span className="input-wrapper-label">{label}</span>}</div>
      <input
        {...{ autoFocus }}
        className="input-wrapper-input"
        name={field.name}
        type={type}
        value={field.value}
        onChange={field.onChange}
        ref={field.ref}
        disabled={disabled}
        placeholder={placeholder}
        tabIndex={tabindex}
        onInvalid={
          onInvalid ||
          (() => {
            if (type === 'email') {
              setError(field.name, 'Virheellinen sähköpostiosoite');
            }
          })
        }
        onKeyDown={() => {
          clearErrors(field.name);
        }}
      />
      {fieldState.error && (customOnError || defaultError(fieldState))}
    </div>
  );
};

export default InputWrapper;
