/* eslint-disable react/no-danger */
import React from 'react';
import { Grid } from 'semantic-ui-react';
import Slider from 'react-slick';

import MagazineImage from './MagazineImage';
import MagazineInfoText from './MagazineInfoText';

const MagazineSelectCarousel = (props) => {
  const {
    sliderSettings,
    carouselExtraImage,
    magazines,
    isMagazineSelectBrand,
  } = props;

  // Temporary solution, waiting for customer to comment on
  const isThereExtraImage = () => {
    let extraImage = false;
    magazines.forEach((magazine) => {
      if (magazine.offerExtraImage && magazine.offerExtraImage !== '') {
        extraImage = true;
      }
    });
    return extraImage;
  };

  const isValidUrl = (url) => {
    const inputElement = document.createElement('input');
    inputElement.type = 'url';
    inputElement.value = url;

    return inputElement.checkValidity();
  };

  const FormatExtraImageJsxElement = ({ extraImage }) => {
    const isExtraImageUrl = isValidUrl(extraImage);
    let extraImageElement = '';

    const noneHasExtraImage = isThereExtraImage();

    if (!noneHasExtraImage) {
      return null;
    }

    if (!extraImage) {
      extraImageElement = '';
    } else if (isExtraImageUrl) {
      extraImageElement = (
        <Grid.Row>
          <img
            alt="tarjouksen lisäkuva"
            src={extraImage}
            style={{ marginTop: -10 }}
          />
        </Grid.Row>
      );
    } else {
      extraImageElement = (
        <Grid.Row className="magazine-offer-extra-image-text-only">
          <span dangerouslySetInnerHTML={{ __html: extraImage }} />
        </Grid.Row>
      );
    }
    return extraImageElement;
  };

  if (magazines.length === 1) {
    return (
      <div id="magazineSelectCarousel-singleItem">
        <Grid
          centered
          className="magazine-carousel-grid"
          id={magazines[0].name}
        >
          <Grid.Column computer={4} tablet={4} mobile={16}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <FormatExtraImageJsxElement
                extraImage={magazines[0].offerExtraImage}
              />
              {magazines[0].packageImage && (
                <MagazineImage
                  magazine={magazines[0]}
                  className="mag-image-side-by-side"
                />
              )}
            </div>
          </Grid.Column>
          <Grid.Column computer={4} tablet={4} mobile={16}>
            <MagazineInfoText
              magazine={magazines[0]}
              carouselExtraImage={carouselExtraImage}
              selectButton={isMagazineSelectBrand} // Brand selection must have select button
              singleItem
            />
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  return (
    <div id="magazineSelectCarousel" className="carousel-wrapper">
      {magazines.length > 1 && (
        <Slider
          {...sliderSettings}
          slidesToShow={magazines.length > 3 ? 4 : magazines.length}
        >
          {magazines.map((magazine) => (
            <Grid
              centered
              className="magazine-carousel-grid magazine-carousel-item"
              key={magazine.id}
              id={magazine.name}
            >
              <FormatExtraImageJsxElement
                extraImage={magazine.offerExtraImage}
              />
              {magazine.packageImage && (
                <Grid.Row className="no-padding">
                  <Grid.Column
                    textAlign="center"
                    className="mag-image-on-top-column"
                  >
                    <MagazineImage
                      magazine={magazine}
                      className="mag-image-on-top"
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row className="magazine-info-text-row">
                <Grid.Column textAlign="center">
                  <MagazineInfoText
                    magazine={magazine}
                    selectButton
                    carouselExtraImage={carouselExtraImage}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default MagazineSelectCarousel;
