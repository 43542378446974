import {
  BACKEND_IN_MAINTENANCE_MODE,
  OFFER_INITIAL_STATE,
  OFFER_SET_PARAMS,
  OFFER_ASK_LUKULAHJA,
  OFFER_FETCH,
  OFFER_FETCH_FAILURE,
  OFFER_ASK_TYRKYTE,
  OFFER_FETCH_TYRKYTE,
  OFFER_FETCH_TYRKYTE_FAILURE,
  OFFER_FETCH_LUKULAHJA_FAILURE,
  OFFER_SHOW_POLL,
  OFFER_SEND_POLL,
  OFFER_SEND_POLL_FAILURE,
  OFFER_FETCH_COMPLETE,
  ORDER_INIT,
  ORDER_INIT_FAILURE,
} from '../actions/actionTypes';

export const initialState = {
  state: OFFER_INITIAL_STATE,
  errorText: null,
  offeringId: null,
  params: null,
  gifts: [],
  magazines: [],
  successHtml: '',
  terms: { __html: '' },
};

// eslint-disable-next-line default-param-last
export const offerReducer = (state = initialState, action) => {
  switch (action.type) {
    case OFFER_INITIAL_STATE: {
      return initialState;
    }
    case OFFER_SET_PARAMS: {
      return {
        ...state,
        state: OFFER_SET_PARAMS,
        params: action.payload.params,
      };
    }
    case OFFER_ASK_LUKULAHJA: {
      return {
        ...state,
        state: OFFER_ASK_LUKULAHJA,
      };
    }
    case OFFER_FETCH: {
      return {
        ...state,
        state: OFFER_FETCH,
      };
    }
    case OFFER_FETCH_FAILURE: {
      return {
        ...state,
        state: OFFER_FETCH_FAILURE,
        ...action.payload,
      };
    }
    case OFFER_ASK_TYRKYTE: {
      return {
        ...state,
        state: OFFER_ASK_TYRKYTE,
        ...action.payload,
      };
    }
    case OFFER_FETCH_TYRKYTE: {
      return {
        ...state,
        state: OFFER_FETCH_TYRKYTE,
      };
    }
    case OFFER_FETCH_TYRKYTE_FAILURE: {
      return {
        ...state,
        state: OFFER_FETCH_TYRKYTE_FAILURE,
        ...action.payload,
      };
    }
    case OFFER_FETCH_LUKULAHJA_FAILURE: {
      return {
        ...state,
        state: OFFER_FETCH_LUKULAHJA_FAILURE,
        errorText: action.payload.errorText,
        ...action.payload,
      };
    }
    case OFFER_SHOW_POLL: {
      return {
        ...state,
        state: OFFER_SHOW_POLL,
        ...action.payload,
      };
    }
    case OFFER_SEND_POLL: {
      return {
        ...state,
        state: OFFER_SEND_POLL,
      };
    }
    case OFFER_SEND_POLL_FAILURE: {
      return {
        ...state,
        state: OFFER_SEND_POLL_FAILURE,
      };
    }
    case OFFER_FETCH_COMPLETE: {
      return {
        ...state,
        state: OFFER_FETCH_COMPLETE,
        ...action.payload,
      };
    }
    // Clicked "Tilaa" -button
    case ORDER_INIT: {
      return {
        ...state,
        state: ORDER_INIT,
      };
    }
    // ORDER_INIT_FAILURE here cause state is handled with offer state (transition to order pipeline failed)
    case ORDER_INIT_FAILURE: {
      return {
        ...state,
        state: ORDER_INIT_FAILURE,
        ...action.payload,
      };
    }
    // OTVMSUP-9, dispatch this when backend returns 503
    case BACKEND_IN_MAINTENANCE_MODE: {
      return {
        ...state,
        state: BACKEND_IN_MAINTENANCE_MODE,
      };
    }
    default: {
      return state;
    }
  }
};
