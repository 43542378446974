import React from 'react';

const OtavamediaAccountStatus = ({ otavamediaAccount }) => {
  switch (otavamediaAccount) {
    case 'created':
      return (
        <p>
          Antamallasi sähköpostiosoitteella on luotu Otavamedia-tili, jonka
          tarvitset käyttääksesi Otavamedian verkkopalveluita. Vahvista
          Otavamedia-tili sähköpostiisi lähetetystä linkistä 7&nbsp;päivän
          kuluessa.
        </p>
      );
    case 'existed':
      return (
        <p>
          Antamallasi sähköpostiosoitteella on Otavamedia-tili, jonka tarvitset
          käyttääksesi Otavamedian verkkopalveluita. Mikäli olet unohtanut
          salasanasi, voit vaihtaa sen kirjautumisen yhteydessä.
        </p>
      );
    case 'failed':
      return (
        <div>
          <p>
            Käyttääksesi tilaukseesi kuuluvaa digisisältöä ota yhteyttä
            asiakaspalveluun
            <b>(09)&nbsp;156&nbsp;6227 / </b>
            <a href="mailto:asiakaspalvelu@otavamedia.fi">digi@otavamedia.fi</a>
          </p>
        </div>
      );
    // API2 otavamedia attribute null or didn't exist
    default:
      return null;
  }
};

export default OtavamediaAccountStatus;
