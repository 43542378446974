import React from 'react';

const SliderArrow = (props) => {
  const { className, style, onClick, direction } = props;
  return (
    <div style={{ ...style, display: 'block' }} onClick={onClick}>
      <img
        src={
          direction === 'left'
            ? '/images/Karuselli_nuoli2.png'
            : '/images/Karuselli_nuoli1.png'
        }
        className={`${className} slider-arrow`}
        alt="slider arrow"
      />
    </div>
  );
};

export default SliderArrow;
