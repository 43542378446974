import React from 'react';
import { Routes, Route } from 'react-router-dom';

import ConfirmSubscriptionPage from './components/views/ConfirmSubscriptionPage';
import FailurePage from './components/views/FailurePage';
import InvoicingChangePage from './components/views/InvoicingChangePage';
import LandingPage from './components/views/LandingPage';
import OrderPage from './components/views/OrderPage';
import OrderCompletePage from './components/views/OrderCompletePage';
import RenewCardPage from './components/views/RenewCardPage';
import TallennusPage from './components/views/TallennusPage';

const Router = () => (
  <Routes>
    <Route path="/tallennus" element={<TallennusPage />} />
    <Route path="/success" element={<OrderCompletePage />} />
    <Route path="/order/:method" element={<OrderPage />} />
    <Route path="/order/complete" element={<OrderCompletePage />} />
    <Route path="/card/renew" element={<RenewCardPage />} />
    <Route path="/vaihto/:offerId" element={<InvoicingChangePage />} />
    <Route path="/kortti/:offerId" element={<InvoicingChangePage />} />
    <Route path="/v/:shortId" element={<ConfirmSubscriptionPage />} />
    {/* Keep /:offeringId last since its dynamic root route */}
    <Route path="/:offeringId" element={<LandingPage />} />
    {/* Fallthrough if no route matches */}
    <Route path="/" element={<FailurePage />} />
  </Routes>
);

export default Router;
