import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';

import Context from '../../context';
import LoadingSpinner from './LoadingSpinner';

import * as types from '../../actions/actionTypes';
import t from '../../locales/fi';

const OrderStatus = (params) => {
  const { onReturn } = params;
  const context = useContext(Context);
  const { order } = context;
  const errorText
    = order.errorText || 'Jotain meni pieleen, ota yhteyttä asiakaspalveluun.';

  switch (order.state) {
    case types.ORDER_INIT_SUCCESS:
    case types.ORDER_HANDLING_START:
      return (
        <Grid.Row>
          <LoadingSpinner />
        </Grid.Row>
      );
    case types.ORDER_ADD_CARD:
      return (
        <Grid.Row>
          <LoadingSpinner label={t.REDIRECTING_TO_CARD} />
        </Grid.Row>
      );
    case types.ORDER_CANCEL_INIT:
      return (
        <Grid.Row>
          <LoadingSpinner label={t.CANCELING_ORDER} />
        </Grid.Row>
      );
    case types.ORDER_CANCEL_FAILURE:
      return (
        <Grid.Row>
          <div style={{ textAlign: 'center', minWidth: '0%' }}>
            <p>{order.errorText}</p>
          </div>
        </Grid.Row>
      );
    case types.ORDER_CANCEL_SUCCESS:
      return (
        <Grid.Row>
          <div
            style={{ textAlign: 'center', minWidth: '0%', padding: '2em' }}
            className="wrapper-box-shadow white-bg"
          >
            <p>{t.CANCELING_ORDER_SUCCESS}</p>
            {order.order?.offeringId && (
              <button
                className="return-button"
                id="returnToOffer"
                type="button"
                onClick={() => onReturn()}
              >
                {t.RETURN_TO_OFFER}
              </button>
            )}
          </div>
        </Grid.Row>
      );
    case types.ORDER_POLL_MOBILEPAY_STATUS:
      return (
        <Grid.Row>
          <LoadingSpinner label={t.POLLING_MOBILEPAY_STATUS} />
        </Grid.Row>
      );
    case types.ORDER_POLL_MOBILEPAY_STATUS_FAILED:
      return (
        <Grid.Row>
          <div
            style={{ textAlign: 'center', minWidth: '0%', padding: '2em' }}
            className="wrapper-box-shadow white-bg"
          >
            <p>{errorText}</p>
          </div>
        </Grid.Row>
      );
    case types.ORDER_SUBSCRIBE:
      return (
        <Grid.Row style={{ maxWidth: 800 }}>
          <div
            style={{ textAlign: 'center', minWidth: '0%', width: '100%' }}
            className="wrapper-box-shadow white-bg"
          >
            <h1>Kiitos tilauksestasi</h1>
            <br />
            <br />
            <LoadingSpinner label="Tilaustasi viimeistellään..." />
          </div>
        </Grid.Row>
      );
    case types.ORDER_INVOICE_CALL:
      return (
        <Grid.Row style={{ maxWidth: 800 }}>
          <div
            style={{
              textAlign: 'center',
              minWidth: '0%',
              width: '100%',
              padding: '15px',
            }}
            className="wrapper-box-shadow white-bg"
          >
            <h1>Kiitos!</h1>
            <br />
            <p>{t.INVOICE_PAYMENT_CALL_ORDER_TEXT}</p>
          </div>
        </Grid.Row>
      );
    case types.ORDER_SUBSCRIBE_FAILURE:
      return (
        <Grid.Row>
          <div
            style={{ textAlign: 'center', minWidth: '0%', padding: '2em' }}
            className="wrapper-box-shadow white-bg"
          >
            <p>{order.errorText}</p>
            {order.order?.offeringId && (
              <button
                className="return-button"
                id="returnToOffer"
                type="button"
                onClick={() => onReturn()}
              >
                {t.RETURN_TO_OFFER}
              </button>
            )}
          </div>
        </Grid.Row>
      );
    case types.ORDER_COMPLETE:
      return (
        <Grid.Row>
          <LoadingSpinner />
        </Grid.Row>
      );
    // OTVMSUP-9 Show maintenance mode info
    case types.BACKEND_IN_MAINTENANCE_MODE:
      return (
        <Grid.Row>
          <div style={{ textAlign: 'center', minWidth: '0%' }}>
            <h1>Päivitämme palvelua. Ole hyvä ja yritä myöhemmin uudelleen.</h1>
          </div>
        </Grid.Row>
      );
    default:
      console.log('UNHANDLED STATUS IN ORDER PIPELINE!');
      return (
        <Grid.Row>
          <div
            style={{ textAlign: 'center', minWidth: '0%', padding: '2em' }}
            className="wrapper-box-shadow white-bg"
          >
            <p>Jotain meni pieleen, ota yhteyttä asiakaspalveluun.</p>
          </div>
        </Grid.Row>
      );
  }
};

export default OrderStatus;
