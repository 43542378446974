import React, { useContext } from 'react';

import Context from '../../../context';
import CheckBoxField from './CheckBoxField';
import t from '../../../locales/fi';

const EmailMarketingConsent = () => {
  const context = useContext(Context);
  const { offer } = context;

  if (!offer.params.emailMarketingOption) return null;

  return (
    <div>
      <CheckBoxField
        name="emailMarketingConsent"
        label={t.EMAIL_MARKETING_CONSENT_TEXT}
      />
    </div>
  );
};

export default EmailMarketingConsent;
