/* eslint-disable react/no-danger */
import React, { useContext, useState, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { useFormContext } from 'react-hook-form';
import Context from '../../../context';

const OrderingTerms = () => {
  const context = useContext(Context);
  const { offer } = context;

  const { getValues } = useFormContext();
  const { selectedMagazineId } = getValues();

  const [preInfoText, setPreInfoText] = useState('');

  useEffect(() => {
    const selectedMagazine = offer.magazines.find(
      (m) => m.id === selectedMagazineId,
    );
    setPreInfoText(
      offer?.options?.preinfoText ? offer?.options?.preinfoText : '',
    );
    // If products contain only digi orders, show digi order preinfo text (not if fixed term, which is checked next)
    const onlyDigiOffers = offer?.magazines?.productLines
      ? !offer?.magazines.productLines.find((p) => p.productCode < 70000)
      : false;
    if (onlyDigiOffers) {
      setPreInfoText(
        offer?.options?.preinfoText_DIGI
          ? offer?.options?.preinfoText_DIGI
          : '',
      );
    }
    // Fixed-term. Preinfo text from option.Kampanjasivu_ennakkotiedot_MA
    if (selectedMagazine?.ordertype === '02') {
      setPreInfoText(
        offer?.options?.preinfoTextMA ? offer?.options?.preinfoTextMA : '',
      );
    }
    // Free orders shouldn't have preinfo text.
    if (
      selectedMagazine?.kayakOrderType === 'I'
      || selectedMagazine?.kayakOrderType === 'L'
    ) {
      setPreInfoText('');
    }
  }, [selectedMagazineId]);

  if (offer?.options && offer.options.orderingTerms.__html !== '') {
    return (
      <Grid.Row className="p-0">
        <Grid.Column width={16} className="ordering-terms">
          {preInfoText && <>* {preInfoText}</>}
        </Grid.Column>
      </Grid.Row>
    );
  }
  return null;
};

export default OrderingTerms;
