import React, { useEffect } from 'react';

/**
 * Hidden form which is automatically submitted when component is rendered.
 *
 * @param requestObject     Object containing form key-value pairs
 */
const AddCardForm = ({ urlParams }) => {
  useEffect(() => {
    document.forms.namedItem('addCardForm').submit();
  }, []);
  // Filter paytrail (ex checkout) add card form params from all url params
  const addCardFormParams = [
    'checkout-account',
    'checkout-algorithm',
    'checkout-method',
    'checkout-nonce',
    'checkout-timestamp',
    'checkout-redirect-success-url',
    'checkout-redirect-cancel-url',
    'checkout-callback-success-url',
    'checkout-callback-cancel-url',
    'signature',
    'language',
  ];
  const parsedParams = Object.keys(urlParams).reduce((parsedParams, key) => {
    if (addCardFormParams.includes(key)) {
      parsedParams[key] = urlParams[key];
    }
    return parsedParams;
  }, {});

  const paytrailAddCardUrl
    = 'https://services.paytrail.com/tokenization/addcard-form';

  return (
    <form method="post" action={paytrailAddCardUrl} id="addCardForm">
      {Object.keys(parsedParams).map((key) => (
        <input type="hidden" name={key} key={key} value={parsedParams[key]} />
      ))}
    </form>
  );
};

export default AddCardForm;
