import React, { useReducer } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';

import Context from './context';

import {
  initialState as offerInitialState,
  offerReducer,
} from './reducers/offer';
import {
  initialState as customerInitialState,
  customerReducer,
} from './reducers/customer';
import {
  initialState as orderInitialState,
  orderReducer,
} from './reducers/order';
import {
  initialState as invoicingChangeInitialState,
  invoicingChangeReducer,
} from './reducers/invoicingChange';
import {
  initialState as cardRenewInitialState,
  cardRenewReducer,
} from './reducers/cardRenew';
import {
  initialState as confirmSubscriptionInitialState,
  confirmSubscriptionReducer,
} from './reducers/confirmSubscription';

import Router from './Router';

if (process.env.FRONTEND_SENTRY_ENABLED === '1') {
  Sentry.init({
    dsn: process.env.FRONTEND_SENTRY_DSN,
    tunnel: `${process.env.BACKEND_ENDPOINT}/sentry`,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
  });
}

const App = () => {
  /*
   * Context API reducers for global state management
   */
  const [offer, offerDispatch] = useReducer(offerReducer, offerInitialState);
  const [customer, customerDispatch] = useReducer(
    customerReducer,
    customerInitialState,
  );
  const [order, orderDispatch] = useReducer(orderReducer, orderInitialState);
  const [invoicingChange, invoicingChangeDispatch] = useReducer(
    invoicingChangeReducer,
    invoicingChangeInitialState,
  );
  const [cardRenew, cardRenewDispatch] = useReducer(
    cardRenewReducer,
    cardRenewInitialState,
  );
  const [confirmSubscription, confirmSubscriptionDispatch] = useReducer(
    confirmSubscriptionReducer,
    confirmSubscriptionInitialState,
  );

  return (
    <Context.Provider
      value={{
        offer,
        offerDispatch,
        customer,
        customerDispatch,
        order,
        orderDispatch,
        invoicingChange,
        invoicingChangeDispatch,
        cardRenew,
        cardRenewDispatch,
        confirmSubscription,
        confirmSubscriptionDispatch,
      }}
    >
      <div className="app-container" id="app-container">
        <Grid stackable centered className="no-margin no-padding">
          <Grid.Row centered className="grid-column-no-padding">
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </Grid.Row>
        </Grid>
      </div>
    </Context.Provider>
  );
};

export default App;
