import * as types from './actionTypes';
import * as ga from '../utils/googleAnalytics';
import * as api from '../api/Api';
import {
  getCustomerFromOrder,
  getErrorText,
  parseUrlParams,
} from '../utils/util';
import { PaymentMethods } from '../utils/consts';
import t from '../locales/fi';

/**
 * Initialize order and receive redirectUrl.
 * @param data
 * @param orderDispatch
 * @param offerDispatch
 * @returns {function(...[*]=)}
 */
export const initOrder = async (data, orderDispatch, offerDispatch) => {
  offerDispatch({
    type: types.ORDER_INIT,
    payload: {},
  });
  const response = await api.post('/payment/init', {
    ...data,
    emailConsent: true,
  });
  if (!response.error) {
    window.location.href = response.redirectUrl;
  } else {
    // OTVMSUP-9, backend in maintenance mode
    if (response.status && response.status === 503) {
      orderDispatch({ type: types.BACKEND_IN_MAINTENANCE_MODE });
      return;
    }
    offerDispatch({
      type: types.ORDER_INIT_FAILURE,
      payload: {
        ...response,
      },
    });
  }
};

/**
 * Initialize order and receive redirectUrl.
 * @param data
 * @param orderDispatch
 * @param offerDispatch
 * @returns {function(...[*]=)}
 */
export const tallennusPageSubscribe = async (
  data,
  orderDispatch,
  offerDispatch,
) => {
  /*
   * Init
   */
  orderDispatch({
    type: types.ORDER_INIT,
    payload: {},
  });
  const initResponse = await api.post('/payment/init', {
    ...data,
    emailConsent: true,
  });
  if (initResponse.error) {
    // OTVMSUP-9, backend in maintenance mode
    if (initResponse.status && initResponse.status === 503) {
      orderDispatch({ type: types.BACKEND_IN_MAINTENANCE_MODE });
      return;
    }
    offerDispatch({ type: types.ORDER_INIT_FAILURE });
    return;
  }
  const urlParams = new URL(initResponse.redirectUrl).searchParams;
  if (!urlParams.has('orderId'))
    offerDispatch({ type: types.ORDER_INIT_FAILURE });
  const orderId = urlParams.get('orderId');
  orderDispatch({
    type: types.ORDER_SUBSCRIBE,
    payload: { data: { orderId } },
  });
  /*
   * Subscribe
   */
  const subscribeResponse = await api.post('/tallennus/subscribe', { orderId });
  if (subscribeResponse.error) {
    // OTVMSUP-9, backend in maintenance mode
    if (subscribeResponse.status && subscribeResponse.status === 503) {
      orderDispatch({ type: types.BACKEND_IN_MAINTENANCE_MODE });
      return;
    }
    orderDispatch({ type: types.ORDER_SUBSCRIBE_FAILURE });
    return;
  }
  orderDispatch({
    type: types.ORDER_COMPLETE,
    payload: { response: subscribeResponse },
  });
};

/**
 * Starts card addition.
 * Uses hidden form on OrderPage.jsx to do POST redirect to Paytrail
 * @param urlParams
 * @param orderDispatch
 * @returns {Promise<void>}
 */
export const addCard = async (urlParams, orderDispatch) => {
  orderDispatch({
    type: types.ORDER_ADD_CARD,
    payload: {
      urlParams: parseUrlParams(urlParams),
    },
  });
};

/**
 * Delete order from backend.
 * @param orderId
 * @param orderDispatch
 * @returns {Promise<void>}
 */
export const cancelOrder = async (orderId, orderDispatch) => {
  orderDispatch({
    type: types.ORDER_CANCEL_INIT,
  });
  const response = await api.get(`/order/cancel?orderId=${orderId}`);
  if (response.error) {
    // OTVMSUP-9, backend in maintenance mode
    if (response.status && response.status === 503) {
      orderDispatch({ type: types.BACKEND_IN_MAINTENANCE_MODE });
      return;
    }
    const errorText = getErrorText(response.errorCode);
    orderDispatch({
      type: types.ORDER_CANCEL_FAILURE,
      payload: { errorText },
    });
  } else {
    const order = response.orderId ? response : null;
    orderDispatch({
      type: types.ORDER_CANCEL_SUCCESS,
      payload: {
        order,
      },
    });
  }
};

/**
 * Subscribe.
 * @param params
 * @param orderDispatch
 * @returns {Promise<void>}
 */
export const subscribe = async (params, orderDispatch) => {
  orderDispatch({
    type: types.ORDER_SUBSCRIBE,
    payload: {
      params,
    },
  });
  const response = await api.post('/subscribe', { ...params });
  if (response.error) {
    // OTVMSUP-9, backend in maintenance mode
    if (response.status && response.status === 503) {
      orderDispatch({ type: types.BACKEND_IN_MAINTENANCE_MODE });
      return;
    }
    const errorText = getErrorText(response.errorCode);
    orderDispatch({
      type: types.ORDER_SUBSCRIBE_FAILURE,
      payload: {
        errorText,
        order: response.order,
      },
    });
    return;
  }
  // Push order info to datalayer if subscription successful
  if (response && response.subscriptionSuccessful) {
    // Report to Google Analytics
    ga.googleAnalyticsPurchase(response);
  }
  // Allow skipping of tyrkyte for "order more here" if mag required tyrkyte and it was not disposable
  // We can trust that customer entered tyrkyte before and we get customer details from order details
  const allowOrderMoreTyrkyteSkip
    = response.magazine
    && response.magazine.requiresTyrkyte === '1'
    && response.magazine.disposableCode === '0';
  orderDispatch({
    type: types.ORDER_SET_CUSTOMER,
    payload: {
      customer: {
        firstName: response.order.payerFirstName || '',
        lastName: response.order.payerLastName,
        companyId: response.order.payerCompanyId || '',
        streetAddress: response.order.payerStreetAddress,
        country: response.order.payerCountry,
        zipCode: response.order.payerZipCode,
        city: response.order.payerCity,
        coName1: response.order.payerCoName1 || '',
        email: response.order.payerEmail,
      },
      allowOrderMoreTyrkyteSkip,
    },
  });

  if (response.order.paymentMethod === PaymentMethods.INVOICING_CALL) {
    orderDispatch({
      type: types.ORDER_INVOICE_CALL,
      payload: {
        response,
      },
    });
  } else {
    orderDispatch({
      type: types.ORDER_COMPLETE,
      payload: {
        response,
      },
    });
  }
};

/**
 * Poll MobilePay one-off payment and agreement status and subscribe if successful.
 * @param params
 * @param orderDispatch
 * @returns {Promise<void>}
 */
export const pollMobilePayStatus = async (params, orderDispatch) => {
  orderDispatch({
    type: types.ORDER_POLL_MOBILEPAY_STATUS,
    payload: {
      urlParams: params,
    },
  });
  const { orderId } = params;
  if (!orderId) {
    orderDispatch({
      type: types.ORDER_POLL_MOBILEPAY_STATUS_FAILED,
    });
    return;
  }

  const response = await api.get(`/payment/mobilepay/${orderId}`);

  if (!response.error) {
    const AGREEMENT_STATUS = {
      PENDING: 'Pending',
      ACCEPTED: 'Accepted',
      REJECTED: 'Rejected',
      EXPIRED: 'Expired',
      CANCELED: 'Canceled',
    };
    const PAYMENT_STATUS = {
      CAPTURED: 'Captured',
      CAPTURE_FAILED: 'Capture failed',
      RESERVED: 'Reserved',
      REJECTED: 'Rejected',
      EXPIRED: 'Expired',
      REQUESTED: 'Requested',
    };

    // Resolve status and re-poll if necessary
    switch (response.status) {
      // Agreement pending -> re-poll
      case AGREEMENT_STATUS.PENDING: {
        await pollMobilePayStatus(params, orderDispatch).then();
        return;
      }
      case AGREEMENT_STATUS.ACCEPTED: {
        switch (response.payment_status) {
          // Payment not captured yet but agreement active -> re-poll
          case PAYMENT_STATUS.REQUESTED:
          case PAYMENT_STATUS.RESERVED: {
            await pollMobilePayStatus(params, orderDispatch).then();
            return;
          }
          // All good -> subscribe
          case PAYMENT_STATUS.CAPTURED: {
            subscribe(params, orderDispatch).then();
            return;
          }
          // Payment error
          case PAYMENT_STATUS.REJECTED:
          case PAYMENT_STATUS.EXPIRED: {
            orderDispatch({
              type: types.ORDER_POLL_MOBILEPAY_STATUS_FAILED,
              payload: {
                errorText: t.MOBILEPAY_AGREEMENT_ERROR,
              },
            });
            return;
          }
          default:
            orderDispatch({
              type: types.ORDER_POLL_MOBILEPAY_STATUS_FAILED,
            });
            return;
        }
      }
      // Agreement bad state
      case AGREEMENT_STATUS.REJECTED:
      case AGREEMENT_STATUS.EXPIRED:
      case AGREEMENT_STATUS.CANCELED: {
        orderDispatch({
          type: types.ORDER_POLL_MOBILEPAY_STATUS_FAILED,
          payload: {
            errorText: t.MOBILEPAY_AGREEMENT_ERROR,
          },
        });
        return;
      }
      default: {
        orderDispatch({
          type: types.ORDER_POLL_MOBILEPAY_STATUS_FAILED,
        });
      }
    }
  } else {
    // OTVMSUP-9, backend in maintenance mode
    if (response.status && response.status === 503) {
      orderDispatch({ type: types.BACKEND_IN_MAINTENANCE_MODE });
      return;
    }
    orderDispatch({
      type: types.ORDER_POLL_MOBILEPAY_STATUS_FAILED,
    });
  }
};

/**
 * Used to set customer after order pipeline failed (customer cancel/error),
 * so that user don't have to refill form.
 * @param order
 * @param customerDispatch
 * @returns {Promise<void>}
 */
export const setCustomerFromOrder = async (order, customerDispatch) => {
  const customer = getCustomerFromOrder(order);
  customerDispatch({
    type: types.OFFER_SET_CUSTOMER,
    payload: {
      customer,
      tyrkyteId: order.tyrkyteId, // Can be undefined if not tyrkyte order
    },
  });
};
