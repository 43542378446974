import React, { useContext } from 'react';

import Context from '../../../context';
import GiftSelectList from './GiftSelectList';
import DropdownSelection from './DropdownSelection';

import { formatPrice } from '../../../utils/util';

/**
 * Dropdown gift select.
 * Used on tallennusPage.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const GiftSelectDropdown = () => {
  const context = useContext(Context);
  const { offer } = context;
  const {
    gifts,
    magazines: [{ optionalGifts }],
  } = offer;

  if (gifts && (optionalGifts === '0' || gifts.length === 1)) {
    return <GiftSelectList gifts={gifts} optionalGifts={optionalGifts} />;
  }

  const getGiftOptions = () =>
    gifts.map((gift) => ({
      key: `${gift.id}`,
      value: `${gift.id}`,
      text: `${gift.name}, Arvo ${formatPrice(gift.price)}`,
    }));

  return (
    <div className="giftDropdownMenu">
      {gifts && gifts.length > 1 && (
        <DropdownSelection
          name="selectedGiftId"
          placeholder="Valitse tästä"
          options={getGiftOptions()}
        />
      )}
    </div>
  );
};

export default GiftSelectDropdown;
