import t from '../locales/fi';

export const UnconfirmedSubscriptionError = {
  ALREADY_CONFIRMED: 'ALREADY_CONFIRMED',
  INVALID_STATUS: 'INVALID_STATUS',
};

/**
 * This is equal to 'kind' (SUBSKIND) in Kayak subscription and 'orderType' in offer
 */
export const OrderType = {
  CONTINUOUS: '01', // Jatkuva tilaus
  FIXED: '02', // Määräaikainen
  SINGLE_ISSUE: '03', // Irtonumero
  CHECK_ISSUE: '04', // Tarkistuskappale
  FREE_ISSUE: '05', // Vapaakappale
  SAMPLE: '06', // Näyte
  UNKNOWN: '99', // Tuntematon
};


/**
 * Path params for accessing OrderPage. Redirects are handled based on these.
 * Keep consistent with enum in backend/src/utils/consts.js.
 */
export const PaymentMethods = {
  CARD: 'card',
  MOBILEPAY: 'mobilepay',
  INVOICE: 'invoice', // Old invoice, used by tallennus and offers with no payment methods
  INVOICING_PAYTRAIL: 'paytrail', // verkkomaksu (bank invoice)
  INVOICING_E_IDENT: 'eident',
  INVOICING_CALL: 'call',
};

/**
 * ErrorCodes from backend and API2. Keep consistent with backend ErrorCodes.
 * @type {{}}
 */
export const ErrorCodes = {
  E_API_FETCH_ERROR: 'E_API_FETCH_ERROR',
  E_IDENT_ERROR: 'E_IDENT_ERROR',
  E_IDENT_NAMES_MISMATCH: 'E_IDENT_NAMES_MISMATCH',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  INIT_ORDER_PARAMS_MISSING: 'INIT_ORDER_PARAMS_MISSING',
  INVOICING_CHANGE_PARAMS_MISSING: 'INVOICING_CHANGE_PARAMS_MISSING',
  CARD_VERIFICATION_FAILED: 'CARD_VERIFICATION_FAILED',
  CARD_TOKEN_FETCH_FAILED: 'CARD_TOKEN_FETCH_FAILED',
  CARD_CHARGE_FAILED: 'CARD_CHARGE_FAILED',
  OFFER_PARAMS_MISSING: 'OFFER_PARAMS_MISSING',
  ORDER_NOT_FOUND: 'ORDER_NOT_FOUND',
  SUBSCRIBE_PARAMS_MISSING: 'SUBSCRIBE_PARAMS_MISSING',
  WRONG_PAYMENT_METHOD: 'WRONG_PAYMENT_METHOD',
  // API 2 error codes
  OFFER_INVALID_EXPIRED: 'OFFER_INVALID_EXPIRED',
  OFFER_INVALID_NO_ACTIVE_CAMPAIGN_PAGES: 'OFFER_INVALID_NO_ACTIVE_CAMPAIGN_PAGES',
  OFFER_INVALID_ALL_OFFERS_HAVE_ACTIVE_SUBSCRIPTION: 'OFFER_INVALID_ALL_OFFERS_HAVE_ACTIVE_SUBSCRIPTION',
  OFFER_INVALID_MATCHING_BRAND_NOT_FOUND: 'OFFER_INVALID_MATCHING_BRAND_NOT_FOUND',
  OFFERING_ID_INVALID: 'OFFERING_ID_INVALID',
  OFFERING_INVALID_NOT_FOUND_WITH_TYRKYTE: 'OFFERING_INVALID_NOT_FOUND_WITH_TYRKYTE',
  TYRKYTE_INVALID: 'TYRKYTE_INVALID',
  TYRKYTE_INVALID_EXPIRED: 'TYRKYTE_INVALID_EXPIRED',
  TYRKYTE_INVALID_MALFORMED: 'TYRKYTE_INVALID_MALFORMED', // Not used?
  TYRKYTE_INVALID_ALREADY_USED: 'TYRKYTE_INVALID_ALREADY_USED',
};

export const ErrorCodeMap = {
  // Backend error codes
  [ErrorCodes.E_API_FETCH_ERROR]: t.DEFAULT_ERROR_HTML,
  [ErrorCodes.E_IDENT_ERROR]: t.E_IDENT_ERROR,
  [ErrorCodes.E_IDENT_NAMES_MISMATCH]: t.E_IDENT_NAMES_MISMATCH,
  [ErrorCodes.INTERNAL_SERVER_ERROR]: t.DEFAULT_ERROR,
  [ErrorCodes.INIT_ORDER_PARAMS_MISSING]: 'INIT_ORDER_PARAMS_MISSING',
  [ErrorCodes.CARD_VERIFICATION_FAILED]: t.CARD_FAILURE,
  [ErrorCodes.CARD_TOKEN_FETCH_FAILED]: t.CARD_FAILURE,
  [ErrorCodes.CARD_CHARGE_FAILED]: t.CARD_FAILURE,
  [ErrorCodes.OFFER_PARAMS_MISSING]: t.DEFAULT_ERROR_HTML,
  [ErrorCodes.ORDER_NOT_FOUND]: t.ORDER_NOT_FOUND,
  [ErrorCodes.SUBSCRIBE_PARAMS_MISSING]: 'SUBSCRIBE_PARAMS_MISSING',
  [ErrorCodes.WRONG_PAYMENT_METHOD]: 'WRONG_PAYMENT_METHOD',
  // API 2 error codes
  [ErrorCodes.OFFER_INVALID_EXPIRED]: t.OFFER_INVALID_EXPIRED,
  [ErrorCodes.OFFER_INVALID_NO_ACTIVE_CAMPAIGN_PAGES]: 'OFFER_INVALID_NO_ACTIVE_CAMPAIGN_PAGES',
  [ErrorCodes.OFFER_INVALID_ALL_OFFERS_HAVE_ACTIVE_SUBSCRIPTION]: 'OFFER_INVALID_ALL_OFFERS_HAVE_ACTIVE_SUBSCRIPTION',
  [ErrorCodes.OFFER_INVALID_MATCHING_BRAND_NOT_FOUND]: 'OFFER_INVALID_MATCHING_BRAND_NOT_FOUND',
  [ErrorCodes.OFFERING_ID_INVALID]: t.COULD_NOT_FIND_OFFER,
  [ErrorCodes.OFFERING_INVALID_NOT_FOUND_WITH_TYRKYTE]: 'OFFERING_INVALID_NOT_FOUND_WITH_TYRKYTE',
  [ErrorCodes.TYRKYTE_INVALID]: t.TYRKYTE_INVALID,
  [ErrorCodes.TYRKYTE_INVALID_EXPIRED]: t.TYRKYTE_INVALID_EXPIRED,
  [ErrorCodes.TYRKYTE_INVALID_MALFORMED]: t.TYRKYTE_INVALID, // Not used?
  [ErrorCodes.TYRKYTE_INVALID_ALREADY_USED]: t.TYRKYTE_INVALID_ALREADY_USED,
};

export const NEW_INVOICE_HARDCODED_TESTING_OFFERING_ID = process.env.FRONTEND_NEW_INVOICE_HARDCODED_TESTING_OFFERING_ID;
export const mobilePayEnabled = process.env.FRONTEND_MOBILE_PAY_ENABLED === '1';
