import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useController, useFormContext } from 'react-hook-form';

const countrySelectValues = [
  { key: 'AF', value: 'AF', text: 'Afganistan' },
  { key: 'AX', value: 'AX', text: 'Ahvenanmaa' },
  { key: 'NL', value: 'NL', text: 'Alankomaat' },
  { key: 'AL', value: 'AL', text: 'Albania' },
  { key: 'DZ', value: 'DZ', text: 'Algeria' },
  { key: 'AS', value: 'AS', text: 'Amerikan Samoa' },
  { key: 'AD', value: 'AD', text: 'Andorra' },
  { key: 'AO', value: 'AO', text: 'Angola' },
  { key: 'AI', value: 'AI', text: 'Anguilla' },
  { key: 'AQ', value: 'AQ', text: 'Antarktis' },
  { key: 'AG', value: 'AG', text: 'Antigua ja Barbuda' },
  { key: 'AE', value: 'AE', text: 'Arabiemiirikunnat' },
  { key: 'AR', value: 'AR', text: 'Argentiina' },
  { key: 'AM', value: 'AM', text: 'Armenia' },
  { key: 'AW', value: 'AW', text: 'Aruba' },
  { key: 'AU', value: 'AU', text: 'Australia' },
  { key: 'AZ', value: 'AZ', text: 'Azerbaidžan' },
  { key: 'BS', value: 'BS', text: 'Bahama' },
  { key: 'BH', value: 'BH', text: 'Bahrain' },
  { key: 'BD', value: 'BD', text: 'Bangladesh' },
  { key: 'BB', value: 'BB', text: 'Barbados' },
  { key: 'BE', value: 'BE', text: 'Belgia' },
  { key: 'BZ', value: 'BZ', text: 'Belize' },
  { key: 'BJ', value: 'BJ', text: 'Benin' },
  { key: 'BM', value: 'BM', text: 'Bermuda' },
  { key: 'BT', value: 'BT', text: 'Bhutan' },
  { key: 'BO', value: 'BO', text: 'Bolivia' },
  { key: 'BQ', value: 'BQ', text: 'Bonaire, Sint Eustatius ja Saba' },
  { key: 'BA', value: 'BA', text: 'Bosnia ja Hertsegovina' },
  { key: 'BW', value: 'BW', text: 'Botswana' },
  { key: 'BV', value: 'BV', text: 'Bouvet\'nsaari' },
  { key: 'BR', value: 'BR', text: 'Brasilia' },
  { key: 'GB', value: 'GB', text: 'Britannia' },
  { key: 'IO', value: 'IO', text: 'Brittiläinen Intian valtameren alue' },
  { key: 'VG', value: 'VG', text: 'Brittiläiset Neitsytsaaret' },
  { key: 'BN', value: 'BN', text: 'Brunei' },
  { key: 'BG', value: 'BG', text: 'Bulgaria' },
  { key: 'BF', value: 'BF', text: 'Burkina Faso' },
  { key: 'BI', value: 'BI', text: 'Burundi' },
  { key: 'KY', value: 'KY', text: 'Caymansaaret' },
  { key: 'CL', value: 'CL', text: 'Chile' },
  { key: 'CK', value: 'CK', text: 'Cookinsaaret' },
  { key: 'CR', value: 'CR', text: 'Costa Rica' },
  { key: 'CW', value: 'CW', text: 'Curaçao' },
  { key: 'DJ', value: 'DJ', text: 'Djibouti' },
  { key: 'DM', value: 'DM', text: 'Dominica' },
  { key: 'DO', value: 'DO', text: 'Dominikaaninen tasavalta' },
  { key: 'EC', value: 'EC', text: 'Ecuador' },
  { key: 'EG', value: 'EG', text: 'Egypti' },
  { key: 'SV', value: 'SV', text: 'El Salvador' },
  { key: 'ER', value: 'ER', text: 'Eritrea' },
  { key: 'ES', value: 'ES', text: 'Espanja' },
  { key: 'ZA', value: 'ZA', text: 'Etelä-Afrikka' },
  { key: 'GS', value: 'GS', text: 'Etelä-Georgia ja Eteläiset Sandwichsaaret' },
  { key: 'SS', value: 'SS', text: 'Etelä-Sudan' },
  { key: 'ET', value: 'ET', text: 'Etiopia' },
  { key: 'FK', value: 'FK', text: 'Falklandinsaaret' },
  { key: 'FJ', value: 'FJ', text: 'Fidži' },
  { key: 'PH', value: 'PH', text: 'Filippiinit' },
  { key: 'FO', value: 'FO', text: 'Färsaaret' },
  { key: 'GA', value: 'GA', text: 'Gabon' },
  { key: 'GM', value: 'GM', text: 'Gambia' },
  { key: 'GE', value: 'GE', text: 'Georgia' },
  { key: 'GH', value: 'GH', text: 'Ghana' },
  { key: 'GI', value: 'GI', text: 'Gibraltar' },
  { key: 'GD', value: 'GD', text: 'Grenada' },
  { key: 'GL', value: 'GL', text: 'Grönlanti' },
  { key: 'GP', value: 'GP', text: 'Guadeloupe' },
  { key: 'GU', value: 'GU', text: 'Guam' },
  { key: 'GT', value: 'GT', text: 'Guatemala' },
  { key: 'GG', value: 'GG', text: 'Guernsey' },
  { key: 'GN', value: 'GN', text: 'Guinea' },
  { key: 'GW', value: 'GW', text: 'Guinea-Bissau' },
  { key: 'GY', value: 'GY', text: 'Guyana' },
  { key: 'HT', value: 'HT', text: 'Haiti' },
  { key: 'HM', value: 'HM', text: 'Heard ja McDonaldinsaaret' },
  { key: 'HN', value: 'HN', text: 'Honduras' },
  { key: 'HK', value: 'HK', text: 'Hongkong' },
  { key: 'ID', value: 'ID', text: 'Indonesia' },
  { key: 'IN', value: 'IN', text: 'Intia' },
  { key: 'IQ', value: 'IQ', text: 'Irak' },
  { key: 'IR', value: 'IR', text: 'Iran' },
  { key: 'IE', value: 'IE', text: 'Irlanti' },
  { key: 'IS', value: 'IS', text: 'Islanti' },
  { key: 'IL', value: 'IL', text: 'Israel' },
  { key: 'IT', value: 'IT', text: 'Italia' },
  { key: 'TL', value: 'TL', text: 'Itä-Timor' },
  { key: 'AT', value: 'AT', text: 'Itävalta' },
  { key: 'JM', value: 'JM', text: 'Jamaika' },
  { key: 'JP', value: 'JP', text: 'Japani' },
  { key: 'YE', value: 'YE', text: 'Jemen' },
  { key: 'JE', value: 'JE', text: 'Jersey' },
  { key: 'JO', value: 'JO', text: 'Jordania' },
  { key: 'CX', value: 'CX', text: 'Joulusaari' },
  { key: 'KH', value: 'KH', text: 'Kambodža' },
  { key: 'CM', value: 'CM', text: 'Kamerun' },
  { key: 'CA', value: 'CA', text: 'Kanada' },
  { key: 'CV', value: 'CV', text: 'Kap Verde' },
  { key: 'KZ', value: 'KZ', text: 'Kazakstan' },
  { key: 'KE', value: 'KE', text: 'Kenia' },
  { key: 'CF', value: 'CF', text: 'Keski-Afrikan tasavalta' },
  { key: 'CN', value: 'CN', text: 'Kiina' },
  { key: 'KG', value: 'KG', text: 'Kirgisia' },
  { key: 'KI', value: 'KI', text: 'Kiribati' },
  { key: 'CO', value: 'CO', text: 'Kolumbia' },
  { key: 'KM', value: 'KM', text: 'Komorit' },
  { key: 'CG', value: 'CG', text: 'Kongo (Brazzaville)' },
  { key: 'CD', value: 'CD', text: 'Kongo (Kinshasa)' },
  { key: 'CC', value: 'CC', text: 'Kookossaaret' },
  { key: 'KP', value: 'KP', text: 'Pohjois-Korea' },
  { key: 'KR', value: 'KR', text: 'Etelä-Korea' },
  { key: 'GR', value: 'GR', text: 'Kreikka' },
  { key: 'HR', value: 'HR', text: 'Kroatia' },
  { key: 'CU', value: 'CU', text: 'Kuuba' },
  { key: 'KW', value: 'KW', text: 'Kuwait' },
  { key: 'CY', value: 'CY', text: 'Kypros' },
  { key: 'LA', value: 'LA', text: 'Laos' },
  { key: 'LV', value: 'LV', text: 'Latvia' },
  { key: 'LS', value: 'LS', text: 'Lesotho' },
  { key: 'LB', value: 'LB', text: 'Libanon' },
  { key: 'LR', value: 'LR', text: 'Liberia' },
  { key: 'LY', value: 'LY', text: 'Libya' },
  { key: 'LI', value: 'LI', text: 'Liechtenstein' },
  { key: 'LT', value: 'LT', text: 'Liettua' },
  { key: 'LU', value: 'LU', text: 'Luxemburg' },
  { key: 'EH', value: 'EH', text: 'Länsi-Sahara' },
  { key: 'MO', value: 'MO', text: 'Macao' },
  { key: 'MG', value: 'MG', text: 'Madagaskar' },
  { key: 'MK', value: 'MK', text: 'Makedonia' },
  { key: 'MW', value: 'MW', text: 'Malawi' },
  { key: 'MV', value: 'MV', text: 'Malediivit' },
  { key: 'MY', value: 'MY', text: 'Malesia' },
  { key: 'ML', value: 'ML', text: 'Mali' },
  { key: 'MT', value: 'MT', text: 'Malta' },
  { key: 'IM', value: 'IM', text: 'Mansaari' },
  { key: 'MA', value: 'MA', text: 'Marokko' },
  { key: 'MH', value: 'MH', text: 'Marshallinsaaret' },
  { key: 'MQ', value: 'MQ', text: 'Martinique' },
  { key: 'MR', value: 'MR', text: 'Mauritania' },
  { key: 'MU', value: 'MU', text: 'Mauritius' },
  { key: 'YT', value: 'YT', text: 'Mayotte' },
  { key: 'MX', value: 'MX', text: 'Meksiko' },
  { key: 'FM', value: 'FM', text: 'Mikronesia' },
  { key: 'MD', value: 'MD', text: 'Moldova' },
  { key: 'MC', value: 'MC', text: 'Monaco' },
  { key: 'MN', value: 'MN', text: 'Mongolia' },
  { key: 'ME', value: 'ME', text: 'Montenegro' },
  { key: 'MS', value: 'MS', text: 'Montserrat' },
  { key: 'MZ', value: 'MZ', text: 'Mosambik' },
  { key: 'MM', value: 'MM', text: 'Myanmar' },
  { key: 'NA', value: 'NA', text: 'Namibia' },
  { key: 'NR', value: 'NR', text: 'Nauru' },
  { key: 'NP', value: 'NP', text: 'Nepal' },
  { key: 'NI', value: 'NI', text: 'Nicaragua' },
  { key: 'NE', value: 'NE', text: 'Niger' },
  { key: 'NG', value: 'NG', text: 'Nigeria' },
  { key: 'NU', value: 'NU', text: 'Niue' },
  { key: 'NF', value: 'NF', text: 'Norfolkinsaari' },
  { key: 'NO', value: 'NO', text: 'Norja' },
  { key: 'CI', value: 'CI', text: 'Norsunluurannikko' },
  { key: 'OM', value: 'OM', text: 'Oman' },
  { key: 'PK', value: 'PK', text: 'Pakistan' },
  { key: 'PW', value: 'PW', text: 'Palau' },
  { key: 'PS', value: 'PS', text: 'Palestiina' },
  { key: 'PA', value: 'PA', text: 'Panama' },
  { key: 'PG', value: 'PG', text: 'Papua-Uusi-Guinea' },
  { key: 'PY', value: 'PY', text: 'Paraguay' },
  { key: 'PE', value: 'PE', text: 'Peru' },
  { key: 'PN', value: 'PN', text: 'Pitcairn' },
  { key: 'MP', value: 'MP', text: 'Pohjois-Mariaanit' },
  { key: 'PT', value: 'PT', text: 'Portugali' },
  { key: 'PR', value: 'PR', text: 'Puerto Rico' },
  { key: 'PL', value: 'PL', text: 'Puola' },
  { key: 'GQ', value: 'GQ', text: 'Päiväntasaajan Guinea' },
  { key: 'QA', value: 'QA', text: 'Qatar' },
  { key: 'FR', value: 'FR', text: 'Ranska' },
  { key: 'TF', value: 'TF', text: 'Ranskan eteläiset alueet' },
  { key: 'GF', value: 'GF', text: 'Ranskan Guayana' },
  { key: 'PF', value: 'PF', text: 'Ranskan Polynesia' },
  { key: 'RE', value: 'RE', text: 'Réunion' },
  { key: 'RO', value: 'RO', text: 'Romania' },
  { key: 'RW', value: 'RW', text: 'Ruanda' },
  { key: 'SE', value: 'SE', text: 'Ruotsi' },
  { key: 'BL', value: 'BL', text: 'Saint Barthélemy' },
  {
    key: 'SH',
    value: 'SH',
    text: 'Saint Helena, Ascension ja Tristan da Cunha',
  },
  { key: 'KN', value: 'KN', text: 'Saint Kitts ja Nevis' },
  { key: 'LC', value: 'LC', text: 'Saint Lucia' },
  { key: 'MF', value: 'MF', text: 'Saint Martin' },
  { key: 'VC', value: 'VC', text: 'Saint Vincent ja Grenadiinit' },
  { key: 'PM', value: 'PM', text: 'Saint-Pierre ja Miquelon' },
  { key: 'DE', value: 'DE', text: 'Saksa' },
  { key: 'SB', value: 'SB', text: 'Salomonsaaret' },
  { key: 'ZM', value: 'ZM', text: 'Sambia' },
  { key: 'WS', value: 'WS', text: 'Samoa' },
  { key: 'SM', value: 'SM', text: 'San Marino' },
  { key: 'ST', value: 'ST', text: 'São Tomé ja Príncipe' },
  { key: 'SA', value: 'SA', text: 'Saudi-Arabia' },
  { key: 'SN', value: 'SN', text: 'Senegal' },
  { key: 'RS', value: 'RS', text: 'Serbia' },
  { key: 'SC', value: 'SC', text: 'Seychellit' },
  { key: 'SL', value: 'SL', text: 'Sierra Leone' },
  { key: 'SG', value: 'SG', text: 'Singapore' },
  { key: 'SX', value: 'SX', text: 'Sint Maarten' },
  { key: 'SK', value: 'SK', text: 'Slovakia' },
  { key: 'SI', value: 'SI', text: 'Slovenia' },
  { key: 'SO', value: 'SO', text: 'Somalia' },
  { key: 'LK', value: 'LK', text: 'Sri Lanka' },
  { key: 'SD', value: 'SD', text: 'Sudan' },
  { key: 'FI', value: 'FI', text: 'Suomi' }, // Default value
  { key: 'SR', value: 'SR', text: 'Suriname' },
  { key: 'SJ', value: 'SJ', text: 'Svalbard ja Jan Mayen' },
  { key: 'SZ', value: 'SZ', text: 'Swazimaa' },
  { key: 'CH', value: 'CH', text: 'Sveitsi' },
  { key: 'SY', value: 'SY', text: 'Syyria' },
  { key: 'TJ', value: 'TJ', text: 'Tadžikistan' },
  { key: 'TW', value: 'TW', text: 'Taiwan' },
  { key: 'TZ', value: 'TZ', text: 'Tansania' },
  { key: 'DK', value: 'DK', text: 'Tanska' },
  { key: 'TH', value: 'TH', text: 'Thaimaa' },
  { key: 'TG', value: 'TG', text: 'Togo' },
  { key: 'TK', value: 'TK', text: 'Tokelau' },
  { key: 'TO', value: 'TO', text: 'Tonga' },
  { key: 'TT', value: 'TT', text: 'Trinidad ja Tobago' },
  { key: 'TD', value: 'TD', text: 'Tšad' },
  { key: 'CZ', value: 'CZ', text: 'Tšekki' },
  { key: 'TN', value: 'TN', text: 'Tunisia' },
  { key: 'TR', value: 'TR', text: 'Turkki' },
  { key: 'TM', value: 'TM', text: 'Turkmenistan' },
  { key: 'TC', value: 'TC', text: 'Turks- ja Caicossaaret' },
  { key: 'TV', value: 'TV', text: 'Tuvalu' },
  { key: 'UG', value: 'UG', text: 'Uganda' },
  { key: 'UA', value: 'UA', text: 'Ukraina' },
  { key: 'HU', value: 'HU', text: 'Unkari' },
  { key: 'UY', value: 'UY', text: 'Uruguay' },
  { key: 'NC', value: 'NC', text: 'Uusi-Kaledonia' },
  { key: 'NZ', value: 'NZ', text: 'Uusi-Seelanti' },
  { key: 'UZ', value: 'UZ', text: 'Uzbekistan' },
  { key: 'BY', value: 'BY', text: 'Valko-Venäjä' },
  { key: 'WF', value: 'WF', text: 'Wallis ja Futuna' },
  { key: 'VU', value: 'VU', text: 'Vanuatu' },
  { key: 'VA', value: 'VA', text: 'Vatikaani' },
  { key: 'VE', value: 'VE', text: 'Venezuela' },
  { key: 'RU', value: 'RU', text: 'Venäjä' },
  { key: 'VN', value: 'VN', text: 'Vietnam' },
  { key: 'EE', value: 'EE', text: 'Viro' },
  { key: 'US', value: 'US', text: 'Yhdysvallat' },
  { key: 'VI', value: 'VI', text: 'Yhdysvaltain Neitsytsaaret' },
  { key: 'UM', value: 'UM', text: 'Yhdysvaltain pienet erillissaaret' },
  { key: 'ZW', value: 'ZW', text: 'Zimbabwe' },
];

const CountrySelect = (props) => {
  const { name } = props;
  const { control } = useFormContext();
  const { field } = useController({ control, name });

  return (
    <div>
      <div>
        <span className="country-select-label">Maa</span>
      </div>
      <Dropdown
        disabled
        className="country-select-dropdown"
        selection
        fluid
        search
        defaultValue="FI"
        name={field.name}
        options={countrySelectValues}
        onChange={(event, data) => {
          field.onChange(data.value);
        }}
      />
    </div>
  );
};

export default CountrySelect;
