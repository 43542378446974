/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useLayoutEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { Navigate } from 'react-router-dom';

import OtavamediaAccountStatus from './OtavamediaAccountStatus';

import { PaymentMethods, OrderType } from '../../utils/consts';
import t from '../../locales/fi';

const {
  formatPrice,
  resolveIsTokenRedirectAllowed,
  resolveTokenRedirectUrl,
} = require('../../utils/util');

// TODO: API2 returns old payer e-mail??!!
/**
 * Thank You -page content for campaign page orders.
 *
 * @return {JSX.Element}
 * @constructor
 * @param props
 */
const ThankYouContent = (props) => {
  const { subscription, order, isDigiOrder, options } = props;
  const [redirectBackToOffer, setRedirectBackToOffer] = useState(false);

  const { magazine } = subscription;
  const { api2subscription } = subscription;

  const orderSuccessText = { __html: magazine.orderSuccessText };
  const sellMoreText = { __html: magazine.sellMoreText };
  const cover = magazine.packageImage;
  const startDate = new Date(
    Date.parse(api2subscription.startDate),
  ).toLocaleDateString('fi-FI');

  const toggleOrderMoreRedirect = () => {
    setRedirectBackToOffer(true);
  };

  // Redirect url to external site if digi-only order
  let digiOnlyRedirectUrl = null;
  if (subscription.digiOnly && resolveIsTokenRedirectAllowed(subscription)) {
    digiOnlyRedirectUrl = resolveTokenRedirectUrl(
      order.articleURL,
      subscription.readToken,
      false,
    );
  }

  // Thank you page, Tilausjakson tiedot -> maksuerä
  const ShowInstalment = () => {
    if (
      api2subscription.invoicingMode !== '13'
      && api2subscription.invoicingMode !== '11'
      && magazine.numOfPayments > 1
    ) {
      // Explicitly return null if customer selects "Pay in 1 instalment" to hide instalment from order info
      if (order.numOfPayments && order.numOfPayments <= 1) return null;
      return (
        <p>
          <b>Maksuerät:</b>
          <br />
          Voit halutessasi maksaa laskusi {magazine.numOfPayments} erässä (
          {magazine.numOfPayments} x{' '}
          {formatPrice(
            api2subscription.priceAmountIncludingVat,
            null,
            magazine.numOfPayments,
          )}
          )
        </p>
      );
    }
    // Explicitly return null if customer selects "Pay in 1 instalment" to hide instalment from order info
    return null;
  };

  const ShowPaymentMethod = () => {
    // hide payment method if order is of kind 02 (temporary) and offer price is "0,00" (free)
    if (
      (api2subscription.kind === '02'
        && parseFloat(magazine.offerPrice.replace(',', '.')) === 0)
      || api2subscription.kind === OrderType.SAMPLE
    ) {
      return null;
    }

    switch (api2subscription.invoicingMode) {
      case '01': {
        const invoiceInfoText
          = options?.campaignpages?.invoice?.infoText || null;
        return (
          <>
            <p>
              <b>Maksutapa:</b>
              <br />
              Lasku
            </p>
            {invoiceInfoText && (
              <p style={{ marginBottom: 10 }}>{invoiceInfoText}</p>
            )}
          </>
        );
      }
      case '11': {
        let bankInfoTextKesto
          = options?.campaignpages?.bank?.infoTextKesto || null;
        bankInfoTextKesto
          = magazine.salesType !== 'Määräaikainen' ? bankInfoTextKesto : null;
        return (
          <>
            <p>
              <b>Maksutapa:</b>
              <br />
              {t.BANK_PAYMENT}
            </p>
            {bankInfoTextKesto && (
              <p style={{ marginBottom: 10 }}>{bankInfoTextKesto}</p>
            )}
          </>
        );
      }
      case '13': {
        let creditCardInfoTextKesto
          = options?.campaignpages?.creditCard?.infoTextKesto || null;
        creditCardInfoTextKesto
          = magazine.salesType !== 'Määräaikainen'
            ? creditCardInfoTextKesto
            : null;
        return (
          <>
            <p>
              <b>Maksutapa:</b>
              <br />
              {t.CARD_PAYMENT_PLAIN}
            </p>
            {creditCardInfoTextKesto && (
              <p style={{ marginBottom: 10 }}>{creditCardInfoTextKesto}</p>
            )}
          </>
        );
      }
      case '44': {
        let mobilePayInfoTextKesto = options?.mobilePayDurationText || null;
        mobilePayInfoTextKesto
          = magazine.salesType !== 'Määräaikainen'
            ? mobilePayInfoTextKesto
            : null;
        return (
          <>
            <p>
              <b>Maksutapa:</b>
              <br />
              MobilePay
            </p>
            {mobilePayInfoTextKesto && (
              <p style={{ marginBottom: 10 }}>{mobilePayInfoTextKesto}</p>
            )}
          </>
        );
      }
      default:
        return null;
    }
  };

  /*
   * OTVMSUP-1155
   * Note: Same logic is needed in emailService.js on backend. (vahvistusviesti)
   *
   * Conditions to show e invoice info:
   *  - Not shown if CARD or MOBILEPAY
   *  - Not shown if PAYTRAIL and not continuing subscription (määräaikainen)
   *  - Not shown if free order (price 0)
   *  - Otherwise shown (All invoicing modes and PAYTRAIL + continuing sub)
   */
  const ShowEInvoiceInfo = () => {
    if (!api2subscription?.subscriber?.customerId) return '';
    const { paymentMethod, price } = order;
    const doesNotRequireEInvoiceInfo
      = paymentMethod === PaymentMethods.CARD
      || paymentMethod === PaymentMethods.MOBILEPAY;
    const freeOrder = price === 0;
    const orderTypeIsNotOngoing = order.kind !== '01'; // 01 = Kestotilaus
    if (
      doesNotRequireEInvoiceInfo
      || (paymentMethod === PaymentMethods.INVOICING_PAYTRAIL
        && orderTypeIsNotOngoing)
      || freeOrder
    ) {
      return '';
    }
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: options?.campaignpages?.invoice?.eInvoiceInfoHTML,
        }}
      />
    );
  };

  /**
   * * OTVMSUP-171
   * * After DOM has rendered add event listener to resize.
   * * Removes (Adds 'no-before' class to element) underline from offering title if text overflows (e.g. text on 2 lines)
   */
  useLayoutEffect(() => {
    window.addEventListener('resize', titleUnderlineHandler);
    titleUnderlineHandler();
    return () => window.removeEventListener('resize', titleUnderlineHandler);
  }, []);

  const titleUnderlineHandler = () => {
    const titles = document.getElementsByTagName('h1');
    if (titles) {
      Array.from(titles).forEach((title) => {
        if (title.scrollHeight > 50) {
          title.classList.add('no-before');
        } else {
          title.classList.remove('no-before');
        }
      });
    }
  };
  return (
    <Grid className="order-content">
      <div className="grid-content" style={{ marginBottom: 0 }}>
        <h2 style={{ textAlign: 'left' }}>Kiitos tilauksesta!</h2>
        {isDigiOrder && (
          <Grid.Row style={{ paddingTop: 0, background: 'white' }}>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={16}
              style={{ textAlign: 'left' }}
            >
              <OtavamediaAccountStatus
                otavamediaAccount={subscription.otavamediaAccount}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        {order.returnUrl && (
          <Grid.Row style={{ background: 'white' }}>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <a href={order.returnUrl} target="_blank" rel="noreferrer">
                Palaa verkkopalveluun »
              </a>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid style={{ paddingLeft: 0 }}>
          <Grid.Row className="grid-thank-you-summary-text">
            <h2>Tilausjakson tiedot</h2>
            <Grid.Column
              floated="left"
              mobile={cover ? 12 : 16}
              tablet={cover ? 12 : 16}
              computer={cover ? 12 : 16}
              style={{ paddingLeft: 0 }}
            >
              <div>
                <span className="thank-you-package-name-type-text">
                  {api2subscription.packageName}
                </span>
                <br />
                <span>{magazine.salesType.toString()}</span>
                <br />
                <br />
                <p>
                  <b>Tarjousjakso:</b>
                  <br />
                  {magazine.orderDurationType === 'nroa'
                    && api2subscription.issueQuantity}
                  {magazine.orderDurationType === 'kk'
                    && api2subscription.months}
                  {magazine.orderDurationType === 'päivää'
                    && api2subscription.days}{' '}
                  {magazine.orderDurationType}{' '}
                  {formatPrice(api2subscription.priceAmountIncludingVat)}
                  {magazine.dontShowNormalPrice !== '1' && (
                    <span>
                      {' '}
                      (norm.{' '}
                      {magazine.orderDurationType === 'nroa'
                        && api2subscription.issueQuantity}
                      {magazine.orderDurationType === 'kk'
                        && api2subscription.months}
                      {magazine.orderDurationType === 'päivää'
                        && api2subscription.days}{' '}
                      {magazine.orderDurationType}{' '}
                      {formatPrice(magazine.normalPrice)})
                    </span>
                  )}
                </p>
                <ShowInstalment />
                <p>
                  <b>Alkamisaika:</b>
                  <br />
                  {startDate}
                </p>
                <ShowPaymentMethod />
                <ShowEInvoiceInfo />
                {order.gifts && order.gifts.length > 0 && (
                  <p>
                    <b>Tilaajalahja:</b>
                    <br />
                    {order.gifts.length === 1
                      && order.gifts.map((gift) => (
                        <span key={gift.id}> {gift.name}</span>
                      ))}
                  </p>
                )}
                {order.gifts.length > 1 && (
                  <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {order.gifts.map((gift) => (
                      <li key={gift.id}>{gift.name}</li>
                    ))}
                  </ul>
                )}
              </div>
            </Grid.Column>

            {sellMoreText.__html !== '' ? (
              <Grid.Column
                floated="left"
                className="p-0"
                mobile={16}
                tablet={16}
                computer={16}
              >
                <div
                  className="text-align-left"
                  dangerouslySetInnerHTML={sellMoreText}
                />
              </Grid.Column>
            ) : (
              <>
                {magazine.offerPrice !== '0'
                  && subscription.options.sellMoreText?.__html && (
                  <Grid.Column
                    className="grid-thank-you-column"
                    mobile={16}
                    tablet={16}
                    computer={16}
                  >
                    <div
                      className="grid-thank-you-column"
                      dangerouslySetInnerHTML={
                        subscription.options.sellMoreText
                      }
                    />
                  </Grid.Column>
                )}
              </>
            )}

            {api2subscription.payer ? (
              <Grid.Column width={16} className="grid-thank-you-column">
                <h2 style={{ textAlign: 'left' }}>Tilaajan tiedot</h2>
                {!magazine.payerCustomerId && (
                  <>
                    {api2subscription.payer.firstName
                      && api2subscription.payer.companyId === '' && (
                      <p>
                        <b>Nimi:</b>
                        <br />
                        {api2subscription.payer.firstName}{' '}
                        {api2subscription.payer.lastName}
                      </p>
                    )}
                    {api2subscription.payer.companyId
                      && api2subscription.payer.firstName === '' && (
                      <p>
                        <b>Nimi:</b>
                        <br />
                        {api2subscription.payer.lastName}{' '}
                        {api2subscription.payer.companyId}
                      </p>
                    )}
                    <p>
                      <b>Osoite:</b>
                      <br />
                      {api2subscription.payer.street1}{' '}
                      {api2subscription.payer.zipCode}{' '}
                      {api2subscription.payer.postName}
                    </p>
                    {api2subscription.payer.emailAddress && (
                      <p>
                        <b>Sähköpostiosoite:</b>
                        <br />
                        {api2subscription.payer.emailAddress}
                      </p>
                    )}
                    {api2subscription.payer.customerId && (
                      <p>
                        <b>Asiakasnumero:</b>
                        <br />
                        {api2subscription.payer.customerId}
                      </p>
                    )}
                  </>
                )}
                <Grid.Column width={16}>
                  <Grid.Column width={16} className="grid-thank-you-column">
                    <h2>Saajan tiedot</h2>
                  </Grid.Column>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <p>
                        <b>Nimi:</b>
                        <br />
                        {api2subscription.subscriber.firstName}{' '}
                        {api2subscription.subscriber.lastName}
                      </p>
                      <p>
                        <b>Osoite:</b>
                        <br />
                        {api2subscription.subscriber.street1}{' '}
                        {api2subscription.subscriber.zipCode}{' '}
                        {api2subscription.subscriber.postName}
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid.Column>
              </Grid.Column>
            ) : (
              <Grid.Column width={16} style={{ paddingLeft: 0 }}>
                <Grid.Column width={16} className="grid-thank-you-column">
                  <h2 style={{ textAlign: 'left' }}>Tilaajan tiedot</h2>
                </Grid.Column>
                <Grid.Row>
                  <Grid.Column width={16}>
                    {api2subscription.subscriber.firstName && (
                      <p>
                        <b>Nimi:</b>
                        <br />
                        {api2subscription.subscriber.firstName}{' '}
                        {api2subscription.subscriber.lastName}
                      </p>
                    )}
                    {api2subscription.subscriber.companyId && (
                      <p>
                        <b>Nimi:</b>
                        <br />
                        {api2subscription.subscriber.lastName}{' '}
                        {api2subscription.subscriber.companyId}
                      </p>
                    )}
                    <p>
                      <b>Osoite:</b>
                      <br />
                      {api2subscription.subscriber.street1}{' '}
                      {api2subscription.subscriber.zipCode}{' '}
                      {api2subscription.subscriber.postName}
                    </p>
                    {api2subscription.subscriber.emailAddress && (
                      <p>
                        <b>Sähköpostiosoite:</b>
                        <br />
                        {api2subscription.subscriber.emailAddress}
                      </p>
                    )}
                    {api2subscription.subscriber.customerId && (
                      <p>
                        <b>Asiakasnumero:</b>
                        <br />
                        {api2subscription.subscriber.customerId}
                      </p>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>

        {orderSuccessText.__html !== '' && (
          <Grid>
            <Grid.Column
              floated="left"
              className="p-0"
              mobile={16}
              tablet={16}
              computer={16}
            >
              <div
                className="text-align-left"
                dangerouslySetInnerHTML={orderSuccessText}
              />
            </Grid.Column>
          </Grid>
        )}

        {magazine.showOrderMoreLink === '1' && (
          // previously Grid.Row
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="clickable-link" onClick={toggleOrderMoreRedirect}>
                Tilaa lisää tästä »
              </div>
              {redirectBackToOffer && order.offerId && (
                <Navigate to={`/${order.offerId}`} />
              )}
            </Grid.Column>
          </Grid>
        )}
        {magazine.showMoreLink === '1' && (
          // previously Grid.Row
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <a href={magazine.moreLinksUrl}>{magazine.showMoreLinkText}</a>
            </Grid.Column>
          </Grid>
        )}
        {digiOnlyRedirectUrl && (
          <Grid style={{ width: '100%' }}>
            <Grid.Column width={16}>
              <p>
                Pääset lukemaan tilaamaasi digiä{' '}
                <a
                  style={{ color: 'blue' }}
                  href={digiOnlyRedirectUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  tästä
                </a>
              </p>
            </Grid.Column>
          </Grid>
        )}
      </div>
    </Grid>
  );
};

export default ThankYouContent;
