import React, { useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import Context from '../../context';

import Footer from '../main/Footer';
import HeaderBar from '../main/HeaderBar';
import LandingPageContent from '../main/LandingPageContent';

import * as offerActions from '../../actions/offer';
import * as pollActions from '../../actions/poll';
import * as orderActions from '../../actions/order';
import * as ga from '../../utils/googleAnalytics';
import {
  shouldShowHeaderAndFooter,
  parseConsentGiven,
} from '../../utils/util';
import useSessionStorage, {
  SESSION_STORAGE_KEYS,
} from '../../hooks/session_storage';

const LandingPage = () => {
  useEffect(() => {
    ga.googleAnalyticsPageView();
  }, []);
  const queryParams = useParams();
  const { clearSession, setSessionItem } = useSessionStorage();

  const location = useLocation();
  const context = useContext(Context);
  const { offer, offerDispatch, customer, customerDispatch, orderDispatch }
    = context;
  useEffect(() => {
    clearSession();
    /*
     * Parse url params
     */
    const host = window.location.hostname;
    // offeringId from react Route (:offeringId). This can also be 'lukulahja'.
    const offeringId = queryParams.offeringId.split('&')[0];
    // tyrkyte is always first param with no key (just plain value) if given
    let tyrkyteId = location.search.replace('?', '').split('&')[0];
    // Tyrkyte must be a positive number
    if (tyrkyteId === '' || !tyrkyteId.match(/^\d+$/)) {
      tyrkyteId = undefined;
    }
    const urlParams = new URL(window.location.href).searchParams;
    const isPreview = urlParams.has('preview');
    const emailMarketingOption
      = urlParams.has('mailoption') && urlParams.get('mailoption') === '1';
    const brand = urlParams.has('brandi') ? urlParams.get('brandi') : undefined;
    const receiveType = urlParams.has('vayla')
      ? urlParams.get('vayla')
      : undefined;
    const referrerSid = urlParams.has('sid') ? urlParams.get('sid') : undefined;
    const referrerUrl = document.referrer;
    const sourceUrl = `${window.location.protocol}//${window.location.host+window.location.pathname}`;

    const consentGiven = parseConsentGiven();
    if (consentGiven && referrerSid) {
      setSessionItem(SESSION_STORAGE_KEYS.REFERRER_SID, referrerSid);
      setSessionItem(SESSION_STORAGE_KEYS.SOURCE_URL, sourceUrl);
    }

    if (consentGiven && referrerUrl) {
      setSessionItem(SESSION_STORAGE_KEYS.REFERRER_URL, referrerUrl);
    }

    const params = {
      host,
      offeringId,
      tyrkyteId,
      isPreview,
      brand,
      receiveType,
      emailMarketingOption,
    };

    /*
     * Fetch actual offer data.
     */
    if (tyrkyteId) {
      offerActions.getOfferWithTyrkyte(
        { ...params, isLukulahja: false },
        offerDispatch,
        customer,
        customerDispatch,
      );
    } else {
      offerActions.getOffer(params, customer, offerDispatch);
    }

  }, []);

  const onTyrkyteSubmit = async (tyrkyteId, isLukulahja) => {
    await offerActions.getOfferWithTyrkyte(
      { ...offer.params, tyrkyteId, isLukulahja },
      offerDispatch,
      customer,
      customerDispatch,
    );
  };

  const onPollSubmit = async (data) => {
    await pollActions.sendPollDetails(data, offerDispatch);
  };

  const onSubmit = async (data) => {
    orderActions.initOrder(data, orderDispatch, offerDispatch);
  };

  return (
    <>
      <div className="layout-container">
        <div id="landingPage" style={{ minWidth: '100%' }}>
          {shouldShowHeaderAndFooter(offer.state) && <HeaderBar />}

          <Grid
            stackable
            centered
            className="no-margin no-padding ie-flexbox-fix"
          >
            <p hidden>{offer.offeringId}</p>

            <LandingPageContent
              onSubmit={onSubmit}
              onTyrkyteSubmit={onTyrkyteSubmit}
              onPollSubmit={onPollSubmit}
            />
          </Grid>
        </div>
      </div>
      {shouldShowHeaderAndFooter(offer.state) && (
        <div className="footer-section">
          <Footer />
        </div>
      )}
    </>
  );
};

export default LandingPage;
