import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';
import { salesTypeStringBySubscriptionKind } from '../../utils/util';
import Context from '../../context';
import DurationAndPriceByOffer from './DurationAndPriceByOffer';
import DurationAndPriceBySubscription from './DurationAndPriceBySubscription';

const SubscriptionAndCustomerInfo = () => {
  const context = useContext(Context);
  const { invoicingChange } = context;
  const subscription = invoicingChange?.subscription;
  const offerDetails = invoicingChange?.offerDetails;
  const benefits = subscription?.benefits;
  // Filter out free postal fees from gifts
  const filteredBenefitProductCodes = ['PE', 'PU', 'PR'];
  const filteredBenefits = benefits
    ? benefits.filter(
      (b) => !filteredBenefitProductCodes.includes(b.productCode),
    )
    : [];
  const startDate = subscription.invoicingStartDate
    ? new Date(subscription.invoicingStartDate)
    : null;
  const startDateString = startDate
    ? `${startDate.getDate()}.${
      startDate.getMonth() + 1
    }.${startDate.getFullYear()}`
    : '';

  return (
    <>
      <Grid.Column
        width={16}
        id="title"
        className="invoicingChange-subscriptionInfo"
        textAlign="center"
      >
        <b>TILAUKSEN TIEDOT</b>
      </Grid.Column>
      <Grid.Column
        width={16}
        id="offerDetails"
        className="invoicingChange-subscriptionInfo"
        textAlign="center"
      >
        <b>Tarjousjakso:</b>
        <br />
        {subscription.packageName}{' '}
        <DurationAndPriceBySubscription subscription={subscription} />
        <br />
        {offerDetails && (
          <>
            {' '}
            (norm.{' '}
            <DurationAndPriceByOffer
              duration={offerDetails.duration}
              durationType={offerDetails.orderDurationType}
              price={offerDetails.normalPrice}
            />
            ){' '}
          </>
        )}
        <b>
          {offerDetails ? (
            <>
              <br />
              {offerDetails.salesType}
            </>
          ) : (
            salesTypeStringBySubscriptionKind(subscription.kind)
          )}
        </b>
      </Grid.Column>

      {filteredBenefits.length > 0 && (
        <Grid.Column
          width={16}
          id="giftInfo"
          className="invoicingChange-subscriptionInfo"
          textAlign="center"
        >
          <b>Tilaajalahja:</b>
          <br />
          {filteredBenefits.map((b) => (
            <span key={b.productName}>
              {b.productName}
              <br />
            </span>
          ))}
        </Grid.Column>
      )}

      <Grid.Column
        width={16}
        id="duration"
        className="invoicingChange-subscriptionInfo"
        textAlign="center"
      >
        <b>Alkamisaika: </b>
        {startDateString}
      </Grid.Column>
    </>
  );
};

export default SubscriptionAndCustomerInfo;
