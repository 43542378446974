import React from 'react';
import { Button, Form } from 'semantic-ui-react';

import LoadingSpinner from '../main/LoadingSpinner';

const TallennusPageLoginForm = (props) => {
  const { handleLoginSubmit, resetNotification, isLoggingIn } = props;

  return (
    <div
      style={{
        backgroundColor: 'white',
        marginBottom: 40,
        padding: '5px 30px 30px 30px',
      }}
    >
      <Form onSubmit={handleLoginSubmit} className="LoginForm">
        <h1>Kirjaudu sisään</h1>
        <input
          type="text"
          onChange={resetNotification}
          label="Käyttäjänimi"
          placeholder="Käyttäjänimi"
          name="username"
        />
        <input
          type="password"
          onChange={resetNotification}
          label="Salasana"
          placeholder="Salasana"
          name="password"
        />
        {isLoggingIn ? (
          <LoadingSpinner />
        ) : (
          <Button type="submit" fluid color="blue">
            Kirjaudu
          </Button>
        )}
      </Form>
    </div>
  );
};

export default TallennusPageLoginForm;
