/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Sticky } from 'semantic-ui-react';

import scrollToElement from '../../utils/scrollUtils';

const OrderFormStepBar = (props) => {
  const { elements, observedElements } = props;
  const ball = (isActive, step) => (
    <div
      key={step.name}
      className="ball-container"
      onClick={() => scrollToElement(step.name)}
      id={`ball-${step.name}`}
    >
      <span className={`ball ${isActive ? 'active' : 'inactive'}`} />
      <span className="ball-title">{step.description}</span>
    </div>
  );

  const current = elements.indexOf(
    elements.find(
      (e) => e.name === observedElements[observedElements.length - 1],
    ),
  );

  return (
    <Sticky>
      <div className="progress-bar-container">
        <hr className="ball-line" />
        {elements.map((step, index) => ball(index === current, step))}
      </div>
    </Sticky>
  );
};

export default OrderFormStepBar;
