import {
  BACKEND_IN_MAINTENANCE_MODE,
  CARD_RENEW_ADD_CARD_FAILURE,
  CARD_RENEW_INIT,
  CARD_RENEW_INIT_FAILURE,
  CARD_RENEW_READY,
  CARD_RENEW_UPDATE_CARD,
  CARD_RENEW_UPDATE_CARD_FAILURE,
  CARD_RENEW_UPDATE_CARD_SUCCESS,
} from '../actions/actionTypes';

export const initialState = {
  state: CARD_RENEW_INIT,
  params: null,
};

// eslint-disable-next-line default-param-last
export const cardRenewReducer = (state = initialState, action) => {
  switch (action.type) {
    case CARD_RENEW_INIT: {
      return state;
    }
    case CARD_RENEW_INIT_FAILURE: {
      return {
        ...state,
        state: CARD_RENEW_INIT_FAILURE,
      };
    }
    case CARD_RENEW_READY: {
      return {
        ...state,
        state: CARD_RENEW_READY,
        params: action.payload.params,
      };
    }
    case CARD_RENEW_ADD_CARD_FAILURE: {
      return {
        ...state,
        state: CARD_RENEW_ADD_CARD_FAILURE,
      };
    }
    case CARD_RENEW_UPDATE_CARD: {
      return {
        ...state,
        state: CARD_RENEW_UPDATE_CARD,
        params: action.payload.params,
      };
    }
    case CARD_RENEW_UPDATE_CARD_FAILURE: {
      return {
        ...state,
        state: CARD_RENEW_UPDATE_CARD_FAILURE,
      };
    }
    case CARD_RENEW_UPDATE_CARD_SUCCESS: {
      return {
        ...state,
        state: CARD_RENEW_UPDATE_CARD_SUCCESS,
      };
    }
    // OTVMSUP-9, dispatch this when backend returns 503
    case BACKEND_IN_MAINTENANCE_MODE: {
      return {
        ...state,
        state: BACKEND_IN_MAINTENANCE_MODE,
      };
    }
    default: {
      return state;
    }
  }
};
