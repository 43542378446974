import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import 'reactjs-popup/dist/index.css';

import PopupContent from './PopupContent';

import { POPUP_TYPE } from '../../../utils/util';

const PopupTrigger = (props) => {
  const { type, magazine, gift } = props;
  const [isOpen, setIsOpen] = useState(false);

  const magazineCoverShadowClass = () => {
    const magazineCoverUrl = 'kannet.kuvalehdet.fi';
    if (magazine?.packageImage.includes(magazineCoverUrl)) {
      return 'with-shadow';
    }
  };

  const openModal = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  switch (type) {
    case POPUP_TYPE.TERMS:
      return (
        <span className="terms-link">
          <a onClick={openModal}>tilausehdot »</a>
          {isOpen
            ? ReactDOM.createPortal(
              <PopupContent
                isOpen={isOpen}
                closeModal={closeModal}
                type={type}
                gift={gift}
                magazine={magazine}
              />,
              document.getElementById('app'),
            )
            : null}
        </span>
      );
    case POPUP_TYPE.MAGAZINE_IMAGE:
      return (
        <>
          {magazine.packageDescription
          && (magazine.packageDescription !== ''
            || magazine.packageDescription.__html === '') ? (
              <div className="readmore">
                <a onClick={openModal}>
                  <img
                    src={magazine.packageImage}
                    className={`large-image ${magazineCoverShadowClass()}`}
                  />
                </a>
                {isOpen
                  ? ReactDOM.createPortal(
                    <PopupContent
                      isOpen={isOpen}
                      closeModal={closeModal}
                      type={type}
                      magazine={magazine}
                    />,
                    document.getElementById('app'),
                  )
                  : null}
              </div>
            ) : (
              <img
                src={magazine.packageImage}
                className={`large-image ${magazineCoverShadowClass()}`}
              />
            )}
        </>
      );
    case POPUP_TYPE.GIFT_IMAGE:
      return (
        <>
          {gift.giftDescription
          && (gift.giftDescription !== ''
            || gift.giftDescription.__html === '') ? (
              <div className="readmore">
                <a onClick={openModal}>
                  <img src={gift.giftImage} className="large-image" />
                </a>
                {isOpen
                  ? ReactDOM.createPortal(
                    <PopupContent
                      isOpen={isOpen}
                      closeModal={closeModal}
                      type={type}
                      gift={gift}
                    />,
                    document.getElementById('app'),
                  )
                  : null}
              </div>
            ) : (
              <img src={gift.giftImage} className="large-image" />
            )}
        </>
      );
    default:
      return (
        <button type="button" onClick={openModal} className="popup-button">
          <p className="font-12">Lue lisää</p>
          {isOpen
            ? ReactDOM.createPortal(
              <PopupContent
                isOpen={isOpen}
                closeModal={closeModal}
                type={type}
                gift={gift}
                magazine={magazine}
              />,
              document.getElementById('app'),
            )
            : null}
        </button>
      );
  }
};

export default PopupTrigger;
