import React, { useContext } from 'react';
import { Button, Grid } from 'semantic-ui-react';

import Context from '../../../context';
import ArrowIcon from '../../util/ArrowIcon';

import * as invoicingChangeActions from '../../../actions/invoicingChange';

const InvoicingSelection = (props) => {
  const { card, eInvoicing, mobilePay } = props;
  const context = useContext(Context);
  const { invoicingChangeDispatch } = context;

  const infoText = 'Vaihda maksutapaa:';

  const invoicingButton = (label, onClick) => (
    <Grid.Column width={16} style={{ padding: 7 }}>
      <Button
        className="invoicingChange-button"
        type="button"
        id={label}
        fluid
        color="blue"
        onClick={() => onClick()}
      >
        <ArrowIcon />
        {label}
      </Button>
    </Grid.Column>
  );

  return (
    <Grid.Row className="invoicingSelection" style={{ paddingBottom: '2rem' }}>
      <Grid.Column
        width={16}
        className="invoicingChange-infoText-noBg"
        textAlign="center"
      >
        <b>{infoText}</b>
      </Grid.Column>
      {card
        && invoicingButton('MAKSUKORTTI', () =>
          invoicingChangeActions.initCardInvoicing(invoicingChangeDispatch),
        )}
      {eInvoicing
        && invoicingButton('E-LASKU', () =>
          invoicingChangeActions.initEInvoicing(invoicingChangeDispatch),
        )}
      {mobilePay
        && invoicingButton('MOBILEPAY', () =>
          invoicingChangeActions.initMobilePayInvoicing(
            invoicingChangeDispatch,
          ),
        )}
    </Grid.Row>
  );
};

export default InvoicingSelection;
