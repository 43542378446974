/*
 * Offer fetch action types
 */
export const OFFER_INITIAL_STATE = 'OFFER_INITIAL_STATE';
export const OFFER_SET_PARAMS = 'OFFER_SET_PARAMS';
export const OFFER_FETCH = 'OFFER_FETCH';
export const OFFER_FETCH_FAILURE = 'OFFER_FETCH_FAILURE';
export const OFFER_ASK_LUKULAHJA = 'OFFER_ASK_LUKULAHJA';
export const OFFER_ASK_TYRKYTE = 'OFFER_ASK_TYRKYTE';
export const OFFER_FETCH_TYRKYTE = 'OFFER_FETCH_TYRKYTE';
export const OFFER_FETCH_TYRKYTE_FAILURE = 'OFFER_FETCH_TYRKYTE_FAILURE';
export const OFFER_FETCH_LUKULAHJA_FAILURE = 'OFFER_FETCH_LUKULAHJA_FAILURE';
export const OFFER_SET_CUSTOMER = 'OFFER_SET_CUSTOMER';
// Poll form states after tyrkyte is entered
export const OFFER_SHOW_POLL = 'OFFER_SHOW_POLL';
export const OFFER_SEND_POLL = 'OFFER_SEND_POLL';
export const OFFER_SEND_POLL_FAILURE = 'OFFER_SEND_POLL_FAILURE';
// Offer fetch complete without errors, show OrderForm (used by every path)
export const OFFER_FETCH_COMPLETE = 'OFFER_FETCH_COMPLETE';

/*
 * Order initialisation.
 * Used by LandingPage and TallennusPage after posting order info to backend.
 */
export const ORDER_INIT = 'ORDER_INIT';
export const ORDER_INIT_SUCCESS = 'ORDER_INIT_SUCCESS';
export const ORDER_INIT_FAILURE = 'ORDER_INIT_FAILURE';

/*
 * Order pipeline action types
 */
export const ORDER_HANDLING_START = 'ORDER_HANDLING_START';
export const ORDER_ADD_CARD = 'ORDER_ADD_CARD';
export const ORDER_ADD_CARD_FAILURE = 'ORDER_ADD_CARD_FAILURE';
export const ORDER_E_IDENT_FAILURE = 'ORDER_E_IDENT_FAILURE';
export const ORDER_POLL_MOBILEPAY_STATUS = 'ORDER_POLL_MOBILEPAY_STATUS';
export const ORDER_POLL_MOBILEPAY_STATUS_FAILED
  = 'ORDER_POLL_MOBILEPAY_STATUS_FAILED';
export const ORDER_CANCEL_INIT = 'ORDER_CANCEL_INIT';
export const ORDER_CANCEL_SUCCESS = 'ORDER_CANCEL_SUCCESS';
export const ORDER_CANCEL_FAILURE = 'ORDER_CANCEL_FAILURE';
export const ORDER_SUBSCRIBE = 'ORDER_SUBSCRIBE';
export const ORDER_SUBSCRIBE_FAILURE = 'ORDER_SUBSCRIBE_FAILED';
export const ORDER_SET_CUSTOMER = 'ORDER_SET_CUSTOMER';
export const ORDER_COMPLETE = 'ORDER_COMPLETE';
export const ORDER_INVOICE_CALL = 'ORDER_INVOICE_CALL';

/*
 * Card renewal action types
 */
export const CARD_RENEW_INIT = 'CARD_RENEW_INIT';
export const CARD_RENEW_INIT_FAILURE = 'CARD_RENEW_INIT_FAILURE';
export const CARD_RENEW_READY = 'CARD_RENEW_READY';
export const CARD_RENEW_ADD_CARD_FAILURE = 'CARD_RENEW_ADD_CARD_FAILURE';
export const CARD_RENEW_UPDATE_CARD = 'CARD_RENEW_UPDATE_CARD';
export const CARD_RENEW_UPDATE_CARD_FAILURE = 'CARD_RENEW_UPDATE_CARD_FAILURE';
export const CARD_RENEW_UPDATE_CARD_SUCCESS = 'CARD_RENEW_UPDATE_CARD_SUCCESS';

/*
 * Invoicing change action types
 */
export const INVOICING_CHANGE_INIT = 'INVOICING_CHANGE_INIT';
export const INVOICING_CHANGE_INIT_FAILURE = 'INVOICING_CHANGE_INIT_FAILURE';
export const INVOICING_CHANGE_ORDER_FETCH = 'INVOICING_CHANGE_ORDER_FETCH';
export const INVOICING_CHANGE_TYRKYTE_EXPIRED
  = 'INVOICING_CHANGE_TYRKYTE_EXPIRED';
export const INVOICING_CHANGE_TYRKYTE_USED = 'INVOICING_CHANGE_TYRKYTE_USED';
export const INVOICING_CHANGE_ORDER_FETCH_FAILURE
  = 'INVOICING_CHANGE_ORDER_FETCH_FAILURE';
export const INVOICING_CHANGE_ORDER_FETCH_SUCCESSFUL
  = 'INVOICING_CHANGE_ORDER_FETCH_SUCCESSFUL';
// E-invoicing
export const INVOICING_CHANGE_E_INVOICING_CHOOSE_BANK
  = 'INVOICING_CHANGE_E_INVOICING_CHOOSE_BANK';
export const INVOICING_CHANGE_E_INVOICING_SSN_FORM
  = 'INVOICING_CHANGE_E_INVOICING_SSN_FORM';
export const INVOICING_CHANGE_E_INVOICING_SEND
  = 'INVOICING_CHANGE_E_INVOICING_SEND';
export const INVOICING_CHANGE_E_INVOICING_FAILURE
  = 'INVOICING_CHANGE_E_INVOICING_FAILURE';
export const INVOICING_CHANGE_E_INVOICING_SUCCESS
  = 'INVOICING_CHANGE_E_INVOICING_SUCCESS';
// Card payment
export const INVOICING_CHANGE_CARD_EMAIL_FORM
  = 'INVOICING_CHANGE_CARD_EMAIL_FORM';
export const INVOICING_CHANGE_CARD_ONLY_FORM
  = 'INVOICING_CHANGE_CARD_ONLY_FORM';
export const INVOICING_CHANGE_CARD_ADD_INIT = 'INVOICING_CHANGE_CARD_ADD_INIT';
export const INVOICING_CHANGE_CARD_ADD_FAILURE
  = 'INVOICING_CHANGE_CARD_INVOICING_FAILURE';
export const INVOICING_CHANGE_CARD_ADD_REDIRECT
  = 'INVOICING_CHANGE_CARD_ADD_REDIRECT';
export const INVOICING_CHANGE_CHARGE_CARD = 'INVOICING_CHANGE_CHARGE_CARD';
export const INVOICING_CHANGE_CHARGE_CARD_FAILURE
  = 'INVOICING_CHANGE_CHARGE_CARD_FAILURE';
export const INVOICING_CHANGE_CHARGE_CARD_SUCCESS
  = 'INVOICING_CHANGE_CHARGE_CARD_SUCCESS';
// MobilePay (coming soon)
export const INVOICING_CHANGE_MOBILEPAY_INVOICING_INIT
  = 'INVOICING_CHANGE_MOBILEPAY_INVOICING_INIT';

/*
 * Confirm subscription types
 */
export const CONFIRM_SUBSCRIPTION_INIT = 'CONFIRM_SUBSCRIPTION_INIT';
export const CONFIRM_SUBSCRIPTION_INIT_FAILURE
  = 'CONFIRM_SUBSCRIPTION_INIT_FAILURE';
export const CONFIRM_SUBSCRIPTION_FETCH = 'CONFIRM_SUBSCRIPTION_FETCH';
export const CONFIRM_SUBSCRIPTION_FETCH_FAILURE
  = 'CONFIRM_SUBSCRIPTION_FETCH_FAILURE';
export const CONFIRM_SUBSCRIPTION_FETCH_SUCCESSFUL
  = 'CONFIRM_SUBSCRIPTION_FETCH_SUCCESSFUL';
export const CONFIRM_SUBSCRIPTION_CONFIRMING
  = 'CONFIRM_SUBSCRIPTION_CONFIRMING';
export const CONFIRM_SUBSCRIPTION_CONFIRM_FAILED
  = 'CONFIRM_SUBSCRIPTION_CONFIRM_FAILED';
export const CONFIRM_SUBSCRIPTION_CONFIRM_SUCCESSFUL
  = 'CONFIRM_SUBSCRIPTION_CONFIRM_SUCCESSFUL';
export const CONFIRM_SUBSCRIPTION_CONFLICT_ALREADY_CONFIRMED
  = 'CONFIRM_SUBSCRIPTION_CONFLICT_ALREADY_CONFIRMED';
export const CONFIRM_SUBSCRIPTION_CONFLICT_INVALID_STATUS
  = 'CONFIRM_SUBSCRIPTION_CONFLICT_INVALID_STATUS';
export const CONFIRM_SUBSCRIPTION_NOT_FOUND = 'CONFIRM_SUBSCRIPTION_NOT_FOUND';

/*
 * Other action types
 */
export const EMPTY_CUSTOMER = 'EMPTY_CUSTOMER';
export const BACKEND_IN_MAINTENANCE_MODE = 'BACKEND_IN_MAINTENANCE_MODE';

export const HIDE_HEADER_AND_FOOTER_TYPES = {
  OFFER_INITIAL_STATE,
  OFFER_SET_PARAMS,
  OFFER_FETCH,
  OFFER_FETCH_TYRKYTE,
  OFFER_SEND_POLL,
  ORDER_INIT_SUCCESS,
  ORDER_HANDLING_START,
  ORDER_ADD_CARD,
  ORDER_ADD_CARD_FAILURE,
  ORDER_COMPLETE,
  ORDER_SUBSCRIBE,
};
