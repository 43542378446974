import React, { useContext, useState } from 'react';
import { Button, Grid, Image } from 'semantic-ui-react';

import Context from '../../context';
import ArrowIcon from '../util/ArrowIcon';

import * as invoicingChangeActions from '../../actions/invoicingChange';

const BankForm = () => {
  const context = useContext(Context);
  const {
    invoicingChange: { options },
    invoicingChangeDispatch,
  } = context;
  const [selectedBank, setSelectedBank] = useState(undefined);

  const banks = [
    { name: 'Aktia', bic: 'HELSFIHH', src: '/images/banks/aktia.svg' },
    {
      name: 'Ålandsbanken',
      bic: 'AABAFI22',
      src: '/images/banks/alandsbanken.svg',
    },
    {
      name: 'Danske Bank',
      bic: 'DABAFIHH',
      src: '/images/banks/danske-bank.svg',
    },
    {
      name: 'Handelsbanken',
      bic: 'HANDFIHH',
      src: '/images/banks/handelsbanken.svg',
    },
    { name: 'Nordea', bic: 'NDEAFIHH', src: '/images/banks/nordea.svg' },
    {
      name: 'OmaSp',
      bic: 'ITELFIHH',
      src: '/images/banks/oma-saastopankki.svg',
    },
    {
      name: 'Osuuspankki',
      bic: 'OKOYFIHH',
      src: '/images/banks/osuuspankki.svg',
    },
    {
      name: 'POP Pankki',
      bic: 'POPFFI22',
      src: '/images/banks/pop-pankki.svg',
    },
    { name: 'S-Pankki', bic: 'SBANFIHH', src: '/images/banks/s-pankki.svg' },
    {
      name: 'Säästöpankki',
      bic: 'ITELFIHH',
      src: '/images/banks/saastopankki.svg',
    },
  ];

  const infoText = options?.invoicingModeChange?.eInvoice?.infoText
    ? options.invoicingModeChange.eInvoice.infoText
    : 'options.invoicingModeChange.eInvoice.infoText';
  const infoText2 = 'Valitse pankki, johon haluat tilata e-laskun:';

  const bankButton = (bank) => {
    const isSelected = selectedBank && bank.name === selectedBank.name;
    return (
      <Grid.Column
        key={bank.name}
        mobile={6}
        tablet={4}
        computer={4}
        onClick={() => setSelectedBank(bank)}
        textAlign="center"
        verticalAlign="middle"
        className={
          isSelected ? 'invoicingChange-bank-selected' : 'invoicingChange-bank'
        }
      >
        <Image
          key={bank.name}
          src={bank.src}
          style={{ height: 100 }}
          centered
        />
      </Grid.Column>
    );
  };

  return (
    <>
      <Grid.Row id="bankForm">
        <Grid.Column
          width={16}
          className="invoicingChange-infoText"
          textAlign="center"
        >
          <span dangerouslySetInnerHTML={{ __html: infoText }} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column
          width={16}
          className="invoicingChange-infoText-noBg"
          textAlign="center"
        >
          <b>
            <p>{infoText2}</p>
          </b>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row id="bankSelect" className="invoicingChange-bank-select">
        {banks.map((bank) => bankButton(bank))}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column
          width={16}
          textAlign="center"
          style={{ paddingBottom: '2rem' }}
        >
          <Button
            className="invoicingChange-button"
            color="blue"
            onClick={() =>
              invoicingChangeActions.setBank(
                selectedBank,
                invoicingChangeDispatch,
              )
            }
            disabled={!selectedBank}
          >
            <ArrowIcon />
            VALITSE PANKKI
          </Button>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

export default BankForm;
