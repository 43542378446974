import React from 'react';
import { Icon } from 'semantic-ui-react';

const MoveBackButton = (props) => {
  const { moveBack } = props;

  return (
    <button
      type="button"
      onClick={moveBack}
      className="invoicingChange-moveBackButton"
    >
      <Icon name="angle left" />
      <b>Takaisin</b>
    </button>
  );
};

export default MoveBackButton;
