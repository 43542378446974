import React, { useLayoutEffect, useContext, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Form, Button, Grid } from 'semantic-ui-react';

import Context from '../../context';
import InputWrapper from '../util/InputWrapper';
import PackageBanner from '../main/PackageBanner';

import * as offerActions from '../../actions/offer';

const errorMessage = (offer, skipTyrkyte) => {
  if (offer.errorText) {
    // Got magazines?
    if (offer.magazines && offer.magazines[0]) {
      // Disposable offer? Do not allow skip
      if (offer.magazines[0].disposableCode === '1') {
        return (
          <p
            className="errorText-longer"
            dangerouslySetInnerHTML={{ __html: offer.errorText }}
          />
        );
      }
      // Allow skip if magazines are found
      return (
        <p className="errorText-longer">
          Jotain meni pieleen. Tarkista koodi ja yritä uudelleen. Pääset myös
          tekemään tilauksesi{' '}
          <span
            className="error-link"
            id="disableTyrkyte"
            onClick={skipTyrkyte}
          >
            Tästä.
          </span>
        </p>
      );
      // No magazines
    }
    return (
      <p
        className="errorText-no-magazines"
        dangerouslySetInnerHTML={{ __html: offer.errorText }}
      />
    );
  }
  return null;
};

const TyrkyteForm = (props) => {
  const { onTyrkyteSubmit } = props;
  const context = useContext(Context);
  const { offer, offerDispatch } = context;
  const formMethods = useForm({
    defaultValues: {
      tyrkyteId: '',
    },
  });
  const [isValid, setIsValid] = useState(false);

  const validate = () => {
    if (!formMethods.getValues('tyrkyteId') && isValid) setIsValid(false);
    else setIsValid(true);
  };
  const isLukulahja = false;

  function splitCouponCodeInfoHeader(offerHeaderAndText) {
    // Ugly function to parse and check if tags are uppercased or lowercase
    // Parse offerHeaderAndText --> offerHeader
    // Values are from Campaign
    if (offerHeaderAndText) {
      if (offerHeaderAndText.match(/<H1>([^<]*?)<\/H1>/g)) {
        const offerHeaderTagged = offerHeaderAndText
          .match(/<H1>([^<]*?)<\/H1>/g)
          .toString();
        const offerHeader = offerHeaderTagged.replace(/<[^>]+>/g, '');
        if (offerHeader.includes('&nbsp;')) {
          return offerHeader.replace(/&nbsp;/g, ' ');
        }
        return offerHeader;
      }
      if (offerHeaderAndText.match(/<h1>([^<]*?)<\/h1>/g)) {
        const offerHeaderTagged = offerHeaderAndText
          .match(/<h1>([^<]*?)<\/h1>/g)
          .toString();
        const offerHeader = offerHeaderTagged.replace(/<[^>]+>/g, '');
        if (offerHeader.includes('&nbsp;')) {
          return offerHeader.replace(/&nbsp;/g, ' ');
        }
        return offerHeader;
      }
      const offerHeaderTagged = offerHeaderAndText.toString();
      const offerHeader = offerHeaderTagged.replace(/<[^>]+>/g, '');
      if (offerHeader.includes('&nbsp;')) {
        return offerHeader.replace(/&nbsp;/g, ' ');
      }
      return offerHeader;
    }
    return null;
  }

  function splitCouponCodeInfoText(offerHeaderAndText) {
    // Ugly function to parse and check if tags are uppercased or lowercase
    // Parse offerHeaderAndText --> offerText
    // Values are from Campaign
    if (offerHeaderAndText) {
      if (offerHeaderAndText.match(/<P>([^<]*?)<\/P>/g)) {
        const offerTextTagged = offerHeaderAndText
          .match(/<P>([^<]*?)<\/P>/g)
          .toString();
        const offerText = offerTextTagged.replace(/<[^>]+>/g, '');
        if (offerText.includes('&nbsp;')) {
          return offerText.replace(/&nbsp;/g, ' ');
        }
        return offerText;
      }
      if (offerHeaderAndText.match(/<p>([^<]*?)<\/p>/g)) {
        const offerTextTagged = offerHeaderAndText
          .match(/<p>([^<]*?)<\/p>/g)
          .toString();
        const offerText = offerTextTagged.replace(/<[^>]+>/g, '');
        if (offerText.includes('&nbsp;')) {
          return offerText.replace(/&nbsp;/g, ' ');
        }
        return offerText;
      }
      const offerTextTagged = offerHeaderAndText.toString();
      const offerText = offerTextTagged.replace(/<[^>]+>/g, '');
      if (offerText.includes('&nbsp;')) {
        return offerText.replace(/&nbsp;/g, ' ');
      }
      return offerText;
    }
    return null;
  }

  const titleUnderlineHandler = () => {
    const titles = document.getElementsByTagName('h1');
    if (titles) {
      Array.from(titles).forEach((title) => {
        if (title.scrollHeight > 50) {
          title.classList.add('no-before');
        } else {
          title.classList.remove('no-before');
        }
      });
    }
  };

  /**
   * * OTVMSUP-171
   * * After DOM has rendered add event listener to resize.
   * * Removes (Adds 'no-before' class to element) underline from offering title if text overflows (e.g. text on 2 lines)
   */
  useLayoutEffect(() => {
    window.addEventListener('resize', titleUnderlineHandler);
    titleUnderlineHandler();
    return () => window.removeEventListener('resize', titleUnderlineHandler);
  }, []);

  return (
    <FormProvider {...formMethods}>
      <Grid className="wrapper-box-shadow stackable white-bg centered">
        {/*
              Checking for HEADER existence before function calls.
              Function splits header and text apart from a single field. */}
        {offer.magazines[0] && offer.magazines[0].couponCodeInfoText && (
          <Grid.Row style={{ background: 'white' }}>
            <PackageBanner offer={offer} isTyrkyteForm />
            {offer.magazines[0].couponCodeInfoText.__html && (
              <h1 className="mt-0">
                {splitCouponCodeInfoHeader(
                  offer.magazines[0].couponCodeInfoText.__html,
                )}
              </h1>
            )}
            {!offer.magazines[0].couponCodeInfoText.__html
              && offer.options?.couponCodeInfoText?.__html && (
              <h1 className="mt-0">
                {splitCouponCodeInfoHeader(
                  offer.options.couponCodeInfoText.__html,
                )}
              </h1>
            )}
            {offer.magazines[0].couponCodeInfoText.__html === ''
              && offer.options?.couponCodeInfoText?.__html === ''
              && console.log('Leipätekstiä ei ole asetettu.')}
          </Grid.Row>
        )}

        {offer.magazines[0] && offer.magazines[0].couponCodeInfoText && (
          <Grid.Row
            mobile={16}
            tablet={16}
            computer={16}
            className="grid-tyrkyte-form-text-row pt-0 pb-0"
          >
            {offer.magazines[0].couponCodeInfoText.__html && (
              <Grid.Column width={16}>
                <div style={{ textAlign: 'center' }}>
                  <p>
                    {splitCouponCodeInfoText(
                      offer.magazines[0].couponCodeInfoText.__html,
                    )}
                  </p>
                </div>
              </Grid.Column>
            )}
            {!offer.magazines[0].couponCodeInfoText.__html
              && offer.options?.couponCodeInfoText?.__html && (
              <Grid.Column width={16}>
                <div style={{ textAlign: 'center' }}>
                  <p>
                    {splitCouponCodeInfoText(
                      offer.options.couponCodeInfoText.__html,
                    )}
                  </p>
                </div>
              </Grid.Column>
            )}
            {/* If both offer.magazines[0] and offer.options couponCodeInfoText fields are empty -->
                Log in console for user to see. */}
            {offer.magazines[0].couponCodeInfoText.__html === ''
              && offer.options?.couponCodeInfoText?.__html === ''
              && console.log('Leipätekstiä ei ole asetettu.')}
          </Grid.Row>
        )}

        {offer.magazines && offer.magazines[0] && (
          <Grid.Row className="grid-tyrkyte-form-row">
            <Grid.Column mobile={8} tablet={8} computer={8}>
              <Form
                className="tyrkyte-form"
                onSubmit={formMethods.handleSubmit((values) => {
                  onTyrkyteSubmit(values.tyrkyteId, isLukulahja);
                })}
              >
                <Form.Field onChange={validate}>
                  <InputWrapper
                    name="tyrkyteId"
                    type="text"
                    label=""
                    style={{ width: '200px' }}
                  />
                </Form.Field>
                <Button
                  type="submit"
                  id="continue"
                  fluid
                  color="blue"
                  style={{ boxShadow: 'none' }}
                  disabled={!isValid}
                >
                  <img
                    alt="submit-arrow"
                    style={{
                      position: 'relative',
                      float: 'left',
                      marginLeft: -5,
                      marginTop: -2,
                      height: 17,
                      width: 17,
                      backgroundSize: 14,
                      content: '',
                      zIndex: 1,
                    }}
                    src="https://otavamedia.fi/wp-content/themes/om-otavamedia-theme/assets/img/icon-arrow.svg"
                  />
                  JATKA
                </Button>
              </Form>
            </Grid.Column>
          </Grid.Row>
        )}

        {offer.errorText && (
          <Grid.Row>
            {errorMessage(offer, () => offerActions.skipTyrkyte(offerDispatch))}
          </Grid.Row>
        )}
      </Grid>
    </FormProvider>
  );
};

export default TyrkyteForm;
