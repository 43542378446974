import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Dropdown, Form } from 'semantic-ui-react';

const DropdownSelection = (props) => {
  const { name, placeholder, options, onChangeCallback, onBlurCallback, open }
    = props;
  const { control } = useFormContext();
  const { field } = useController({ control, name });

  return (
    <Form.Field>
      <Dropdown
        open={open ? true : undefined}
        name={field.name}
        value={field.value}
        options={options}
        onBlur={onBlurCallback}
        onChange={
          onChangeCallback
            ? (event, data) => {
              field.onChange(data.value);
              onChangeCallback();
            }
            : (event, data) => field.onChange(data.value)
        }
        placeholder={placeholder}
        ref={field.ref}
        selection
        search
      />
    </Form.Field>
  );
};

export default DropdownSelection;
