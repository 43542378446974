import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

const SelectButton = (props) => {
  const { value, name, text, selectedText, id, singleItem } = props;
  const { control, getValues } = useFormContext();
  const { field } = useController({ control, name });
  field.value = value;
  const btnText = text || 'Valitse';
  const btnTextSelected = selectedText || 'Valittu';

  const checked = getValues()[name] === field.value;

  return (
    <button
      color="blue"
      style={singleItem ? { marginLeft: 0 } : {}}
      className={`select-button${checked ? ' selected' : ''}`}
      type="button"
      id={id || 'selectButton'}
      name={field.name}
      value={field.value}
      onClick={(event) => {
        field.onChange(event);
      }}
    >
      {checked ? btnTextSelected : btnText}
    </button>
  );
};

export default SelectButton;
