import React from 'react';
import { Form } from 'semantic-ui-react';
import { formatPrice } from '../../../utils/util';

const GiftSelectList = (props) => {
  const { gifts, optionalGifts } = props;

  return (
    <div className="elementList">
      {gifts.map((gift) => (
        <Form.Field key={gift.id}>
          <label>
            {optionalGifts === '1' && (
              <input type="radio" name="selectedGiftId" value={gift.id} />
            )}
            <span className="listElementTitle">
              <b>
                {gift.name}, {`Arvo ${formatPrice(gift.price)}`}
              </b>
            </span>
          </label>
        </Form.Field>
      ))}
    </div>
  );
};

export default GiftSelectList;
