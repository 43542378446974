import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';
import Slider from 'react-slick';

import Context from '../../../context';
import GiftSelectDropdown from './GiftSelectDropdown';
import PopupTrigger from './PopupTrigger';
import SelectButton from './SelectButton';

import {
  formatPrice,
  getBackgroundColor,
  Layout,
  POPUP_TYPE,
} from '../../../utils/util';
import SliderArrow from './SliderArrow';
import GiftImage from './GiftImage';

/**
 * Gift selection.
 * Used on normal orderForm and tallennusPageOrderForm.
 *
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
const GiftSelection = (props) => {
  const { giftLayout } = props;
  const context = useContext(Context);
  const { offer, customer } = context;

  const sliderSettings = {
    arrows: true,
    infinite: offer.gifts > 3,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <SliderArrow direction="right" />,
    prevArrow: <SliderArrow direction="left" />,
  };

  const { optionalGifts } = offer.magazines[0];
  let noGiftImages = true;
  offer.gifts.forEach((gift) => {
    if (gift.giftImage && gift.giftImage !== '') {
      noGiftImages = false;
    }
  });
  if (offer.gifts.length === '0') {
    return null;
  }

  // TallennusPageOrderForm uses this
  if (giftLayout === Layout.DROPDOWN) {
    return (
      <div className=".grid-text-content-centered-margined ">
        <h2>
          {offer.magazines[0].selectGiftText
            || (optionalGifts === '1' ? 'Valitse lahja' : 'Tarjouksen lahjat')}
        </h2>
        <GiftSelectDropdown />
      </div>
    );
  }

  const extraText3valid
    = offer.magazines[0].extraText3.__html.match(/<P>([^<]*?)<\/P>/g) !== null
    || offer.magazines[0].extraText3.__html.match(/<p>([^<]*?)<\/p>/g) !== null
    || offer.magazines[0].extraText3.__html;

  const backgroundColor = getBackgroundColor(null, offer);

  return (
    <Grid className="pt-20">
      <Grid.Row style={{ background: backgroundColor }} className="no-padding">
        <Grid.Column width={16} textAlign="center">
          <h2>
            {offer.magazines[0].selectGiftText
              || (optionalGifts === '1' ? 'Valitse lahja' : 'Tarjouksen lahjat')}
          </h2>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row style={{ background: backgroundColor }} className="no-padding">
        <Grid.Column width={16} className="no-padding">
          {offer.gifts.length === 1 && (
            <Grid
              stackable
              centered
              className="no-margin no-padding"
              id="giftChoice-singleItem"
              key={offer.gifts[0].id}
            >
              <Grid.Row>
                {offer.gifts[0].giftImage && (
                  <Grid.Column mobile={4} tablet={4} computer={4}>
                    <PopupTrigger
                      type={POPUP_TYPE.GIFT_IMAGE}
                      gift={offer.gifts[0]}
                    />
                  </Grid.Column>
                )}
                <Grid.Column mobile={4} tablet={4} computer={4}>
                  <p>{offer.gifts[0].name}</p>
                  {offer.gifts[0].price !== '0' && (
                    <p>{`Arvo ${formatPrice(offer.gifts[0].price)}`}</p>
                  )}
                  {offer.gifts[0].giftDescription && (
                    <PopupTrigger
                      type={POPUP_TYPE.GIFT}
                      gift={offer.gifts[0]}
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}

          {offer.gifts.length > 1 && (
            <div id="giftChoice-multipleItems" className="carousel-wrapper">
              <Slider
                {...sliderSettings}
                slidesToShow={offer.gifts.length > 3 ? 4 : offer.gifts.length}
              >
                {offer.gifts.map((gift) => (
                  <Grid centered className="gift-carousel-grid" key={gift.id}>
                    {!noGiftImages && (
                      <Grid.Row className="no-padding">
                        <Grid.Column
                          textAlign="center"
                          className="gift-image-on-top-column no-padding"
                        >
                          {offer.magazines[0].dontShowGifts2 !== '1'
                            && gift.giftImage && (
                            <GiftImage
                              gift={gift}
                              className="gift-image-on-top"
                            />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    )}
                    <Grid.Row className="no-padding gift-info-text-row">
                      <Grid.Column
                        textAlign="center"
                        className="gift-info-text-column"
                      >
                        {/* Lue lisää >> */}
                        {gift.giftDescription
                          && gift.giftDescription.__html !== '<P>&nbsp;</P>' && (
                          <PopupTrigger type={POPUP_TYPE.GIFT} gift={gift} />
                        )}
                        <div className="gift-info-text-container">
                          {/* Name */}
                          <p style={{ paddingTop: 15 }}>{gift.name}</p>
                          {/* Gift value */}
                          {gift.price !== '0' && (
                            <p className="font-12">{`Arvo ${formatPrice(
                              gift.price,
                            )}`}</p>
                          )}
                        </div>
                        {/* Select button */}
                        {optionalGifts === '1'
                          && (customer.tyrkyteId
                            || offer.requiresTyrkyte === '0'
                            || offer.disposableTyrkyte === '0'
                            || offer.params.isPreview) && (
                          <div className="gift-select-button">
                            <SelectButton
                              centered
                              type="radio"
                              name="selectedGiftId"
                              id={gift.name}
                              value={gift.id}
                            />
                          </div>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                ))}
              </Slider>
            </div>
          )}
        </Grid.Column>
      </Grid.Row>

      {extraText3valid ? (
        <Grid.Row>
          <Grid.Column width={16}>
            <div
              className="gift-choice-description"
              dangerouslySetInnerHTML={offer.magazines[0].extraText3}
            />
          </Grid.Column>
        </Grid.Row>
      ) : (
        // gift extra text or bottom padding
        <div style={{ height: '20px' }} />
      )}
    </Grid>
  );
};

export default GiftSelection;
