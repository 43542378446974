import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Form, Button, Grid, Image } from 'semantic-ui-react';

import Context from '../../context';
import CheckBoxField from './formSections/CheckBoxField';
import DropdownSelection from './formSections/DropdownSelection';

// let invalidForm = true;
const currentYear = new Date().getFullYear();

const pollQuestions = {
  checkBoxes: [
    { name: 'iKiinnostusalue1', label: 'Pukeutuminen ja muoti' },
    { name: 'iKiinnostusalue2', label: 'Terveys ja hyvinvointi' },
    { name: 'iKiinnostusalue3', label: 'Käsityöt' },
    { name: 'iKiinnostusalue4', label: 'Ruoka ja reseptiikka' },
    { name: 'iKiinnostusalue5', label: 'Kasvit ja puutarhanhoito' },
    { name: 'iKiinnostusalue6', label: 'Kirjat ja kirjallisuus' },
    { name: 'iKiinnostusalue7', label: 'Viihde- ja kodin elektroniikka' },
    { name: 'iKiinnostusalue8', label: 'Autot ja autotarvikkeet' },
    { name: 'iKiinnostusalue9', label: 'Veneily' },
    { name: 'iKiinnostusalue11', label: 'Lemmikkieläimet' },
    {
      name: 'iKiinnostusalue13',
      label: 'Rakentaminen, remontointi, \'tee-se-itse\'',
    },
    { name: 'iKiinnostusalue14', label: 'Musiikki' },
    { name: 'iKiinnostusalue16', label: 'Sisustaminen' },
    { name: 'iKiinnostusalue17', label: 'TV-viihde ja elokuvat' },
    { name: 'iKiinnostusalue19', label: 'Kauneudenhoito' },
    { name: 'iKiinnostusalue20', label: 'Matkailu' },
    { name: 'iKiinnostusalue21', label: 'Viihde ja julkisuuden henkilöt' },
    { name: 'iKiinnostusalue23', label: 'Liikunta ja urheilu' },
    { name: 'iKiinnostusalue24', label: 'Golf' },
    { name: 'iKiinnostusalue25', label: 'Loma-asuminen ja mökkeily' },
    { name: 'iKiinnostusalue26', label: 'Moottoriurheilu' },
    { name: 'iKiinnostusalue27', label: 'Koti ja perhe' },
    { name: 'iKiinnostusalue28', label: 'Rikokset ja niiden taustat' },
    { name: 'iKiinnostusalue29', label: 'Ristikot, sudokut ja tehtävät' },
    { name: 'iKiinnostusalue30', label: 'Ympäristöasiat, ekologisuus' },
    { name: 'iKiinnostusalue31', label: 'Tiede' },
    { name: 'iKiinnostusalue32', label: 'Horoskoopit ja astrologia' },
    { name: 'iKiinnostusalue33', label: 'Kielet ja opiskelu' },
    { name: 'iKiinnostusalue34', label: 'Elämäntarinat ja haastattelut' },
    { name: 'iKiinnostusalue35', label: 'Seniorikansalaisten asiat' },
    { name: 'iKiinnostusalue36', label: 'Yhteiskunta ja politiikka' },
    { name: 'iKiinnostusalue37', label: 'Kulttuuri' },
    {
      name: 'iKiinnostusalue38',
      label: 'Eräretkeily ja luonnossa liikkuminen',
    },
    { name: 'iKiinnostusalue39', label: 'Kalastus' },
    { name: 'iKiinnostusalue40', label: 'Metsästys' },
  ],
  dropDowns: [
    { name: 'iKiinnostusalue531', label: '1. lapsi' },
    { name: 'iKiinnostusalue533', label: '2. lapsi' },
    { name: 'iKiinnostusalue535', label: '3. lapsi' },
  ],
};

// const validate = (values) => {
//   // At least one filed needs to be selected
//   if (!values || (
//     !values.iKiinnostusalue1
//     && !values.iKiinnostusalue2
//     && !values.iKiinnostusalue3
//     && !values.iKiinnostusalue4
//     && !values.iKiinnostusalue5
//     && !values.iKiinnostusalue6
//     && !values.iKiinnostusalue7
//     && !values.iKiinnostusalue8
//     && !values.iKiinnostusalue9
//     && !values.iKiinnostusalue10
//     && !values.iKiinnostusalue11
//     && !values.iKiinnostusalue12
//     && !values.iKiinnostusalue13
//     && !values.iKiinnostusalue14
//     && !values.iKiinnostusalue16
//     && !values.iKiinnostusalue17
//     && !values.iKiinnostusalue19
//     && !values.iKiinnostusalue20
//     && !values.iKiinnostusalue21
//     && !values.iKiinnostusalue23
//     && !values.iKiinnostusalue24
//     && !values.iKiinnostusalue25
//     && !values.iKiinnostusalue26
//     && !values.iKiinnostusalue27
//     && !values.iKiinnostusalue28
//     && !values.iKiinnostusalue29
//     && !values.iKiinnostusalue30
//     && !values.iKiinnostusalue31)) {
//     invalidForm = true;
//   } else {
//     invalidForm = false;
//   }
//   return null;
// };

const getYearOptions = () => {
  const ageOptions = [];
  let index = 0;
  for (let i = currentYear - 18; i <= currentYear; i++) {
    ageOptions[index] = { key: `${i}`, value: `${i}`, text: `${i}` };
    index += 1;
  }
  return ageOptions;
};

const PollForm = (props) => {
  const { onPollSubmit } = props;
  const context = useContext(Context);
  const { offer, customer } = context;

  const formMethods = useForm({
    defaultValues: {
      customer,
      ...pollQuestions.checkBoxes.reduce(
        (prev, { name }) => ({ ...prev, [name]: '' }),
        {},
      ),
      ...pollQuestions.dropDowns.reduce(
        (prev, { name }) => ({ ...prev, [name]: '' }),
        {},
      ),
    },
  });

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // <===== This stops the form from being submitted
    }
  };

  const groupCheckboxes = (arr, groupSize = 6) => {
    const groups = [];
    for (let i = 0; i < arr.length; i += groupSize) {
      const group = arr.slice(i, i + groupSize);
      groups.push(group);
    }
    return groups;
  };

  const checkBoxSplitIndex
    = pollQuestions.checkBoxes.length % 2 === 0
      ? pollQuestions.checkBoxes.length / 2
      : pollQuestions.checkBoxes.length / 2 + 1;
  const checkBoxesFirst = pollQuestions.checkBoxes.slice(0, checkBoxSplitIndex);
  const checkBoxesLast = pollQuestions.checkBoxes.slice(
    checkBoxSplitIndex,
    pollQuestions.checkBoxes.length,
  );

  const groupedCheckboxes1 = groupCheckboxes(checkBoxesFirst);
  const groupedCheckboxes2 = groupCheckboxes(checkBoxesLast);

  return (
    <FormProvider {...formMethods}>
      <Form
        onKeyPress={onKeyPress}
        onSubmit={formMethods.handleSubmit((data) => {
          onPollSubmit({
            ...data,
            tyrkyte: customer.tyrkyte,
            tyrkyteId: customer.tyrkyteId || customer.tyrkyte.tyrkyte,
            tyrkyteLuotu: customer.tyrkyte.luotu,
          });
        })}
        className="poll-form wrapper-box-shadow"
      >
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            className="poll-form-header-space mb-0 mt-0"
            style={{ background: 'white' }}
          >
            <h1>Minua kiinnostavat aiheet</h1>
          </Grid.Column>
          {customer.tyrkyteId
            && offer.magazines[0].extraTextInterestSurvey1 && (
            <div
              dangerouslySetInnerHTML={
                offer.magazines[0].extraTextInterestSurvey1
              }
              className="poll-form-extratext-wrapper p-10 mb-0"
            />
          )}
          {customer.tyrkyteId && offer.banners.topBannerImageInterestSurvey && (
            <span>
              <Image src={offer.banners.topBannerImageInterestSurvey} fluid />
            </span>
          )}
        </Grid.Row>
        <Grid
          stackable
          centered
          className="no-margin no-padding poll-form-grid-content"
        >
          <Grid.Row>
            <Grid.Column floated="left" mobile={8} tablet={8} computer={8}>
              {groupedCheckboxes1.map((checkBoxGroup) => (
                <>
                  {checkBoxGroup.map((question) => (
                    <CheckBoxField
                      key={question.name}
                      name={question.name}
                      label={question.label}
                    />
                  ))}
                  <br />
                </>
              ))}
            </Grid.Column>
            <Grid.Column floated="left" mobile={8} tablet={8} computer={8}>
              {groupedCheckboxes2.map((checkBoxGroup) => (
                <>
                  {checkBoxGroup.map((question) => (
                    <CheckBoxField
                      key={question.name}
                      name={question.name}
                      label={question.label}
                    />
                  ))}
                  <br />
                </>
              ))}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column floated="left" mobile={16} tablet={16} computer={16}>
              <span className="fieldTitle">
                Alaikäisten lasten syntymävuodet:
              </span>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column floated="left" mobile={16} tablet={8} computer={8}>
              {pollQuestions.dropDowns.map((q) => (
                <React.Fragment key={q.name}>
                  <span className="fieldTitle">{q.label}</span>
                  <DropdownSelection
                    name={q.name}
                    placeholder="Syntymävuosi"
                    options={getYearOptions()}
                  />
                </React.Fragment>
              ))}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Button
                type="submit"
                id="continue"
                fluid
                color="blue"
                style={{ boxShadow: 'none' }}
              >
                TALLENNA VASTAUKSET JA JATKA
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </FormProvider>
  );
};

export default PollForm;
