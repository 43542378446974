import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid } from 'semantic-ui-react';

import InputWrapper from '../../util/InputWrapper';
import CountrySelect from './CountrySelect';
import {
  countryShouldDisplayOrderAbroadText,
  orderAbroadText,
  Recipient,
} from '../../../utils/util';

const RecipientDetails = (props) => {
  const { askRecipientEmail, emailRequired = true } = props;
  const { getValues } = useFormContext();
  const {
    recipient,
    recipientDetails: { recipientCountry },
  } = getValues();

  return (
    <Grid id="recipientDetails">
      <Grid.Row>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label="Etunimi *"
            name="recipientDetails.recipientFirstName"
            type="text"
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label="Sukunimi *"
            name="recipientDetails.recipientLastName"
            type="text"
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={16}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label="Osoite *"
            name="recipientDetails.recipientStreetAddress"
            type="text"
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label="Postinumero *"
            name="recipientDetails.recipientZipCode"
            type="text"
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label="Postitoimipaikka *"
            name="recipientDetails.recipientCity"
            type="text"
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          className="recipient-selection-input-column"
        >
          <CountrySelect
            placeholder="Maa"
            name="recipientDetails.recipientCountry"
            type="text"
          />
        </Grid.Column>
        {askRecipientEmail && (
          <Grid.Column
            mobile={16}
            tablet={8}
            computer={8}
            className="recipient-selection-input-column"
          >
            <InputWrapper
              label={`Sähköpostiosoite ${emailRequired ? '*' : ''}`}
              name="recipientDetails.recipientEmail"
              type="email"
            />
          </Grid.Column>
        )}

        <Grid.Column verticalAlign="middle" mobile={16} tablet={8} computer={8}>
          {[Recipient.TO_FRIEND, Recipient.TO_SELF].includes(recipient)
            && countryShouldDisplayOrderAbroadText(recipientCountry) && (
            <p style={{ color: 'red', fontSize: 16 }}>{orderAbroadText}</p>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default RecipientDetails;
