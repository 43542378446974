/* eslint-disable max-len */
const t = {
  // Please add in alphabetical order
  BANK_PAYMENT: 'Verkkomaksu',
  CANCELING_ORDER: 'Perutaan tilausta...',
  CANCELING_ORDER_FAILED: 'Tilauksen poisto epäonnistui!',
  CANCELING_ORDER_SUCCESS: 'Maksutapahtuma keskeytettiin.',
  CARD_FAILURE: 'Kortin veloitus epäonnistui. Tilaus peruttiin.',
  CARD_PAYMENT: 'Korttiveloitus (debit tai credit)',
  CARD_PAYMENT_PLAIN: 'Korttiveloitus',
  COULD_NOT_CONFIRM_SUBSCRIPTION:
    'Emme voineet vahvistaa tilaustasi. Asiakaspalvelumme ottaa teihin tarvittaessa yhteyttä lähipäivinä.',
  COULD_NOT_FIND_OFFER:
    'Annetuilla tiedoilla ei löytynyt tarjoomaa, tarkista kenttien tiedot.',
  DEFAULT_BANK_INFO_TEXT:
    'Tarjousjakso maksetaan verkkopankissa ja se veloitetaan yhdessä erässä. Maksujen välitykseen käytetään Paytrail-palvelua. Tulevat jaksot maksetaan paperilaskulla, josta veloitamme käsittely- ja postituskulut 4,90 €/lasku. Vaihda paperilasku kätevään ja maksuttomaan e-laskuun. Lähetämme ohjeet maksutavanvaihdosta sähköpostitse ennen seuraavaa tilausjaksoa. Tilauksen voi irtisanoa koska tahansa tilausehtojen mukaisesti.',
  DEFAULT_ERROR: 'Jotain meni pieleen.',
  DEFAULT_ERROR_HTML:
    '<h1 className="error-header" style="padding: 0 16px">Jotain meni pieleen.</h1><div className="error-wrapper-front" style="padding: 0 16px"><p className="error-p">Yritä hetken kuluttua uudelleen tai katso Otavamedian ajankohtaiset tarjoukset <a href="https://otavamedia.fi/tuotteet-ja-edut/tutustu-ja-tilaa/" className="error-link">täältä</a>.</p></div>',
  DEFAULT_INVOICING_MODE_CHANGE_CREDITCARD_INFO_TEXT:
    'Voit maksaa tilauksesi seuraavilla maksukorteilla: Visa, Visa Electron, MasterCard, Eurocard. Maksujen välitykseen käytetään Paytrail-palvelua.\n\nSeuraavan tilausjakson hinta veloitetaan automaattisesti kortiltasi uuden tilausjakson alkaessa.',
  E_IDENT_ERROR: 'Tunnistautuminen epäonnistui. Tilaus peruttiin.',
  E_IDENT_NAMES_MISMATCH:
    'Tunnistautumisen henkilötiedot eivät vastanneet tilauksella annettuja. Tilaus peruttiin.',
  EMAIL_MARKETING_CONSENT_TEXT:
    'Minulle ei saa lähettää sähköistä suoramarkkinointia tai asiakasviestintää',
  INVOICE_IDENTIFICATION_CALL: 'Lasku (asiakaspalvelun tunnistussoitto)',
  INVOICE_PAYMENT_BANK: 'Lasku (tunnistus pankkitunnuksilla)',
  INVOICE_PAYMENT_CALL: 'Lasku (asiakaspalvelun tunnistussoitto)',
  OFFER_INVALID_EXPIRED: 'Tarjous ei ole enää voimassa.',
  OFFER_REQUIRES_DISPOSABLE_TYRKYTE:
    'Tarjooma vaatii kertakäyttöisen tyrkytteen!',
  ORDER_NOT_FOUND: 'Tilausta ei löydy.',
  OTAVAMEDIA_CUSTOMER_SERVICE_URL:
    'https://otavamedia.fi/asiakaspalvelu/#asiakaspalvelun-yhteystiedot',
  MOBILEPAY_AGREEMENT_ERROR:
    'MobilePay -maksua ei voitu suorittaa tai se hylättiin.',
  MOBILEPAY_PAYMENT: 'MobilePay-veloitus',
  OTAVAMEDIA_ORDER_MAGAZINES_LINK:
    'https://otavamedia.fi/tuotteet-ja-edut/tutustu-ja-tilaa/',
  PAYER_DETAILS: 'Maksajan tiedot',
  PAYTRAIL_INVOICE_PAYMENT_FAILURE:
    'Suoritus epäonnistui! Poistetaan tilausta...',
  POLLING_MOBILEPAY_STATUS: 'MobilePay -maksua käsitellään...',
  RECIPIENT_DETAILS: 'Saajan tiedot',
  REDIRECTING_TO_MOBILEPAY:
    'Sinut ohjataan hetken kuluttua MobilePay -näkymään...',
  REDIRECTING_TO_MOBILEPAY_FAILED:
    'MobilePay uudelleenohjaus epäonnistui. Ole hyvä ja yritä uudelleen. Ongelman toistuessa ota yhteyttä asiakaspalveluun.',
  REDIRECTING_TO_PAYMENT: 'Uudelleenohjataan maksupalveluun...',
  REDIRECTING_TO_CARD: 'Uudelleenohjataan maksukortin lisäykseen...',
  RETURN_TO_OFFER: 'Palaa takaisin tilaussivulle',
  SUBSCRIPTION_ALREADY_CONFIRMED: 'Tilaus on jo vahvistettu.',
  SUBSCRIPTION_NOT_FOUND: 'Tilausta ei löydy.',
  // todo remove after approved by customer
  INVOICE_PAYMENT_CALL_ORDER_TEXT:
    'Asiakaspalvelumme ottaa sinuun yhteyttä mahdollisimman pian tilauksen hyväksymiseksi.',
  TYRKYTE_INVALID: 'Virheellinen koodi.',
  TYRKYTE_INVALID_ALREADY_USED: 'Koodi on jo käytetty.',
  TYRKYTE_INVALID_EXPIRED: 'Koodin voimassaolo on päättynyt.',
};

export default t;
