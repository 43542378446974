import React, { useState } from 'react';
import { Button, Grid, Image, Input } from 'semantic-ui-react';

import ArrowIcon from '../util/ArrowIcon';
import { isValidSsn } from '../../utils/validators';

const EInvoiceForm = (props) => {
  const { eInvoice, submit, options } = props;
  const [ssn, setSsn] = useState('');
  const [error, setError] = useState(null);

  const infoText = options?.invoicingModeChange?.eInvoice?.infoText
    ? options.invoicingModeChange.eInvoice.infoText
    : 'options.invoicingModeChange.eInvoice.infoText';
  const infoText2
    = 'Olet melkein valmis. Pankkisi vaatii henkilötunnusta, jotta e-lasku saadaan pankissasi eteenpäin:';

  const handleOnChange = (e) => {
    if (error) setError(null);
    setSsn(e.target.value);
  };

  const handleOnClick = () => {
    if (!isValidSsn(ssn)) {
      setError('Henkilötunnuksen tulee olla muotoa PPKKVVXXXXX');
    } else {
      submit(ssn);
    }
  };

  return (
    <>
      <Grid.Row id="eInvoiceForm">
        <Grid.Column
          width={16}
          className="invoicingChange-infoText"
          textAlign="center"
        >
          <span dangerouslySetInnerHTML={{ __html: infoText }} />
          <Grid centered>
            <Grid.Row style={{ paddingBottom: 0 }}>
              <Grid.Column width={6} textAlign="center">
                <b>Valittu pankki:</b>
                <Image
                  key={eInvoice.name}
                  src={eInvoice.src}
                  style={{ paddingTop: 10 }}
                  centered
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column
          width={16}
          className="invoicingChange-infoText-noBg"
          textAlign="center"
        >
          <b>
            <p>{infoText2}</p>
          </b>
        </Grid.Column>
        <Grid.Column
          width={16}
          className="invoicingChange-infoText-noBg"
          textAlign="center"
        >
          <Input
            type="text"
            focus
            id="ssn"
            placeholder="Henkilötunnus (PPKKVVXXXXX)"
            value={ssn}
            onChange={handleOnChange}
            style={{ width: 280 }}
          />
          {error && (
            <span style={{ color: 'red', float: 'right', padding: 5 }}>
              {error}
            </span>
          )}
        </Grid.Column>
        <Grid.Column
          width={16}
          textAlign="center"
          style={{ paddingBottom: '2rem' }}
        >
          <Button
            className="invoicingChange-button"
            color="blue"
            onClick={handleOnClick}
            disabled={!ssn}
          >
            <ArrowIcon />
            VAHVISTA
          </Button>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

export default EInvoiceForm;
