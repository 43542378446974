import {
  BACKEND_IN_MAINTENANCE_MODE,
  CONFIRM_SUBSCRIPTION_CONFIRM_FAILED,
  CONFIRM_SUBSCRIPTION_CONFIRM_SUCCESSFUL,
  CONFIRM_SUBSCRIPTION_CONFIRMING,
  CONFIRM_SUBSCRIPTION_CONFLICT_ALREADY_CONFIRMED,
  CONFIRM_SUBSCRIPTION_CONFLICT_INVALID_STATUS,
  CONFIRM_SUBSCRIPTION_FETCH,
  CONFIRM_SUBSCRIPTION_FETCH_FAILURE,
  CONFIRM_SUBSCRIPTION_FETCH_SUCCESSFUL,
  CONFIRM_SUBSCRIPTION_INIT,
  CONFIRM_SUBSCRIPTION_INIT_FAILURE,
  CONFIRM_SUBSCRIPTION_NOT_FOUND,
} from '../actions/actionTypes';

export const initialState = {
  state: CONFIRM_SUBSCRIPTION_INIT,
  params: undefined,
  unconfirmedSubscriptions: undefined,
};

// eslint-disable-next-line default-param-last
export const confirmSubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIRM_SUBSCRIPTION_INIT: {
      return state;
    }
    case CONFIRM_SUBSCRIPTION_INIT_FAILURE: {
      return {
        ...state,
        state: CONFIRM_SUBSCRIPTION_INIT_FAILURE,
        error: action.payload.error,
      };
    }
    case CONFIRM_SUBSCRIPTION_FETCH: {
      return {
        ...state,
        state: CONFIRM_SUBSCRIPTION_FETCH,
        ...action.payload,
      };
    }
    case CONFIRM_SUBSCRIPTION_FETCH_FAILURE: {
      return {
        ...state,
        state: CONFIRM_SUBSCRIPTION_FETCH_FAILURE,
        error: action.payload.error,
      };
    }
    case CONFIRM_SUBSCRIPTION_CONFLICT_ALREADY_CONFIRMED: {
      return {
        ...state,
        state: CONFIRM_SUBSCRIPTION_CONFLICT_ALREADY_CONFIRMED,
        error: action.payload.error,
      };
    }
    case CONFIRM_SUBSCRIPTION_CONFLICT_INVALID_STATUS: {
      return {
        ...state,
        state: CONFIRM_SUBSCRIPTION_CONFLICT_INVALID_STATUS,
        error: action.payload.error,
      };
    }
    case CONFIRM_SUBSCRIPTION_FETCH_SUCCESSFUL: {
      if (!action.payload.unconfirmedSubscriptions) {
        return {
          ...state,
          state: CONFIRM_SUBSCRIPTION_NOT_FOUND,
          unconfirmedSubscriptions: null,
        };
      }
      return {
        ...state,
        state: CONFIRM_SUBSCRIPTION_FETCH_SUCCESSFUL,
        unconfirmedSubscriptions: action.payload.unconfirmedSubscriptions,
      };
    }
    case CONFIRM_SUBSCRIPTION_NOT_FOUND: {
      return {
        ...state,
        state: CONFIRM_SUBSCRIPTION_NOT_FOUND,
        unconfirmedSubscriptions: null,
      };
    }
    case CONFIRM_SUBSCRIPTION_CONFIRMING: {
      return {
        ...state,
        state: CONFIRM_SUBSCRIPTION_CONFIRMING,
      };
    }
    case CONFIRM_SUBSCRIPTION_CONFIRM_FAILED: {
      return {
        ...state,
        state: CONFIRM_SUBSCRIPTION_CONFIRM_FAILED,
      };
    }
    case CONFIRM_SUBSCRIPTION_CONFIRM_SUCCESSFUL: {
      return {
        ...state,
        state: CONFIRM_SUBSCRIPTION_CONFIRM_SUCCESSFUL,
      };
    }
    // OTVMSUP-9, dispatch this when backend returns 503
    case BACKEND_IN_MAINTENANCE_MODE: {
      return {
        ...state,
        state: BACKEND_IN_MAINTENANCE_MODE,
      };
    }
    default: {
      return state;
    }
  }
};
