import React, { useContext, useState } from 'react';
import { Form, Button, Image, Grid, Message } from 'semantic-ui-react';
import { useForm, FormProvider } from 'react-hook-form';

import Context from '../../context';
import InputWrapper from '../util/InputWrapper';

const LukulahjaForm = (props) => {
  const { onTyrkyteSubmit } = props;
  const context = useContext(Context);
  const { offer } = context;
  const formMethods = useForm({
    defaultValues: {
      tyrkyteId: '',
    },
  });
  const [isValid, setIsValid] = useState(false);

  const validate = () => {
    if (!formMethods.getValues('tyrkyteId') && isValid) setIsValid(false);
    else setIsValid(true);
  };

  const { handleSubmit } = formMethods;

  const isLukulahja = true;

  return (
    <FormProvider {...formMethods}>
      <Grid.Row floated="left" mobile={16} tablet={16} computer={16}>
        <Grid.Column width={16}>
          <Image src="/images/lukulahja_banneri_2019.jpg" fluid />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row floated="left" mobile={16} tablet={16} computer={16}>
        <Grid.Column>
          <h1>Lunasta Lukulahjasi</h1>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row floated="left" mobile={16} tablet={16} computer={16}>
        <Grid.Column>
          <Form
            className="tyrkyte-form"
            onSubmit={handleSubmit((values) => {
              onTyrkyteSubmit(values.tyrkyteId, isLukulahja);
            })}
          >
            <Form.Field onChange={validate}>
              <InputWrapper
                label='Kirjoita alla olevaan laatikkoon lahjakoodisi ja klikkaa "Jatka"'
                name="tyrkyteId"
                type="text"
              />
            </Form.Field>
            <br />

            <Button
              type="submit"
              id="continue"
              fluid
              color="blue"
              disabled={!isValid}
            >
              JATKA
            </Button>
          </Form>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        {offer.errorText && (
          <Message negative>
            <p dangerouslySetInnerHTML={{ __html: offer.errorText }} />
          </Message>
        )}
      </Grid.Row>
    </FormProvider>
  );
};

export default LukulahjaForm;
