import * as api from '../api/Api';
import {
  BACKEND_IN_MAINTENANCE_MODE,
  CARD_RENEW_ADD_CARD_FAILURE,
  CARD_RENEW_INIT_FAILURE,
  CARD_RENEW_READY,
  CARD_RENEW_UPDATE_CARD,
  CARD_RENEW_UPDATE_CARD_FAILURE,
  CARD_RENEW_UPDATE_CARD_SUCCESS,
} from './actionTypes';

/**
 * Init payment card renewal.
 *
 * @param customerId
 * @param hash
 * @param cardRenewDispatch
 * @returns {function(...[*]=)}
 */
export const initCardRenewal = async (customerId, hash, cardRenewDispatch) => {
  const response = await api.get(
    `/payment/creditcard/renew?customerId=${customerId}&hash=${hash}`,
  );
  if (!response.error) {
    // Parse host part away since we are using react router redirect on campaign pages
    cardRenewDispatch({
      type: CARD_RENEW_READY,
      payload: {
        params: response.urlParams,
      },
    });
  } else {
    // OTVMSUP-9, backend in maintenance mode
    if (response.status && response.status === 503) {
      cardRenewDispatch({ type: BACKEND_IN_MAINTENANCE_MODE });
      return;
    }
    cardRenewDispatch({
      type: CARD_RENEW_INIT_FAILURE,
      payload: {
        ...response,
      },
    });
  }
};

export const initCardRenewalFail = async (cardRenewDispatch) => {
  cardRenewDispatch({
    type: CARD_RENEW_INIT_FAILURE,
  });
};

export const addCardFailure = async (cardRenewDispatch) => {
  cardRenewDispatch({
    type: CARD_RENEW_ADD_CARD_FAILURE,
  });
};

const parseUrlParams = (urlSearchParams) => {
  const parsedParams = {};
  urlSearchParams.forEach((value, key) => {
    parsedParams[key] = value;
  });
  return parsedParams;
};

export const updateToken = async (urlParams, cardRenewDispatch) => {
  cardRenewDispatch({
    type: CARD_RENEW_UPDATE_CARD,
    payload: {
      params: parseUrlParams(urlParams),
    },
  });
  const response = await api.get(
    `/payment/creditcard/update?${urlParams.toString()}`,
  );

  if (response.error) {
    // OTVMSUP-9, backend in maintenance mode
    if (response.status && response.status === 503) {
      cardRenewDispatch({ type: BACKEND_IN_MAINTENANCE_MODE });
      return;
    }
    cardRenewDispatch({
      type: CARD_RENEW_UPDATE_CARD_FAILURE,
      payload: {
        ...response,
      },
    });
  } else {
    cardRenewDispatch({
      type: CARD_RENEW_UPDATE_CARD_SUCCESS,
      payload: {
        ...response,
      },
    });
  }
};
