import React, { useContext, useEffect } from 'react';
import { Grid, Message } from 'semantic-ui-react';

import Context from '../../context';
import LoadingSpinner from './LoadingSpinner';
import LukulahjaForm from '../forms/LukulahjaForm';
import OrderForm from '../forms/OrderForm';
import PollForm from '../forms/PollForm';
import TyrkyteForm from '../forms/TyrkyteForm';

import * as types from '../../actions/actionTypes';
import t from '../../locales/fi';

const LandingPageContent = (props) => {
  const { onSubmit, onTyrkyteSubmit, onPollSubmit } = props;
  const context = useContext(Context);
  const { offer, customer } = context;

  useEffect(() => {}, [offer.state]);

  switch (offer.state) {
    case types.OFFER_INITIAL_STATE:
    case types.OFFER_SET_PARAMS: {
      return (
        <Grid.Row>
          <div
            style={{ textAlign: 'center', minWidth: '0%' }}
            className="wrapper-box-shadow white-bg"
          >
            <LoadingSpinner />
            <br />
          </div>
        </Grid.Row>
      );
    }
    case types.OFFER_FETCH: {
      return (
        <Grid.Row
          style={{ paddingTop: 0 }}
          className="wrapper-box-shadow white-bg"
        >
          <LoadingSpinner label="Ladataan tarjousta..." />
        </Grid.Row>
      );
    }
    case types.OFFER_FETCH_FAILURE: {
      return (
        <Grid.Row>
          <div
            style={{
              textAlign: 'center',
              minWidth: '0%',
              background: 'white',
              padding: '1em',
            }}
            className="wrapper-box-shadow"
          >
            {offer.errorText && (
              <p dangerouslySetInnerHTML={{ __html: t.DEFAULT_ERROR_HTML }} />
            )}
          </div>
        </Grid.Row>
      );
    }
    case types.OFFER_ASK_LUKULAHJA:
    case types.OFFER_FETCH_LUKULAHJA_FAILURE: {
      return <LukulahjaForm onTyrkyteSubmit={onTyrkyteSubmit} />;
    }
    case types.OFFER_ASK_TYRKYTE:
    case types.OFFER_FETCH_TYRKYTE_FAILURE: {
      return <TyrkyteForm onTyrkyteSubmit={onTyrkyteSubmit} />;
    }
    case types.OFFER_FETCH_TYRKYTE: {
      return (
        <Grid.Row
          style={{ paddingTop: 0 }}
          className="wrapper-box-shadow white-bg"
        >
          <LoadingSpinner label="Ladataan tarjousta..." />
        </Grid.Row>
      );
    }
    case types.OFFER_SHOW_POLL: {
      return <PollForm onPollSubmit={onPollSubmit} />;
    }
    case types.OFFER_SEND_POLL: {
      return (
        <Grid.Row>
          <LoadingSpinner label="Vastauksiasi käsitellään..." />
        </Grid.Row>
      );
    }
    case types.OFFER_SEND_POLL_FAILURE: {
      return (
        <Grid.Row>
          <PollForm onPollSubmit={onPollSubmit} />
          <Message negative>
            <p>
              Vastausten lähetys epäonnistui! Tarkista kentät ja yritä
              uudelleen! Ongelman toistuessa ota yhteyttä asiakaspalveluun.
            </p>
          </Message>
        </Grid.Row>
      );
    }
    case types.OFFER_FETCH_COMPLETE:
    case types.ORDER_INIT: {
      return (
        <Grid.Row className="p-0">
          <div style={{ textAlign: 'center', minWidth: '0%' }}>
            <OrderForm onSubmit={onSubmit} offer={offer} customer={customer} />
          </div>
        </Grid.Row>
      );
    }
    case types.ORDER_INIT_FAILURE: {
      return (
        <Grid.Row style={{ paddingTop: 0 }}>
          <div style={{ textAlign: 'center', minWidth: '0%' }}>
            <OrderForm onSubmit={onSubmit} offer={offer} customer={customer} />
            <Message negative>
              <p>
                Tilauksen lähetys epäonnistui! Ongelman toistuessa ota yhteyttä
                asiakaspalveluun.
              </p>
            </Message>
          </div>
        </Grid.Row>
      );
    }
    // OTVMSUP-9 Show maintenance mode info
    case types.BACKEND_IN_MAINTENANCE_MODE:
      return (
        <Grid.Row>
          <div style={{ textAlign: 'center', minWidth: '0%' }}>
            <h1>Päivitämme palvelua. Ole hyvä ja yritä myöhemmin uudelleen.</h1>
            <br />
          </div>
        </Grid.Row>
      );

    default:
      return (
        <Grid.Row>
          <div
            style={{ textAlign: 'center', minWidth: '0%' }}
            className="wrapper-box-shadow"
          >
            <p>Jotain meni pieleen! :(</p>
            <br />
          </div>
        </Grid.Row>
      );
  }
};

export default LandingPageContent;
