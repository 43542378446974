import React, { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import * as ga from '../../utils/googleAnalytics';

import PaywallSubAndAccountStatus from './PaywallSubAndAccountStatus';

const {
  resolveIsTokenRedirectAllowed,
  resolveTokenRedirectUrl,
} = require('../../utils/util');

const redirectStyle = {
  marginTop: '25px',
  maxWidth: '400px',
};

/**
 * Thank You -page content for paywall orders.
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const ThankYouContentPaywall = (props) => {
  const { subscription, order } = props;
  const redirectAllowed = resolveIsTokenRedirectAllowed(subscription);
  const redirectUrl = resolveTokenRedirectUrl(
    order.articleURL,
    subscription.readToken,
    true,
  );
  useEffect(() => {
    if (redirectAllowed) {
      ga.googleAnalyticsPaywallPurchase();
      setTimeout(() => {
        window.location.href = redirectUrl;
      }, 15000);
    }
  }, []);

  return (
    <Grid.Row>
      <div
        style={{
          textAlign: 'center',
          minWidth: '0%',
          paddingTop: '1rem',
          paddingBottom: '2rem',
        }}
      >
        <h1>Kiitos tilauksesta!</h1>
        <PaywallSubAndAccountStatus subscription={subscription} />
        {redirectAllowed && redirectUrl && (
          <div>
            <p style={redirectStyle}>
              Mikäli sinua ei ohjata automaattisesti takaisin artikkeliin
              15&nbsp;sekunnin kuluttua, paina <a href={redirectUrl}>tästä</a>
            </p>
          </div>
        )}
      </div>
    </Grid.Row>
  );
};
export default ThankYouContentPaywall;
