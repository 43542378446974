import React, { useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';

import Context from '../../context';

import * as confirmSubscriptionActions from '../../actions/confirmSubcription';
import * as ga from '../../utils/googleAnalytics';

import { CONFIRM_SUBSCRIPTION_FETCH_SUCCESSFUL } from '../../actions/actionTypes';
import ConfirmSubscriptionContent from '../main/ConfirmSubscriptionContent';
import Footer from '../main/Footer';

const ConfirmSubscriptionPage = () => {
  useEffect(() => {
    ga.googleAnalyticsPageView();
  }, []);
  const context = useContext(Context);
  const { confirmSubscription, confirmSubscriptionDispatch } = context;

  useEffect(() => {
    const shortId = window.location.href.replace(
      `${window.location.origin}/v/`,
      '',
    );
    confirmSubscriptionActions
      .fetchUnconfirmedSubscription(shortId, confirmSubscriptionDispatch)
      .then();
  }, []);

  useEffect(() => {
    if (confirmSubscription.state === CONFIRM_SUBSCRIPTION_FETCH_SUCCESSFUL) {
      const { shortId } = confirmSubscription;
      confirmSubscriptionActions
        .confirmSubscription({ shortId }, confirmSubscriptionDispatch)
        .then();
    }
  }, [confirmSubscription.state]);

  return (
    <>
      <div className="layout-container">
        <div id="invoicingChangePage" className="invoicingChange-container">
          <Grid centered className="padding">
            <Grid.Row>
              <Grid.Column
                textAlign="center"
                className="invoicingChange-header"
              >
                <img
                  style={{
                    height: '5ex',
                  }}
                  alt="otavamedia logo"
                  src="https://otavamedia.fi/wp-content/themes/om-otavamedia-theme/assets/img/logo.svg"
                />
              </Grid.Column>
            </Grid.Row>
            <ConfirmSubscriptionContent />
          </Grid>
        </div>
      </div>
      <div className="footer-section">
        <Footer />
      </div>
    </>
  );
};

export default ConfirmSubscriptionPage;
