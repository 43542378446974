import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';

import Context from '../../context';
import DurationAndPriceByOffer from './DurationAndPriceByOffer';
import ConfirmSubscriptionStatus from './ConfirmSubscriptionStatus';

const benefitsStringByBenefits = (benefits) => (
  <span>
    <h5 className="no-margin">
      <b>{benefits.length > 1 ? 'Tilaajalahjat:' : 'Tilaajalahja:'}</b>
    </h5>
    {benefits.map(({ benefitName }) => (
      <span>
        {benefitName}
        <br />
      </span>
    ))}
  </span>
);

const ConfirmSubscriptionInfo = () => {
  const context = useContext(Context);
  const { confirmSubscription } = context;
  const subscriptions = confirmSubscription?.unconfirmedSubscriptions;
  return (
    <>
      <Grid.Column
        width={16}
        textAlign="center"
        verticalAlign="middle"
        className="confirmSubscription-infoText"
      >
        <ConfirmSubscriptionStatus confirmSubscription={confirmSubscription} />
      </Grid.Column>
      <Grid.Column
        width={16}
        id="subscriptionDetails"
        className="confirmSubscription-subscriptionInfo"
        textAlign="center"
      >
        <h3 className="confirm-subscription-summary">Yhteenveto</h3>
        {subscriptions.map(
          ({
            offerName,
            duration,
            orderDurationType,
            subscriptionTotalPrice,
            normalPrice,
            salesType,
            __campaignBenefits__,
          }) => (
            <>
              <h5 className="no-margin">
                <b>Tarjousjakso:</b>
              </h5>
              {`${offerName} `}
              <br />
              <DurationAndPriceByOffer
                duration={duration}
                durationType={orderDurationType}
                price={subscriptionTotalPrice}
                bold
              />
              <br /> (norm.{' '}
              <DurationAndPriceByOffer
                duration={duration}
                durationType={orderDurationType}
                price={normalPrice}
              />
              )
              <br />
              {salesType}
              <br />
              {__campaignBenefits__.length > 0
                && benefitsStringByBenefits(__campaignBenefits__)}
              <br />
            </>
          ),
        )}
      </Grid.Column>
    </>
  );
};

export default ConfirmSubscriptionInfo;
