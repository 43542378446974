import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';

import Context from '../../context';
import ConfirmSubscriptionInfo from './ConfirmSubscriptionInfo';
import ErrorMessageRow from './ErrorMessageRow';
import t from '../../locales/fi';

import {
  BACKEND_IN_MAINTENANCE_MODE,
  CONFIRM_SUBSCRIPTION_CONFIRM_FAILED,
  CONFIRM_SUBSCRIPTION_CONFIRM_SUCCESSFUL,
  CONFIRM_SUBSCRIPTION_CONFIRMING,
  CONFIRM_SUBSCRIPTION_CONFLICT_ALREADY_CONFIRMED,
  CONFIRM_SUBSCRIPTION_CONFLICT_INVALID_STATUS,
  CONFIRM_SUBSCRIPTION_FETCH,
  CONFIRM_SUBSCRIPTION_FETCH_FAILURE,
  CONFIRM_SUBSCRIPTION_FETCH_SUCCESSFUL,
  CONFIRM_SUBSCRIPTION_INIT,
  CONFIRM_SUBSCRIPTION_INIT_FAILURE,
  CONFIRM_SUBSCRIPTION_NOT_FOUND,
} from '../../actions/actionTypes';
import LoadingSpinner from './LoadingSpinner';

const ConfirmSubscriptionContent = () => {
  const context = useContext(Context);
  const { confirmSubscription } = context;

  switch (confirmSubscription.state) {
    case CONFIRM_SUBSCRIPTION_INIT: {
      return <LoadingSpinner />;
    }
    case CONFIRM_SUBSCRIPTION_INIT_FAILURE: {
      return <ErrorMessageRow />;
    }
    case CONFIRM_SUBSCRIPTION_FETCH: {
      return <LoadingSpinner />;
    }
    case CONFIRM_SUBSCRIPTION_FETCH_FAILURE: {
      return <ErrorMessageRow />;
    }
    case CONFIRM_SUBSCRIPTION_CONFLICT_ALREADY_CONFIRMED: {
      return (
        <ErrorMessageRow
          text1={t.SUBSCRIPTION_ALREADY_CONFIRMED}
          text2={null}
        />
      );
    }
    case CONFIRM_SUBSCRIPTION_CONFLICT_INVALID_STATUS: {
      return (
        <ErrorMessageRow
          text1={t.COULD_NOT_CONFIRM_SUBSCRIPTION}
          text2={null}
        />
      );
    }
    case CONFIRM_SUBSCRIPTION_NOT_FOUND: {
      return <ErrorMessageRow text1={t.SUBSCRIPTION_NOT_FOUND} />;
    }
    case CONFIRM_SUBSCRIPTION_FETCH_SUCCESSFUL: {
      return (
        <Grid.Row id="confirmSubscriptionInfo">
          <ConfirmSubscriptionInfo data={confirmSubscription} />
        </Grid.Row>
      );
    }
    case CONFIRM_SUBSCRIPTION_CONFIRMING: {
      return (
        <Grid.Row id="confirmSubscriptionInfo">
          <ConfirmSubscriptionInfo data={confirmSubscription} />
        </Grid.Row>
      );
    }
    case CONFIRM_SUBSCRIPTION_CONFIRM_FAILED: {
      return <ErrorMessageRow text1={t.COULD_NOT_CONFIRM_SUBSCRIPTION} />;
    }
    case CONFIRM_SUBSCRIPTION_CONFIRM_SUCCESSFUL: {
      return (
        <Grid.Row id="confirmSubscriptionInfo">
          <ConfirmSubscriptionInfo data={confirmSubscription} />
        </Grid.Row>
      );
    }
    // OTVMSUP-9 Show maintenance mode info
    case BACKEND_IN_MAINTENANCE_MODE:
      return (
        <Grid.Row>
          <div style={{ textAlign: 'center', minWidth: '0%' }}>
            <h1>Päivitämme palvelua. Ole hyvä ja yritä myöhemmin uudelleen.</h1>
            <br />
          </div>
        </Grid.Row>
      );
    default: {
      return <ErrorMessageRow />;
    }
  }
};

export default ConfirmSubscriptionContent;
