import * as api from '../api/Api';
import { UnconfirmedSubscriptionError } from '../utils/consts';

import {
  BACKEND_IN_MAINTENANCE_MODE,
  CONFIRM_SUBSCRIPTION_CONFIRM_FAILED,
  CONFIRM_SUBSCRIPTION_CONFIRM_SUCCESSFUL,
  CONFIRM_SUBSCRIPTION_CONFIRMING,
  CONFIRM_SUBSCRIPTION_CONFLICT_ALREADY_CONFIRMED,
  CONFIRM_SUBSCRIPTION_CONFLICT_INVALID_STATUS,
  CONFIRM_SUBSCRIPTION_FETCH,
  CONFIRM_SUBSCRIPTION_FETCH_FAILURE,
  CONFIRM_SUBSCRIPTION_FETCH_SUCCESSFUL,
} from './actionTypes';

export const fetchUnconfirmedSubscription = async (
  shortId,
  confirmSubscriptionDispatch,
) => {
  confirmSubscriptionDispatch({
    type: CONFIRM_SUBSCRIPTION_FETCH,
    payload: {
      shortId,
    },
  });
  let response = null;
  try {
    response = await api.get('/confirm-subscription', { shortId });
    const { status, message, error } = response;
    if (error) {
      // OTVMSUP-9, backend in maintenance mode
      if (status && status === 503) {
        confirmSubscriptionDispatch({ type: BACKEND_IN_MAINTENANCE_MODE });
        return;
      }
      if (status === 409) {
        let dispatchType;
        if (message === UnconfirmedSubscriptionError.ALREADY_CONFIRMED) {
          dispatchType = CONFIRM_SUBSCRIPTION_CONFLICT_ALREADY_CONFIRMED;
        } else if (message === UnconfirmedSubscriptionError.INVALID_STATUS) {
          dispatchType = CONFIRM_SUBSCRIPTION_CONFLICT_INVALID_STATUS;
        } else {
          dispatchType = CONFIRM_SUBSCRIPTION_FETCH_FAILURE;
        }
        confirmSubscriptionDispatch({
          type: dispatchType,
          payload: { error: message },
        });
        return;
      }
      confirmSubscriptionDispatch({
        type: CONFIRM_SUBSCRIPTION_FETCH_FAILURE,
        payload: { error: message },
      });
      return;
    }
  } catch (e) {
    confirmSubscriptionDispatch({
      type: CONFIRM_SUBSCRIPTION_FETCH_FAILURE,
      payload: { error: e.message },
    });
    return;
  }

  confirmSubscriptionDispatch({
    type: CONFIRM_SUBSCRIPTION_FETCH_SUCCESSFUL,
    payload: { ...response },
  });
};

/**
 * @param {{shortId: string}} params
 * @param {*} confirmSubscriptionDispatch
 * @returns
 */
export const confirmSubscription = async (
  params,
  confirmSubscriptionDispatch,
) => {
  confirmSubscriptionDispatch({ type: CONFIRM_SUBSCRIPTION_CONFIRMING });
  let response = null;
  try {
    response = await api.put('/confirm-subscription', { ...params });
    if (response.error) {
      // OTVMSUP-9, backend in maintenance mode
      if (response.status && response.status === 503) {
        confirmSubscriptionDispatch({ type: BACKEND_IN_MAINTENANCE_MODE });
        return;
      }
      confirmSubscriptionDispatch({
        type: CONFIRM_SUBSCRIPTION_CONFIRM_FAILED,
      });
      return;
    }
  } catch (e) {
    confirmSubscriptionDispatch({
      type: CONFIRM_SUBSCRIPTION_CONFIRM_FAILED,
      payload: { error: e.message },
    });
    return;
  }
  confirmSubscriptionDispatch({
    type: CONFIRM_SUBSCRIPTION_CONFIRM_SUCCESSFUL,
  });
};
