import React, { useContext, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import Context from '../../context';
import InvoicingChangeContent from '../main/InvoicingChangeContent';
import MoveBackButton from '../main/MoveBackButton';
import Footer from '../main/Footer';

import * as invoicingChangeActions from '../../actions/invoicingChange';
import * as ga from '../../utils/googleAnalytics';

import {
  INVOICING_CHANGE_CARD_EMAIL_FORM,
  INVOICING_CHANGE_E_INVOICING_CHOOSE_BANK,
  INVOICING_CHANGE_E_INVOICING_SSN_FORM,
} from '../../actions/actionTypes';

const InvoicingChangePage = () => {
  const { offerId } = useParams();
  const { pathname } = useLocation();
  useEffect(() => {
    ga.googleAnalyticsPageView();
  }, []);
  const context = useContext(Context);
  const { invoicingChange, invoicingChangeDispatch } = context;

  useEffect(() => {
    const params = new URL(window.location.href).searchParams;
    const path = pathname.split('/')[1]; // 'vaihto' or 'kortti'
    params.set('offerId', offerId);
    // Paytrail redirect back
    if (params.has('success')) {
      // State is changes via reducer, process these asynchronously
      invoicingChangeActions.chargeCard(params, invoicingChangeDispatch).then();
    } else {
      invoicingChangeActions
        .fetchSubscriptionAndCustomer(params, path, invoicingChangeDispatch)
        .then();
    }
  }, []);

  const moveBackAllowed = invoicingChange
    ? invoicingChange.state === INVOICING_CHANGE_CARD_EMAIL_FORM
      || invoicingChange.state === INVOICING_CHANGE_E_INVOICING_CHOOSE_BANK
      || invoicingChange.state === INVOICING_CHANGE_E_INVOICING_SSN_FORM
    : false;

  const addCardInit = (email) => {
    invoicingChangeActions.initAddCardForm(
      invoicingChange?.offerId,
      invoicingChange?.subscription?.orderSubscriptionId,
      invoicingChange?.tyrkyteId,
      email,
      invoicingChange?.path,
      invoicingChangeDispatch,
    );
  };

  const submitEInvoiceForm = (ssn) => {
    const parsedData = { ...invoicingChange };
    parsedData.eInvoice.ssn = ssn;
    invoicingChangeActions
      .submitEInvoice(parsedData, invoicingChangeDispatch)
      .then();
  };

  return (
    <>
      <div className="layout-container">
        <div id="invoicingChangePage" className="invoicingChange-container">
          <Grid centered className="padding">
            <Grid.Row>
              <Grid.Column
                textAlign="center"
                className="invoicingChange-header"
              >
                {moveBackAllowed && (
                  <MoveBackButton
                    moveBack={() =>
                      invoicingChangeActions.moveBack(
                        invoicingChange,
                        invoicingChangeDispatch,
                      )
                    }
                  />
                )}
                <img
                  style={{
                    height: '5ex',
                  }}
                  alt="otavamedia logo"
                  src="https://otavamedia.fi/wp-content/themes/om-otavamedia-theme/assets/img/logo.svg"
                />
              </Grid.Column>
            </Grid.Row>
            <InvoicingChangeContent
              addCardInit={addCardInit}
              submitEInvoiceForm={submitEInvoiceForm}
            />
          </Grid>
        </div>
      </div>
      <div className="footer-section">
        <Footer />
      </div>
    </>
  );
};

export default InvoicingChangePage;
