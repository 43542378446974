import {
  BACKEND_IN_MAINTENANCE_MODE,
  INVOICING_CHANGE_CARD_ADD_FAILURE,
  INVOICING_CHANGE_CARD_ADD_INIT,
  INVOICING_CHANGE_CARD_ADD_REDIRECT,
  INVOICING_CHANGE_CARD_EMAIL_FORM,
  INVOICING_CHANGE_CARD_ONLY_FORM,
  INVOICING_CHANGE_CHARGE_CARD,
  INVOICING_CHANGE_CHARGE_CARD_FAILURE,
  INVOICING_CHANGE_CHARGE_CARD_SUCCESS,
  INVOICING_CHANGE_E_INVOICING_CHOOSE_BANK,
  INVOICING_CHANGE_E_INVOICING_FAILURE,
  INVOICING_CHANGE_E_INVOICING_SEND,
  INVOICING_CHANGE_E_INVOICING_SSN_FORM,
  INVOICING_CHANGE_E_INVOICING_SUCCESS,
  INVOICING_CHANGE_INIT,
  INVOICING_CHANGE_INIT_FAILURE,
  INVOICING_CHANGE_MOBILEPAY_INVOICING_INIT,
  INVOICING_CHANGE_ORDER_FETCH,
  INVOICING_CHANGE_ORDER_FETCH_FAILURE,
  INVOICING_CHANGE_ORDER_FETCH_SUCCESSFUL,
  INVOICING_CHANGE_TYRKYTE_EXPIRED,
  INVOICING_CHANGE_TYRKYTE_USED,
} from '../actions/actionTypes';

export const initialState = {
  state: INVOICING_CHANGE_INIT,
  path: null,
  options: null,
  subscription: null,
  offerDetails: null,
  tyrkyte: null,
  params: null,
  offerId: null,
  tyrkyteId: null,
  paytrailParams: null,
  eInvoice: null,
  error: null,
};

// eslint-disable-next-line default-param-last
export const invoicingChangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVOICING_CHANGE_INIT: {
      return state;
    }
    case INVOICING_CHANGE_INIT_FAILURE: {
      return {
        ...state,
        state: INVOICING_CHANGE_INIT_FAILURE,
        error: action.payload.error,
      };
    }
    case INVOICING_CHANGE_ORDER_FETCH: {
      return {
        ...state,
        state: INVOICING_CHANGE_ORDER_FETCH,
        ...action.payload,
      };
    }
    case INVOICING_CHANGE_TYRKYTE_EXPIRED: {
      return {
        ...state,
        state: INVOICING_CHANGE_TYRKYTE_EXPIRED,
      };
    }
    case INVOICING_CHANGE_TYRKYTE_USED: {
      return {
        ...state,
        state: INVOICING_CHANGE_TYRKYTE_USED,
      };
    }
    case INVOICING_CHANGE_ORDER_FETCH_FAILURE: {
      return {
        ...state,
        state: INVOICING_CHANGE_ORDER_FETCH_FAILURE,
        error: action.payload.error,
      };
    }
    case INVOICING_CHANGE_ORDER_FETCH_SUCCESSFUL: {
      return {
        ...state,
        state: INVOICING_CHANGE_ORDER_FETCH_SUCCESSFUL,
        path: action.payload.path,
        subscription: action.payload.subscription,
        options: action.payload.options,
        tyrkyte: action.payload.tyrkyte,
        offerDetails: action.payload.offerDetails,
      };
    }
    /*
     * E-invoicing
     */
    case INVOICING_CHANGE_E_INVOICING_CHOOSE_BANK: {
      return {
        ...state,
        state: INVOICING_CHANGE_E_INVOICING_CHOOSE_BANK,
      };
    }
    case INVOICING_CHANGE_E_INVOICING_SSN_FORM: {
      return {
        ...state,
        state: INVOICING_CHANGE_E_INVOICING_SSN_FORM,
        eInvoice: action.payload,
      };
    }
    case INVOICING_CHANGE_E_INVOICING_SEND: {
      return {
        ...state,
        state: INVOICING_CHANGE_E_INVOICING_SEND,
        eInvoice: action.payload.eInvoice,
      };
    }
    case INVOICING_CHANGE_E_INVOICING_FAILURE: {
      return {
        ...state,
        state: INVOICING_CHANGE_E_INVOICING_FAILURE,
      };
    }
    case INVOICING_CHANGE_E_INVOICING_SUCCESS: {
      return {
        ...state,
        state: INVOICING_CHANGE_E_INVOICING_SUCCESS,
      };
    }
    /*
     * Card invoicing
     */
    case INVOICING_CHANGE_CARD_ONLY_FORM: {
      return {
        ...state,
        state: INVOICING_CHANGE_CARD_ONLY_FORM,
        path: action.payload.path,
        subscription: action.payload.subscription,
        options: action.payload.options,
        tyrkyte: action.payload.tyrkyte,
        offerDetails: action.payload.offerDetails,
      };
    }
    case INVOICING_CHANGE_CARD_EMAIL_FORM: {
      return {
        ...state,
        state: INVOICING_CHANGE_CARD_EMAIL_FORM,
      };
    }
    case INVOICING_CHANGE_CARD_ADD_INIT: {
      return {
        ...state,
        state: INVOICING_CHANGE_CARD_ADD_INIT,
      };
    }
    case INVOICING_CHANGE_CARD_ADD_FAILURE: {
      return {
        ...state,
        state: INVOICING_CHANGE_CARD_ADD_FAILURE,
      };
    }
    case INVOICING_CHANGE_CARD_ADD_REDIRECT: {
      return {
        ...state,
        state: INVOICING_CHANGE_CARD_ADD_REDIRECT,
        paytrailParams: action.payload,
      };
    }
    case INVOICING_CHANGE_CHARGE_CARD: {
      return {
        ...state,
        state: INVOICING_CHANGE_CHARGE_CARD,
      };
    }
    case INVOICING_CHANGE_CHARGE_CARD_FAILURE: {
      return {
        ...state,
        state: INVOICING_CHANGE_CHARGE_CARD_FAILURE,
      };
    }
    case INVOICING_CHANGE_CHARGE_CARD_SUCCESS: {
      return {
        ...state,
        state: INVOICING_CHANGE_CHARGE_CARD_SUCCESS,
        options: action.payload.options,
        subscription: action.payload.subscription,
      };
    }
    /*
     * MobilePay invoicing
     */
    case INVOICING_CHANGE_MOBILEPAY_INVOICING_INIT: {
      return {
        ...state,
        state: INVOICING_CHANGE_MOBILEPAY_INVOICING_INIT,
      };
    }
    // OTVMSUP-9, dispatch this when backend returns 503
    case BACKEND_IN_MAINTENANCE_MODE: {
      return {
        ...state,
        state: BACKEND_IN_MAINTENANCE_MODE,
      };
    }
    default: {
      return state;
    }
  }
};
