import axios from 'axios';

const HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'x-api-key': process.env.FRONTEND_BACKEND_APIKEY,
  Pragma: 'no-cache',
};

const config = { baseURL: process.env.FRONTEND_BACKEND_ENDPOINT };

export const get = async (uri, params = {}) => {
  try {
    const response = await axios.get(`${config.baseURL}${uri}`, {
      params,
      headers: HEADERS,
    });
    return response.data;
  } catch (e) {
    // Got response indicating non 2xx status code
    if (e.response) {
      console.log(
        'Non 2xx response from backend! : %s - %j',
        e.response.status,
        e.response.data,
      );
      return { error: true, status: e.response?.status, ...e.response.data };
    }
    throw e;
  }
};

export const post = async (uri, data = {}) => {
  try {
    const response = await axios.post(`${config.baseURL}${uri}`, data, {
      headers: HEADERS,
    });
    return response.data;
  } catch (e) {
    // Got response indicating non 2xx status code
    if (e.response) {
      console.log(
        'Non 2xx response from backend! : %s - %j',
        e.response.status,
        e.response.data,
      );
      return { error: true, status: e.response?.status, ...e.response.data };
    }
    throw e;
  }
};

export const put = async (uri, data = {}) => {
  try {
    const response = await axios.put(`${config.baseURL}${uri}`, data, {
      headers: HEADERS,
    });
    return response.data;
  } catch (e) {
    // Got response indicating non 2xx status code
    if (e.response) {
      console.log(
        'Non 2xx response from backend! : %s - %j',
        e.response.status,
        e.response.data,
      );
      return { error: true, status: e.response?.status, ...e.response.data };
    }
    throw e;
  }
};
