import { scroller } from 'react-scroll';

// Note: These have to be in the same order as in OrderForm
export const SCROLL_ELEMENTS = {
  BRAND_SELECTION: {
    name: 'brandSelection',
    description: 'Brändi',
  },
  LIST_SELECTION: {
    name: 'listSelection',
    description: 'Lista',
  },
  MAGAZINE_SELECTION: {
    name: 'magazineSelection',
    description: 'Tuote',
  },
  GIFT_SELECTION: {
    name: 'giftSelection',
    description: 'Tilaajalahja',
  },
  RECIPIENT_AND_PAYMENT_SELECTION: {
    name: 'recipientAndPaymentSelection',
    description: 'Yhteystiedot ja maksu',
    alternativeDescription: 'Yhteystiedot', // When no payment
  },
  ORDER_SUMMARY: {
    name: 'orderSummary',
    description: 'Yhteenveto',
  },
};

export const resolveStepBarScrollElements = (offer) => {
  let elements = Object.values(SCROLL_ELEMENTS).map((e) => ({
    name: e.name,
    description: e.description,
  }));
  // No brand selection and list selection in step bar breadcrumbs
  elements = elements.filter(
    (e) =>
      e.name !== SCROLL_ELEMENTS.BRAND_SELECTION.name
      && e.name !== SCROLL_ELEMENTS.LIST_SELECTION.name,
  );
  // No gift step if no gifts
  if (offer.gifts.length === 0) {
    elements = elements.filter(
      (e) => e.name !== SCROLL_ELEMENTS.GIFT_SELECTION.name,
    );
  }
  const firstMag = offer?.magazines?.[0];
  // No payment selection if free order or no payment selections allowed
  const cardPaymentAllowed = firstMag.paymentMethodCreditCard === '1';
  const invoicePaymentAllowed = firstMag.paymentMethodInvoice === '1';
  if (
    firstMag.kayakOrderType === 'I'
    || firstMag.kayakOrderType === 'L'
    || (!invoicePaymentAllowed && !cardPaymentAllowed)
  ) {
    elements[
      elements.findIndex(
        (e) => e.name === SCROLL_ELEMENTS.RECIPIENT_AND_PAYMENT_SELECTION.name,
      )
    ] = {
      name: SCROLL_ELEMENTS.RECIPIENT_AND_PAYMENT_SELECTION.name,
      description:
        SCROLL_ELEMENTS.RECIPIENT_AND_PAYMENT_SELECTION.alternativeDescription,
    };
  }
  return elements;
};

const handleScrollOffset = () => {
  const mobileOffset = -92;
  const desktopOffset = -128;
  try {
    const { innerWidth: width } = window;
    const isMobile = width < 1225;
    return isMobile ? mobileOffset : desktopOffset;
  } catch (err) {
    // Incase if window.innerWidth is not found return desktop nav height to offset
    return desktopOffset;
  }
};

/**
 * Scroll screen to given element.
 *
 * @param element Element to scroll to
 */
const scrollToElement = async (element) => {
  // Dirty hack. Async + timeout forces scrolling to happen after all renders which makes it more consistent
  setTimeout(() => {
    scroller.scrollTo(element, {
      duration: 1500,
      smooth: true,
      offset: handleScrollOffset(), // scroll n px above/under target element
    });
  }, 100);
};

export default scrollToElement;
