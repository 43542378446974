import React from 'react';
import { Button, Grid } from 'semantic-ui-react';

import * as types from '../../actions/actionTypes';
import { findFirstDigiPaperCode } from '../../utils/util';
import { resolveFrontPage } from '../../utils/digiFrontPages';
import ArrowIcon from '../util/ArrowIcon';

const InvoicingChangeSuccess = (props) => {
  const { invoicingChange } = props;
  // Paper code of first digi product in sub if found, otherwise null
  const firstDigiProductInSub = invoicingChange?.subscription?.productLines
    ? findFirstDigiPaperCode(invoicingChange.subscription.productLines)
    : null;

  const infoText = () => {
    const eInvoiceOptions
      = invoicingChange?.options?.invoicingModeChange?.eInvoice;
    const creditCardOptions
      = invoicingChange?.options?.invoicingModeChange?.creditCard;

    switch (invoicingChange.state) {
      case types.INVOICING_CHANGE_E_INVOICING_SUCCESS: {
        const isOp = invoicingChange?.eInvoice?.bic === 'OKOYFIHH';
        if (isOp) {
          return firstDigiProductInSub
            ? eInvoiceOptions?.successTextDigiOP
            : eInvoiceOptions?.successTextOP;
        }
        return firstDigiProductInSub
          ? eInvoiceOptions?.successTextDigi
          : eInvoiceOptions?.successText;
      }
      case types.INVOICING_CHANGE_CHARGE_CARD_SUCCESS: {
        return firstDigiProductInSub
          ? creditCardOptions?.successTextDigi
          : creditCardOptions?.successText;
      }
      default:
        return null;
    }
  };

  const digiMagazineLink = () => {
    const frontPage = resolveFrontPage(firstDigiProductInSub);
    return (
      <Grid.Column width={16} id="digiMagazineLink" textAlign="center">
        <Button
          className="invoicingChange-button"
          color="blue"
          as="a"
          href={frontPage}
        >
          <ArrowIcon />
          SIIRRY LUKEMAAN DIGIÄ
        </Button>
      </Grid.Column>
    );
  };

  return (
    <>
      <Grid.Row
        id="invoicingChangeSuccess"
        style={firstDigiProductInSub ? null : { paddingBottom: '2rem' }}
      >
        <Grid.Column
          width={16}
          id="successInfoText"
          className="invoicingChange-infoText"
          textAlign="center"
        >
          <span dangerouslySetInnerHTML={{ __html: infoText() }} />
        </Grid.Column>
      </Grid.Row>

      {firstDigiProductInSub && (
        <Grid.Row style={{ paddingBottom: '2rem' }}>
          <Grid.Column width={16} textAlign="center">
            {digiMagazineLink()}
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  );
};

export default InvoicingChangeSuccess;
