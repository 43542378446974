import 'core-js/stable'; // Polyfills for Babel
import 'regenerator-runtime/runtime'; // Runtime stuff for Babel
import React from 'react';
import { createRoot } from 'react-dom/client';
import Auth from '@aws-amplify/auth';

import './assets/stylesheets/index.less';
import './assets/stylesheets/slider.less';

import App from './App';

Auth.configure({
  Auth: {
    region: process.env.FRONTEND_AWS_REGION || '',
    userPoolId: process.env.FRONTEND_AWS_USER_POOL_ID || '',
    userPoolWebClientId: process.env.FRONTEND_AWS_APP_CLIENT_ID || '',
    oauth: {
      // Domain name
      domain: process.env.FRONTEND_AWS_OAUTH_URL || '',

      // Authorized scopes
      scope: ['email', 'profile', 'openid'],

      // 'code' for Authorization code grant,
      // 'token' for Implicit grant
      responseType: 'token',

      // optional, for Cognito hosted ui specified options
      options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        AdvancedSecurityDataCollectionFlag: true,
      },
    },
  },
});

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App />);
