import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, Form } from 'semantic-ui-react';

import Context from '../../../context';
import CountrySelect from './CountrySelect';
import InputWrapper from '../../util/InputWrapper';

import {
  countryShouldDisplayOrderAbroadText,
  orderAbroadText,
  Recipient,
  shouldDisplayStudentNumberInput,
} from '../../../utils/util';
import { PaymentMethods } from '../../../utils/consts';
import EmailInfoText from './EmailInfoText';
import EmailMarketingConsent from './EmailMarketingConsent';

const SubscriberDetails = (props) => {
  const { emailRequired = true } = props;
  const context = useContext(Context);
  const { offer, customer } = context;
  const emailInfoText = offer?.options?.emailInfoText;
  const { getValues } = useFormContext();
  const {
    recipient,
    subscriberDetails: { payerCountry: subscriberCountry },
    paymentMethod,
  } = getValues();

  const displayStudentNumberInput = shouldDisplayStudentNumberInput(offer.offeringId);

  const phoneRequired = paymentMethod === PaymentMethods.INVOICING_CALL;
  if (customer.isLoadingCustomer) {
    return (
      <Grid id="subscriberDetails">
        <Grid.Row className="no-padding">
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <br />
            <div className="ui active loader" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  /*
   * If customer is fetched with tyrkyte, some fields should be uneditable
   */
  const preFilledCustomer
    = customer.tyrkyteId
    && customer.customer.firstName
    && customer.customer.lastName;

  if (preFilledCustomer) {
    return (
      <Grid id="subscriberDetails-prefilled">
        <Grid.Row className="no-padding">
          <Grid.Column mobile={16} tablet={16} computer={16} align="center">
            <p>
              {customer.customer.firstName !== ''
                && `${customer.customer.firstName} `}
              {customer.customer.lastName}
            </p>
            <p>{customer.customer.streetAddress} </p>
            <p>
              {customer.customer.zipCode} {customer.customer.city}
            </p>
            <Form.Field width={16} className="prefilled-details-field">
              <InputWrapper
                placeholder={`Sähköpostiosoite ${emailRequired ? '*' : ''}`}
                name="subscriberDetails.payerEmail"
                type="email"
              />
            </Form.Field>
            <Form.Field width={16} className="prefilled-details-field">
              <InputWrapper
                name="subscriberDetails.payerPhoneNumber"
                type="text"
                placeholder={`Puhelinnumero ${phoneRequired ? '*' : ''}`}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <EmailMarketingConsent />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="no-padding">
          <Grid.Column mobile={16} tablet={16} computer={16} align="center">
            <Form.Field>
              <Grid.Column
                className={
                  customer.tyrkyte && customer.tyrkyte.masterasiakasid
                    ? 'right floated'
                    : 'right floated'
                }
                mobile={16}
                tablet={8}
                computer={8}
              >
                <div className="form-annotation form-annotation-email-address">
                  <EmailInfoText infoText={emailInfoText} />
                </div>
              </Grid.Column>
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  return (
    <Grid id="subscriberDetails" style={{ backgroundColor: 'white' }}>
      <Grid.Row className="no-padding">
        {displayStudentNumberInput && <Grid.Column
          mobile={16}
          tablet={16}
          computer={16}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label="Opiskelijanumero *"
            name="subscriberDetails.payerStudentNumber"
            type="text"
          />
        </Grid.Column>}
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label="Etunimi *"
            name="subscriberDetails.payerFirstName"
            type="text"
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label="Sukunimi *"
            name="subscriberDetails.payerLastName"
            type="text"
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={16}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label="Osoite *"
            name="subscriberDetails.payerStreetAddress"
            type="text"
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label="Postinumero *"
            name="subscriberDetails.payerZipCode"
            type="text"
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label="Postitoimipaikka *"
            name="subscriberDetails.payerCity"
            type="text"
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label={`Sähköpostiosoite ${emailRequired ? '*' : ''}`}
            name="subscriberDetails.payerEmail"
            type="email"
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label="Puhelin"
            name="subscriberDetails.payerPhoneNumber"
            type="text"
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          className="recipient-selection-input-column"
        >
          <CountrySelect
            placeholder="Maa"
            name="subscriberDetails.payerCountry"
            type="text"
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={8}
          computer={8}
          className="recipient-selection-input-column"
        >
          <InputWrapper
            label="Syntymävuosi"
            name="subscriberDetails.payerBirthday"
            type="text"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <EmailMarketingConsent />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row className="no-padding">
        <Grid.Column
          className={
            customer.tyrkyte && customer.tyrkyte.masterasiakasid
              ? 'right floated'
              : 'right floated'
          }
          mobile={16}
          tablet={16}
          computer={16}
        >
          <div className="form-annotation">
            <EmailInfoText infoText={emailInfoText} />
          </div>
          <br />
          {[
            Recipient.TO_FRIEND,
            Recipient.TO_SELF,
            Recipient.TO_COMPANY,
          ].includes(recipient)
            && countryShouldDisplayOrderAbroadText(subscriberCountry) && (
            <p style={{ color: 'red', fontSize: 16 }}>{orderAbroadText}</p>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default SubscriberDetails;
