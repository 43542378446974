import React, { useState, useEffect, useContext } from 'react';
import { Message, Grid } from 'semantic-ui-react';
import Auth from '@aws-amplify/auth';

import Context from '../../context';
import Footer from '../main/Footer';
import TallennusPageHeaderButton from '../main/TallennusPageHeaderButton';
import TallennusPageLoginForm from '../forms/TallennusPageLoginForm';
import TallennusPageOrderForm from '../forms/TallennusPageOrderForm';
import TallennusPageOfferForm from '../forms/TallennusPageOfferForm';

import * as ga from '../../utils/googleAnalytics';
import * as offerActions from '../../actions/offer';
import * as orderActions from '../../actions/order';
import * as types from '../../actions/actionTypes';
import t from '../../locales/fi';

const TallennusPage = () => {
  useEffect(() => {
    ga.googleAnalyticsPageView();
  }, []);
  const context = useContext(Context);
  const {
    offer,
    offerDispatch,
    customer,
    customerDispatch,
    order,
    orderDispatch,
  } = context;

  const [state, setState] = useState({
    loading: true,
    isLoggingIn: false,
    isLoadingOffer: false,
    loginError: null,
  });
  const [notification, setNotification] = useState({
    show: false,
    isError: false,
    message: '',
  });

  const emptyPageInfo = async () => {
    await offerActions.emptyOffer(offerDispatch);
    await offerActions.emptyCustomer(customerDispatch);
  };

  /*
   * AWS login
   */
  const [user, setUser] = useState(null);
  // Check if already logged in when loading page first time.
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((u) => {
        setUser(u);
        setState({ ...state, loading: false });
      })
      .catch(() => {
        setState({ ...state, loading: false });
      });
  }, []);

  const handleLoginSubmit = async (event) => {
    event.persist();
    setState({ ...state, isLoggingIn: true });
    setNotification({ ...notification, show: false });
    Auth.signIn(event.target.username.value, event.target.password.value)
      .then((u) => {
        setUser(u);
        setState({ ...state, isLoggingIn: false });
      })
      .catch(() => {
        setState({ ...state, isLoggingIn: false });
        setNotification({
          show: true,
          isError: true,
          message: 'Tarkista käyttäjänimi ja salasana',
        });
      });
  };

  const logout = async () => {
    await emptyPageInfo();
    Auth.signOut()
      .then(() => setUser(null))
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const resetNotification = async () => {
    setNotification({ show: false, isError: false, message: '' });
  };

  const goBack = async () => {
    await emptyPageInfo();
  };

  const handleVoucherFormSubmit = async ({
    offeringId,
    tyrkyteId,
    barcode,
  }) => {
    setState({ ...state, isLoadingOffer: true });
    await resetNotification();
    await offerActions.emptyOffer(offerDispatch);
    await offerActions.emptyCustomer(customerDispatch);

    let customerId = null;
    if (barcode) {
      customerId = barcode;
    } else if (tyrkyteId) {
      customerId = tyrkyteId;
    }
    const params = {
      host: 'tallennus',
      offeringId,
      tyrkyteId: customerId,
    };

    if (customerId) {
      await offerActions.getOfferWithTyrkyte(
        { ...params, isPreview: false, isLukulahja: false },
        offerDispatch,
        customer,
        customerDispatch,
      );
    } else {
      await offerActions.getOffer(params, null, offerDispatch);
    }
    setState({ ...state, isLoadingOffer: false });
  };

  const onSubmit = async (data) => {
    try {
      orderActions.tallennusPageSubscribe(data, orderDispatch, offerDispatch);
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(e.message);
      await emptyPageInfo();
    }
  };

  // Offer states
  useEffect(() => {
    switch (offer.state) {
      case types.OFFER_FETCH_FAILURE:
      case types.OFFER_FETCH_TYRKYTE_FAILURE: {
        offerActions.emptyOffer(offerDispatch).then();
        setNotification({
          show: true,
          isError: true,
          message: offer.errorText || t.COULD_NOT_FIND_OFFER,
        });
        // setTimeout(async () => { await resetNotification(); }, 10000);
        break;
      }
      case types.ORDER_INIT_FAILURE: {
        setNotification({
          show: true,
          isError: true,
          message: offer.errorText || 'Tilauksen alustus epäonnistui',
        });
        // setTimeout(async () => { await resetNotification(); }, 10000);
        break;
      }
      default:
        break;
    }
  }, [offer.state]);
  // Order states
  useEffect(() => {
    switch (order.state) {
      case types.ORDER_SUBSCRIBE_FAILURE: {
        // eslint-disable-next-line no-alert
        setNotification({
          show: true,
          isError: true,
          message:
            'Tilauksen tallennus epäonnistui!\nOngelman toistuessa ota yhteyttä tekniseen tukeen.',
        });
        break;
      }
      case types.ORDER_COMPLETE: {
        // OTVMSUP-658 Scroll to top after successful subscription send
        window.scrollTo(0, 0);
        (async function emptyPage() {
          await emptyPageInfo();
        })();
        setNotification({
          show: true,
          isError: false,
          message: 'Tilaus luotiin onnistuneesti!',
        });
        setTimeout(async () => {
          await resetNotification();
        }, 10000);
        break;
      }
      default:
        break;
    }
  }, [order.state]);

  return (
    <>
      <div className="layout-container">
        <Grid id="tallennusPage" style={{ minWidth: '100%' }}>
          <Grid.Row>
            <Grid.Column
              width={16}
              textAlign="center"
              className="invoicingChange-header"
            >
              {user && (
                <TallennusPageHeaderButton
                  logout={logout}
                  goBack={goBack}
                  offeringId={offer?.offeringId}
                />
              )}
              <img
                style={{
                  height: '5ex',
                }}
                alt="otavamedia logo"
                src="https://otavamedia.fi/wp-content/themes/om-otavamedia-theme/assets/img/logo.svg"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            {state.loading && (
              <Grid.Column width={16} textAlign="center">
                <Grid centered className="padding">
                  <Grid.Row style={{ marginTop: '50px' }}>
                    <br />
                    <div className="ui active loader" />
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            )}

            {!user && (
              <Grid.Column width={16} textAlign="center">
                <TallennusPageLoginForm
                  handleLoginSubmit={handleLoginSubmit}
                  resetNotification={resetNotification}
                  isLoggingIn={state.isLoggingIn}
                />
              </Grid.Column>
            )}

            {notification.show && (
              <Grid.Column
                id="notification"
                width={16}
                textAlign="center"
                style={{ marginBottom: '20px' }}
              >
                {notification.isError ? (
                  <Message negative>{notification.message}</Message>
                ) : (
                  <Message positive>{notification.message}</Message>
                )}
              </Grid.Column>
            )}

            {user && !state.loading && (
              <>
                {offer.state && offer.state !== types.OFFER_FETCH_COMPLETE ? (
                  <Grid.Column width={16} textAlign="center">
                    <TallennusPageOfferForm
                      handleVoucherFormSubmit={handleVoucherFormSubmit}
                      resetNotification={resetNotification}
                      isLoadingOffer={state.isLoadingOffer}
                    />
                  </Grid.Column>
                ) : (
                  <Grid.Column width={16} textAlign="center">
                    <TallennusPageOrderForm onSubmit={onSubmit} />
                  </Grid.Column>
                )}
              </>
            )}
          </Grid.Row>
        </Grid>
      </div>
      <div className="footer-section">
        <Footer />
      </div>
    </>
  );
};

export default TallennusPage;
