import React, { useContext, useState } from 'react';
import { Button, Grid, Input } from 'semantic-ui-react';

import Context from '../../context';
import ArrowIcon from '../util/ArrowIcon';
import SubscriptionAndCustomerInfo from '../main/SubscriptionAndCustomerInfo';

import { isValidEmail } from '../../utils/validators';
import { resolveInvoicingChangeEmail } from '../../utils/util';
import t from '../../locales/fi';

const EMailForm = (props) => {
  const { onSubmit, onlyCard } = props;
  const context = useContext(Context);
  const { invoicingChange } = context;
  const { options, offerDetails } = invoicingChange;
  const subscriberEmail = resolveInvoicingChangeEmail(
    invoicingChange.subscription,
  );
  const [email, setEmail] = useState(subscriberEmail || '');
  const [error, setError] = useState(null);

  const infoText = options?.invoicingModeChange?.creditCard?.infoText
    ? options.invoicingModeChange.creditCard.infoText
    : t.DEFAULT_INVOICING_MODE_CHANGE_CREDITCARD_INFO_TEXT;

  const infoText2 = 'Anna sähköpostiosoite:';

  const handleOnChange = (e) => {
    if (error) setError(null);
    setEmail(e.target.value);
  };

  const handleOnClick = () => {
    if (!isValidEmail(email)) {
      setError('Tarkista sähköposti');
    } else {
      onSubmit(email);
    }
  };

  return (
    <>
      <Grid.Row id="emailForm">
        {onlyCard && <SubscriptionAndCustomerInfo data={invoicingChange} />}
        <Grid.Column
          width={16}
          className="invoicingChange-infoText"
          textAlign="center"
        >
          <span dangerouslySetInnerHTML={{ __html: infoText }} />
          {offerDetails?.renewalPriceString && (
            <span> {offerDetails.renewalPriceString}</span>
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column
          width={16}
          className="invoicingChange-infoText-noBg"
          textAlign="center"
        >
          <b>{infoText2}</b>
        </Grid.Column>
        <Grid.Column
          width={16}
          className="invoicingChange-infoText-noBg"
          textAlign="center"
        >
          <Input
            type="text"
            focus
            value={email}
            onChange={handleOnChange}
            style={{ width: 280 }}
          />
          {error && (
            <span style={{ color: 'red', float: 'right', padding: 5 }}>
              {error}
            </span>
          )}
        </Grid.Column>
        <Grid.Column
          width={16}
          className="invoicingChange-infoText-noBg"
          textAlign="center"
        >
          <Button
            className="invoicingChange-button"
            color="blue"
            onClick={handleOnClick}
            disabled={!email}
          >
            <ArrowIcon />
            SIIRRY MAKSUUN
          </Button>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

export default EMailForm;
