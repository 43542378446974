import React from 'react';
import { Icon } from 'semantic-ui-react';

const TallennusPageHeaderButton = (props) => {
  const { offeringId, logout, goBack } = props;

  const onClick = offeringId ? goBack : logout;
  const label = offeringId ? 'Takaisin' : 'Kirjaudu ulos';

  return (
    <button
      type="button"
      onClick={onClick}
      className="tallennusPage-headerButton"
    >
      <Icon name="angle left" />
      <b>{label}</b>
    </button>
  );
};

export default TallennusPageHeaderButton;
