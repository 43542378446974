import React, { useState, useEffect, useContext } from 'react';
import { Button, Grid } from 'semantic-ui-react';

import Context from '../../context';
import AddCardForm from '../forms/AddCardForm';
import HeaderBar from '../main/HeaderBar';

import * as cardRenewActions from '../../actions/cardRenew';
import * as ga from '../../utils/googleAnalytics';
import {
  BACKEND_IN_MAINTENANCE_MODE,
  CARD_RENEW_ADD_CARD_FAILURE,
  CARD_RENEW_INIT,
  CARD_RENEW_INIT_FAILURE,
  CARD_RENEW_READY,
  CARD_RENEW_UPDATE_CARD,
  CARD_RENEW_UPDATE_CARD_FAILURE,
  CARD_RENEW_UPDATE_CARD_SUCCESS,
} from '../../actions/actionTypes';
import Footer from '../main/Footer';

const RenewCardPage = () => {
  useEffect(() => {
    ga.googleAnalyticsPageView();
  }, []);
  const context = useContext(Context);
  const { cardRenew, cardRenewDispatch } = context;
  /**
   * Fix customer id in url params.
   *
   * Customer link is of type: "/card/renew?<customerId>&hash=<hash>".
   * Paytrail success redirect is of type: "/card/renew?customerId=<customerId>&hash=<hash>".
   * Need to add customerId as key for first param if customer link for validation and parsing.
   * (Jaicom wants renewal url customerId like this for some reason).
   *
   * @param url window.location.href
   * @return {URLSearchParams} parsed URLSearchParams object
   */
  const fixUrlParams = (url) => {
    // If urlParams has customerId (i.e. when redirect from checkout) just pass searchParams back
    const originalUrlParams = new URL(url).searchParams;
    if (originalUrlParams.has('customerId')) {
      return originalUrlParams;
    }
    // Split url to path (splitUrl[0]) and urlParams (splitUrl[1])
    const splitUrl = url.split('?');

    const fixedUrl = `${splitUrl[0]}?customerId=${splitUrl[1]}`;
    return new URL(fixedUrl).searchParams;
  };
  /**
   * Validate url params.
   *
   * Check if customerId is present (added with fix method before validation if missing)
   * Check if customerId is purely numeric
   * Check if hash is present (used to validate customerId with secret at backend)
   *
   * @param urlParams
   * @return {*|boolean}
   */
  const isValidUrlParams = (urlParams) =>
    urlParams.has('customerId')
    && /^\d+$/.test(urlParams.get('customerId'))
    && urlParams.has('hash');

  useEffect(() => {
    // Fix url params and validate.
    const urlParams = fixUrlParams(window.location.href);
    if (!isValidUrlParams(urlParams)) {
      cardRenewActions.initCardRenewalFail(cardRenewDispatch);
      return;
    }
    // Success param NOT found so its first render from Kayak e-mail link
    if (!urlParams.has('success')) {
      cardRenewActions.initCardRenewal(
        urlParams.get('customerId'),
        urlParams.get('hash'),
        cardRenewDispatch,
      );
      return;
    }
    // Success param found so its redirect back from checkout
    if (urlParams.has('success') && urlParams.get('success') !== '') {
      if (urlParams.get('success') === 'true') {
        cardRenewActions.updateToken(urlParams, cardRenewDispatch);
      }
      if (urlParams.get('success') === 'false') {
        cardRenewActions.addCardFailure(cardRenewDispatch);
      }
    }
  }, []);

  const [redirect, setRedirect] = useState(false);
  const toggleRedirect = () => {
    setRedirect(true);
  };

  const Content = ({ state }) => {
    switch (state) {
      case CARD_RENEW_INIT: {
        return (
          <>
            <Grid.Row>
              <h3>Päivitä maksukorttisi tiedot</h3>
            </Grid.Row>
            <Grid.Row>
              <p>
                Pääset muuttamaan maksukorttisi tiedot alla olevalla
                painikkeella.
              </p>
            </Grid.Row>
            <Grid.Row>
              <div className="ui active loader" />
            </Grid.Row>
          </>
        );
      }
      case CARD_RENEW_INIT_FAILURE: {
        return (
          <Grid.Row>
            <p>Jotain meni pieleen pyyntöä käsitellessä..</p>
          </Grid.Row>
        );
      }
      case CARD_RENEW_READY: {
        return (
          <>
            <Grid.Row>
              <h3>Päivitä maksukorttisi tiedot</h3>
            </Grid.Row>
            <Grid.Row>
              <p>
                Pääset muuttamaan maksukorttisi tiedot alla olevalla
                painikkeella.
              </p>
            </Grid.Row>
            <Grid.Row>
              <Button
                style={{ width: 280, margin: '0 auto', fontSize: 14 }}
                type="submit"
                id="submitOrder"
                fluid
                color="blue"
                onClick={toggleRedirect}
              >
                Siirry Paytrail -palveluun
              </Button>
              {redirect && <AddCardForm urlParams={cardRenew.params} />}
            </Grid.Row>
          </>
        );
      }
      case CARD_RENEW_ADD_CARD_FAILURE: {
        return (
          <Grid.Row>
            <p>Kortin lisäys epäonnistui tai keskeytettiin!</p>
          </Grid.Row>
        );
      }
      case CARD_RENEW_UPDATE_CARD: {
        return (
          <>
            <Grid.Row>
              <p>Päivitetään kortin tietoja taustajärjestelmiin..</p>
            </Grid.Row>
            <Grid.Row>
              <div className="ui active loader" />
            </Grid.Row>
          </>
        );
      }
      case CARD_RENEW_UPDATE_CARD_FAILURE: {
        return (
          <>
            <Grid.Row>
              <h3>Korttitietojen päivitys epäonnistui</h3>
            </Grid.Row>
            <Grid.Row>
              <p>Ongelman toistuessa ota yhteyttä asiakaspalveluun.</p>
            </Grid.Row>
          </>
        );
      }
      case CARD_RENEW_UPDATE_CARD_SUCCESS: {
        return (
          <>
            <Grid.Row>
              <h3>Korttitietojen päivitys onnistui!</h3>
            </Grid.Row>
            <Grid.Row>
              <p>Kiitos! Voit nyt sulkea tämän ikkunan.</p>
            </Grid.Row>
          </>
        );
      }
      // OTVMSUP-9 Show maintenance mode info
      case BACKEND_IN_MAINTENANCE_MODE:
        return (
          <Grid.Row>
            <div style={{ textAlign: 'center', minWidth: '0%' }}>
              <h1>
                Päivitämme palvelua. Ole hyvä ja yritä myöhemmin uudelleen.
              </h1>
              <br />
            </div>
          </Grid.Row>
        );
      default: {
        return (
          <Grid.Row>
            <p>Jotain meni pieleen..</p>
          </Grid.Row>
        );
      }
    }
  };
  return (
    <>
      <div className="layout-container">
        <div id="renewCardPage">
          <HeaderBar />

          <Grid centered className="padding">
            <Content state={cardRenew.state} />
          </Grid>
        </div>
      </div>
      <div className="footer-section">
        <Footer />
      </div>
    </>
  );
};

export default RenewCardPage;
