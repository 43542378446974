const DIGI_FRONT_PAGE = {
  AL: 'http://alibi.fi/nakoislehti/ ',
  AN: 'https://anna.fi/nakoislehti',
  DE: 'https://kotiliesi.fi/deko-nakoislehti/',
  ER: 'https://eralehti.fi/nakoislehti/',
  HY: 'https://hymy.fi/nakoislehti/',
  KI: 'https://kipparilehti.fi/nakoislehti/',
  KL: 'https://anna.fi/kotilaakari-nakoislehti/',
  KO: 'https://kotiliesi.fi/nakoislehti/',
  KU: 'https://koululainen.fi/nakoislehti/',
  MK: 'https://metsastysjakalastus.fi/arkisto',
  RM: 'https://rakennusmaailma.fi/nakoislehti/',
  SE: 'https://seura.fi/nakoislehti/',
  SK: 'https://suomenkuvalehti.fi/nakoislehti/',
  TM: 'https://tekniikanmaailma.fi/arkisto/',
  VA: 'https://seura.fi/viva-nakoislehti/',
  VE: 'https://venelehti.fi/nakoislehti/',
};

const DIGI_PAPER_CODES = {
  AL: 'Alibi',
  AN: 'Anna',
  DE: 'Deko',
  ER: 'Erä',
  HY: 'Hymy',
  KI: 'Kippari',
  KL: 'Kotilääkäri',
  KO: 'Kotiliesi',
  KU: 'Koululainen',
  MK: 'Metsästys ja Kalastus',
  RM: 'Rakennusmaailma',
  SE: 'Seura',
  SK: 'Suomenkuvalehti',
  TM: 'Tekniikan Maailma',
  VA: 'Seura',
  VE: 'Venelehti',
};

const resolveFrontPage = (paperCode) => {
  if (paperCode in DIGI_FRONT_PAGE) return DIGI_FRONT_PAGE[paperCode];
  return null;
};

module.exports = {
  DIGI_FRONT_PAGE,
  DIGI_PAPER_CODES,
  resolveFrontPage,
};
