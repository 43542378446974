/* eslint-disable react/no-danger */
import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Checkbox, Grid } from 'semantic-ui-react';

import Context from '../../../context';
import Legal from './Legal';

import {
  formatPrice,
  getBackgroundColor,
  parsePaymentMethodsFromMagazine,
  selectPaymentMethodText,
  showPriceByOrderType,
  startingDateVisibilityByOrderType,
  subscribeButtonDisabled,
} from '../../../utils/util';
import { PaymentMethods } from '../../../utils/consts';
import { ORDER_INIT } from '../../../actions/actionTypes';
import MagazineImage from './MagazineImage';
import LoadingSpinner from '../../main/LoadingSpinner';
import t from '../../../locales/fi';

const OrderSummary = (props) => {
  const { payInSingleChargeToggle } = props;
  const context = useContext(Context);
  const { offer, customer } = context;
  const { getValues } = useFormContext();
  const { selectedMagazineId, selectedGiftId, paymentMethod } = getValues();

  const optionalGifts = offer.magazines[0].optionalGifts === '1';
  const selectedGift = offer.gifts.find((g) => g.id === selectedGiftId);
  // Show gift section if gift is selected or there is non-selectable gifts
  const showGiftSummary
    = selectedGift || (!optionalGifts && offer.gifts.length > 0);
  const selectedMagazine = offer.magazines.find(
    (m) => m.id === selectedMagazineId,
  );
  const allowedMethods = parsePaymentMethodsFromMagazine(offer.magazines[0]);
  const hidePaymentMethod = !allowedMethods.card && !allowedMethods.invoice;
  const creditCardInfoText = offer?.options?.creditcardInfoText || '';
  const creditCardInfoTextKesto
    = offer?.options?.campaignpages?.creditCard?.infoTextKesto || '';
  const bankInfoText = offer?.options?.bankPaymentInfoText || '';
  const bankInfoTextKesto
    = offer?.options?.campaignpages?.bank?.infoTextKesto || '';
  const mobilePayInfoText = offer?.options?.mobilePayInfoText || '';
  const mobilePayInfoTextKesto = offer?.options?.mobilePayDurationText || '';
  const renewalPriceString = selectedMagazine?.siteOptions?.renewalPriceString;
  const backgroundColor = getBackgroundColor(null, offer);
  const invoiceInfoText
    = offer?.options?.campaignpages?.invoice?.infoText || '';

  const paymentMethodText = selectPaymentMethodText(paymentMethod);

  const bankSummaryInfoText = () => {
    if (bankInfoText && bankInfoText !== '') {
      if (
        selectedMagazine
        && selectedMagazine.salesType !== 'Määräaikainen'
        && bankInfoTextKesto
      ) {
        return <p className="font-12">{bankInfoTextKesto}</p>;
      }
      return '';
    }
    return <p className="font-12">{t.DEFAULT_BANK_INFO_TEXT}</p>;
  };

  return (
    <Grid id="orderSummary" className="padding-top-20">
      <Grid.Row style={{ background: backgroundColor }}>
        <Grid.Column width={16}>
          <h2>Yhteenveto</h2>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row style={{ background: backgroundColor }} className="pt-0">
        <Grid.Column computer={6} tablet={6} mobile={16}>
          {selectedMagazine?.packageImage && (
            <MagazineImage
              magazine={selectedMagazine}
              className="order-summary-mag-image"
            />
          )}
        </Grid.Column>
        <Grid.Column computer={8} tablet={6} mobile={16}>
          <Grid className="order-summary-text-wrapper">
            {selectedMagazine && (
              <Grid.Row style={{ paddingBottom: 0 }}>
                <Grid.Column width={16}>
                  <b>Tarjousjakso:</b>
                  <br />
                  {/* Name + duration, e.g., "Kotiliesi Käsityö LEHTI 30 € / 12 kk" */}
                  <span>{selectedMagazine.name}</span>
                  <br />
                  <span>
                    {selectedMagazine.duration}{' '}
                    {selectedMagazine.orderDurationType}{' '}
                    {showPriceByOrderType(
                      selectedMagazine.offerPrice,
                      selectedMagazine.discountPercentage,
                      selectedMagazine.kayakOrderType,
                    )}
                  </span>{' '}
                  {/* Normal price, e.g., "(norm. 60 € / 12 kk)" */}
                  {selectedMagazine.dontShowNormalPrice !== '1' && (
                    <span className="font-12">
                      {'(norm. '}
                      {selectedMagazine.duration}{' '}
                      {selectedMagazine.orderDurationType}{' '}
                      {formatPrice(selectedMagazine.normalPrice)})
                    </span>
                  )}
                  <br />
                  {/* Order type, e.g., "Jatkuva tilaus" */}
                  {selectedMagazine.salesType !== ''
                    && selectedMagazine.salesType !== 'Tuntematon' && (
                    <FormatOrderTypeText
                      selectedMagazine={selectedMagazine}
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            )}

            {showGiftSummary && (
              <Grid.Row className="no-padding">
                <Grid.Column width={16}>
                  <br />
                  <b>Tilaajalahja:</b>
                  <br />
                  {optionalGifts ? (
                    <span>
                      {selectedGift.name}
                      {selectedGift.price && selectedGift.price !== '0' && (
                        <>
                          {', '}
                          arvo {formatPrice(selectedGift.price)}
                        </>
                      )}
                    </span>
                  ) : (
                    <>
                      {offer.gifts.map((g) => (
                        <div key={g.id}>
                          <p>
                            <span>
                              {g.name}
                              {g.price && g.price !== '0' && (
                                <> Arvo {formatPrice(g.price)}</>
                              )}
                            </span>
                          </p>
                        </div>
                      ))}
                    </>
                  )}
                </Grid.Column>
              </Grid.Row>
            )}

            {paymentMethod && !hidePaymentMethod && (
              <Grid.Row style={{ paddingBottom: 0 }}>
                <Grid.Column width={16}>
                  <b>Maksutapa: </b>
                  <br />
                  <span id="payment-method-text">{paymentMethodText}</span>
                  {renewalPriceString
                  && (paymentMethod === PaymentMethods.CARD
                    || paymentMethod === PaymentMethods.MOBILEPAY) ? (
                      <span className="font-12">
                        <br />
                        {renewalPriceString}
                      </span>
                    ) : (
                      ''
                    )}
                </Grid.Column>
                {(paymentMethod === PaymentMethods.INVOICING_E_IDENT
                  || paymentMethod === PaymentMethods.INVOICING_CALL)
                  && allowedMethods.invoice
                  && selectedMagazine
                  && selectedMagazine.numOfPayments > 1 && (
                  <Grid.Column width={16}>
                    {formatPrice(
                      selectedMagazine.offerPrice,
                      selectedMagazine.discountPercentage,
                    ) !== '0'
                        && selectedMagazine.numOfPayments > 1 && (
                      <p className="font-16">
                            Voit halutessasi maksaa laskusi{' '}
                        {selectedMagazine.numOfPayments} erässä (
                        {selectedMagazine.numOfPayments} x{' '}
                        {formatPrice(
                          selectedMagazine.offerPrice,
                          selectedMagazine.discountPercentage,
                          selectedMagazine.numOfPayments,
                        )}
                            )
                      </p>
                    )}
                  </Grid.Column>
                )}
              </Grid.Row>
            )}

            {(paymentMethod === PaymentMethods.INVOICING_E_IDENT
              || paymentMethod === PaymentMethods.INVOICING_CALL)
              && allowedMethods.invoice && (
              <Grid.Row className="no-padding">
                {selectedMagazine && selectedMagazine.numOfPayments > 1 && (
                  <Grid.Column width={16}>
                    <Checkbox
                      label="Haluan maksaa tilauksen yhdessä erässä"
                      className="form-annotation form-annotation-payment-single-payment-checkbox"
                      name="payInSingleCharge"
                      onChange={() => payInSingleChargeToggle()}
                    />
                  </Grid.Column>
                )}
                <Grid.Column width={16}>
                  <div>
                    <p className="font-12">{invoiceInfoText}</p>
                  </div>
                </Grid.Column>
              </Grid.Row>
            )}

            {paymentMethod === PaymentMethods.INVOICING_PAYTRAIL
              && allowedMethods.paytrail && (
              <Grid.Row className="no-padding">
                <Grid.Column width={16}>{bankSummaryInfoText()}</Grid.Column>
              </Grid.Row>
            )}

            {paymentMethod === PaymentMethods.CARD && allowedMethods.card && (
              <Grid.Row className="no-padding">
                <Grid.Column width={16}>
                  {creditCardInfoText && creditCardInfoText !== '' ? (
                    selectedMagazine
                    && selectedMagazine.salesType !== 'Määräaikainen'
                    && creditCardInfoTextKesto && (
                      <p className="font-12">{creditCardInfoTextKesto}</p>
                    )
                  ) : (
                    <p className="font-12">
                      Voit maksaa tilauksesi seuraavilla luottokorteilla: Visa,
                      Visa Electron, MasterCard, Eurocard. Maksujen välitykseen
                      käytetään PayEx-palvelua. Korttimaksu veloitetaan
                      automaattisesti kortiltasi uuden tilausjakson alkaessa.
                      Tilauksen voi irtisanoa koska tahansa tilausehtojen
                      mukaisesti.
                    </p>
                  )}
                </Grid.Column>
              </Grid.Row>
            )}

            {paymentMethod === PaymentMethods.MOBILEPAY
              && allowedMethods.mobilepay && (
              <Grid.Row className="no-padding">
                <Grid.Column width={16}>
                  {mobilePayInfoText
                      && mobilePayInfoText !== ''
                      && selectedMagazine
                      && selectedMagazine.salesType !== 'Määräaikainen'
                      && mobilePayInfoTextKesto && (
                    <p className="font-12">{mobilePayInfoTextKesto}</p>
                  )}
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row className="pb-0">
        <Grid.Column width={16}>
          <Legal offer={offer} customer={customer} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={16}>
          {offer.state === ORDER_INIT ? (
            <LoadingSpinner label="Tilaustasi käsitellään..." />
          ) : (
            <button
              disabled={subscribeButtonDisabled(
                offer,
                selectedMagazineId,
                selectedGiftId,
                paymentMethod,
              )}
              className="subscribe-button"
              type="submit"
              id="submitButton"
            >
              Tilaa
            </button>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const FormatOrderTypeText = ({ selectedMagazine }) => {
  const startingDateText = startingDateVisibilityByOrderType(
    selectedMagazine.startBeginning,
    selectedMagazine.orderStarts,
    selectedMagazine.kayakOrderType,
  );
  if (startingDateText) {
    return (
      <span>
        {selectedMagazine.salesType}
        {startingDateVisibilityByOrderType(
          selectedMagazine.startBeginning,
          selectedMagazine.orderStarts,
          selectedMagazine.kayakOrderType,
        )}{' '}
        *
      </span>
    );
  }
  return <span>{selectedMagazine.salesType} *</span>;
};

export default OrderSummary;
