/* eslint-disable import/prefer-default-export */
/**
 * Get logo by brand. Brand is essentially the paperCode of a magazine productLines.
 * In emergency situations 2 first characters of packageId can also be used
 * @param {string} brand
 * @returns {image}
 */
export const logoSrcByBrand = (brand) => {
  switch (brand) {
    case 'AL':
      return '/images/logos/ALIBI_logo.svg';
    case 'AN':
      return '/images/logos/ANNA_logo.svg';
    case 'DE':
      return '/images/logos/DEKO_logo.svg';
    case 'ER':
      return '/images/logos/ERÄ_logo.svg';
    case 'HY':
      return '/images/logos/HYMY_logo.svg';
    case 'KA':
      return '/images/logos/KANAVA_logo.svg';
    case 'KI':
      return '/images/logos/KIPPARI_logo.svg';
    case 'KL':
      return '/images/logos/KOTILÄÄKÄRI_logo.svg';
    case 'KO':
      return '/images/logos/KOTILIESI_logo.svg';
    case 'KU':
      return '/images/logos/KOULULAINEN_logo.svg';
    case 'NK':
      return '/images/logos/KOTILIESI_KÄSITYÖ_logo.svg';
    case 'ML':
      return '/images/logos/MAALLA_logo.svg';
    case 'MK':
      return '/images/logos/METSÄSTYS_JA_KALASTUS_logo.svg';
    case 'PA':
      return '/images/logos/PARNASSO_logo.svg';
    case 'RM':
      return '/images/logos/RAKENNUSMAAILMA_logo.svg';
    case 'SE':
      return '/images/logos/SEURA_logo.svg';
    case 'SK':
      return '/images/logos/SUOMEN_KUVALEHTI_new_logo.svg';
    case 'SR':
      return '/images/logos/SUPERRISTIKOT_logo.svg';
    case 'TM':
      return '/images/logos/TEKNIIKAN_MAAILMA_logo.svg';
    case 'VA':
      return '/images/logos/VIVA_logo.svg';
    case 'VE':
      return '/images/logos/VENE_logo.svg';
    case 'VM':
      return '/images/logos/VAUHDIN_MAAILMA_logo.svg';
    default:
      return '/images/logos/OTAVAMEDIA_logo.svg';
  }
};
