import React from 'react';

const PaywallSubAndAccountStatus = (props) => {
  const { subscription } = props;
  const style = {
    maxWidth: '400px',
  };
  const orderFailed = 'Tilauksesi käsitellään asiakaspalvelussa.';
  const otavamediaAccountExisted
    = 'Antamallasi sähköpostiosoitteella on Otavamedia-tili. Mikäli olet unohtanut salasanasi, voit vaihtaa sen kirjautumisen yhteydessä.';
  const otavamediaAccountCreated
    = 'Antamallasi sähköpostiosoitteella on luotu Otavamedia-tili. Vahvista Otavamedia-tili ja luo tilille salasana sähköpostiisi lähetetystä linkistä 7 päivän kuluessa.';
  const otavamediaAccountFailed
    = 'Otavamedia-tilin luominen ei onnistunut antamillasi tiedoilla, ota yhteyttä asiakaspalveluun';
  const readApproval
    = 'Voit nyt käyttää palvelua kirjautumatta 72\xA0tuntia. Tämän jälkeen kirjautuminen vaatii Otavamedia-tilin.';

  const CustomerSupportInfo = () => (
    <p>
      (09)&nbsp;156&nbsp;6227 /&nbsp;
      <a href="mailto:asiakaspalvelu@otavamedia.fi">digi@otavamedia.fi</a>
    </p>
  );

  const { subscriptionSuccessful } = subscription;
  switch (subscription.otavamediaAccount) {
    case 'existed':
      return (
        <div style={style}>
          {!subscriptionSuccessful && <p>{orderFailed}</p>}
          <p>{readApproval}</p>
          <p>{otavamediaAccountExisted}</p>
        </div>
      );
    case 'created':
      return (
        <div style={style}>
          {!subscriptionSuccessful && <p>{orderFailed}</p>}
          <p>{readApproval}</p>
          <p>{otavamediaAccountCreated}</p>
        </div>
      );
    case 'failed':
      return (
        <div style={style}>
          {subscriptionSuccessful ? (
            <p>{readApproval}</p>
          ) : (
            <p>{orderFailed}</p>
          )}
          <p>{otavamediaAccountFailed}</p>
          <CustomerSupportInfo />
        </div>
      );
    default:
      console.log('Did not recieve otavamedia account status from backend!');
      return (
        <div style={style}>
          {subscriptionSuccessful ? (
            <p>{readApproval}</p>
          ) : (
            <p>{orderFailed}</p>
          )}
          <p>{otavamediaAccountFailed}</p>
          <CustomerSupportInfo />
        </div>
      );
  }
};

export default PaywallSubAndAccountStatus;
