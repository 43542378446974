import * as api from '../api/Api';

import {
  BACKEND_IN_MAINTENANCE_MODE,
  INVOICING_CHANGE_CARD_EMAIL_FORM,
  INVOICING_CHANGE_CARD_ADD_FAILURE,
  INVOICING_CHANGE_CARD_ADD_INIT,
  INVOICING_CHANGE_CARD_ADD_REDIRECT,
  INVOICING_CHANGE_CHARGE_CARD,
  INVOICING_CHANGE_CHARGE_CARD_FAILURE,
  INVOICING_CHANGE_CHARGE_CARD_SUCCESS,
  INVOICING_CHANGE_E_INVOICING_CHOOSE_BANK,
  INVOICING_CHANGE_E_INVOICING_FAILURE,
  INVOICING_CHANGE_E_INVOICING_SEND,
  INVOICING_CHANGE_E_INVOICING_SSN_FORM,
  INVOICING_CHANGE_E_INVOICING_SUCCESS,
  INVOICING_CHANGE_MOBILEPAY_INVOICING_INIT,
  INVOICING_CHANGE_ORDER_FETCH,
  INVOICING_CHANGE_ORDER_FETCH_FAILURE,
  INVOICING_CHANGE_ORDER_FETCH_SUCCESSFUL,
  INVOICING_CHANGE_TYRKYTE_EXPIRED,
  INVOICING_CHANGE_TYRKYTE_USED,
  INVOICING_CHANGE_CARD_ONLY_FORM,
} from './actionTypes';
import { ErrorCodes } from '../utils/consts';

export const fetchSubscriptionAndCustomer = async (
  params,
  path,
  invoicingChangeDispatch,
) => {
  invoicingChangeDispatch({
    type: INVOICING_CHANGE_ORDER_FETCH,
    payload: {
      params: params.toString(),
      tyrkyteId: params.get('ty'),
      offerId: params.get('offerId'),
    },
  });
  let response = null;
  try {
    response = await api.get('/invoicing-change/subscription', params);
    if (response.error || response.errorCode || response.errorText) {
      // OTVMSUP-9, backend in maintenance mode
      if (response.status && response.status === 503) {
        invoicingChangeDispatch({ type: BACKEND_IN_MAINTENANCE_MODE });
        return;
      }
      if (response.errorCode === ErrorCodes.TYRKYTE_INVALID_EXPIRED) {
        invoicingChangeDispatch({ type: INVOICING_CHANGE_TYRKYTE_EXPIRED });
        return;
      }
      if (response.errorCode === ErrorCodes.TYRKYTE_INVALID_ALREADY_USED) {
        invoicingChangeDispatch({ type: INVOICING_CHANGE_TYRKYTE_USED });
        return;
      }
      invoicingChangeDispatch({
        type: INVOICING_CHANGE_ORDER_FETCH_FAILURE,
        payload: { error: response.errorText },
      });
      return;
    }
  } catch (e) {
    invoicingChangeDispatch({
      type: INVOICING_CHANGE_ORDER_FETCH_FAILURE,
      payload: { error: e.message },
    });
    return;
  }
  if (path === 'kortti') {
    invoicingChangeDispatch({
      type: INVOICING_CHANGE_CARD_ONLY_FORM,
      payload: {
        path,
        subscription: response.subscription,
        options: response.options,
        tyrkyte: response.tyrkyte,
        offerDetails: response.offerDetails,
      },
    });
    return;
  }
  invoicingChangeDispatch({
    type: INVOICING_CHANGE_ORDER_FETCH_SUCCESSFUL,
    payload: {
      path,
      subscription: response.subscription,
      options: response.options,
      tyrkyte: response.tyrkyte,
      offerDetails: response.offerDetails,
    },
  });
};

export const initCardInvoicing = async (invoicingChangeDispatch) => {
  invoicingChangeDispatch({ type: INVOICING_CHANGE_CARD_EMAIL_FORM });
};

/**
 * Card payment initializing.
 *
 * @returns {function(...[*]=)}
 */
export const initAddCardForm = async (
  offerId,
  subscriptionId,
  tyrkyteId,
  email,
  path,
  invoicingChangeDispatch,
) => {
  invoicingChangeDispatch({ type: INVOICING_CHANGE_CARD_ADD_INIT });
  const url = `/invoicing-change/creditcard?offerId=${offerId}&subscriptionId=${subscriptionId}&tyrkyteId=${tyrkyteId}&email=${email}&path=${path}`;
  const response = await api.get(url);
  if (response.error) {
    // OTVMSUP-9, backend in maintenance mode
    if (response.status && response.status === 503) {
      invoicingChangeDispatch({ type: BACKEND_IN_MAINTENANCE_MODE });
      return;
    }
    invoicingChangeDispatch({
      type: INVOICING_CHANGE_CARD_ADD_FAILURE,
      payload: {
        ...response,
      },
    });
  }
  invoicingChangeDispatch({
    type: INVOICING_CHANGE_CARD_ADD_REDIRECT,
    payload: {
      ...response,
    },
  });
};

export const chargeCard = async (params, invoicingChangeDispatch) => {
  invoicingChangeDispatch({ type: INVOICING_CHANGE_CHARGE_CARD });
  let response = null;
  try {
    // Params are returned straight from paytrail add card form
    response = await api.get('/invoicing-change/creditcard/charge', params);
    if (response.error) {
      // OTVMSUP-9, backend in maintenance mode
      if (response.status && response.status === 503) {
        invoicingChangeDispatch({ type: BACKEND_IN_MAINTENANCE_MODE });
        return;
      }
      // Charge card also checks for tyrkyte since it is after redirect
      if (response.errorText === 'Tyrkyte was expired') {
        invoicingChangeDispatch({ type: INVOICING_CHANGE_TYRKYTE_EXPIRED });
        return;
      }
      if (response.errorText === 'Tyrkyte was used') {
        invoicingChangeDispatch({ type: INVOICING_CHANGE_TYRKYTE_USED });
        return;
      }
      invoicingChangeDispatch({
        type: INVOICING_CHANGE_CHARGE_CARD_FAILURE,
        payload: { error: response.errorText },
      });
    } else {
      invoicingChangeDispatch({
        type: INVOICING_CHANGE_CHARGE_CARD_SUCCESS,
        payload: { ...response },
      });
    }
  } catch (e) {
    invoicingChangeDispatch({
      type: INVOICING_CHANGE_CHARGE_CARD_FAILURE,
      payload: { error: e.message },
    });
  }
};

export const initEInvoicing = async (invoicingChangeDispatch) => {
  invoicingChangeDispatch({ type: INVOICING_CHANGE_E_INVOICING_CHOOSE_BANK });
};

export const setBank = async (bank, invoicingChangeDispatch) => {
  invoicingChangeDispatch({
    type: INVOICING_CHANGE_E_INVOICING_SSN_FORM,
    payload: { ...bank },
  });
};

export const submitEInvoice = async (data, invoicingChangeDispatch) => {
  const eInvoice = {
    tyrkyteId: data.tyrkyteId,
    firstName: data.subscription.subscriber.firstName,
    lastName: data.subscription.subscriber.lastName,
    ssn: data.eInvoice.ssn,
    customerId:
      data.subscription.payer?.customerId
      || data.subscription.subscriber.customerId,
    bic: data.eInvoice.bic,
  };
  invoicingChangeDispatch({
    type: INVOICING_CHANGE_E_INVOICING_SEND,
    payload: {
      eInvoice,
    },
  });
  let response = null;
  try {
    response = await api.post('/invoicing-change/e-invoice', eInvoice);
    if (response.error) {
      // OTVMSUP-9, backend in maintenance mode
      if (response.status && response.status === 503) {
        invoicingChangeDispatch({ type: BACKEND_IN_MAINTENANCE_MODE });
        return;
      }
      invoicingChangeDispatch({
        type: INVOICING_CHANGE_E_INVOICING_FAILURE,
        payload: { error: response.errorText },
      });
    } else {
      invoicingChangeDispatch({
        type: INVOICING_CHANGE_E_INVOICING_SUCCESS,
      });
    }
  } catch (e) {
    invoicingChangeDispatch({
      type: INVOICING_CHANGE_E_INVOICING_FAILURE,
      payload: { error: e.message },
    });
  }
};

export const initMobilePayInvoicing = async (invoicingChangeDispatch) => {
  invoicingChangeDispatch({ type: INVOICING_CHANGE_MOBILEPAY_INVOICING_INIT });
};

export const moveBack = async (
  invoiceChangeObject,
  invoicingChangeDispatch,
) => {
  switch (invoiceChangeObject.state) {
    // Card payment
    case INVOICING_CHANGE_CARD_EMAIL_FORM: {
      invoicingChangeDispatch({
        type: INVOICING_CHANGE_ORDER_FETCH_SUCCESSFUL,
        payload: { ...invoiceChangeObject },
      });
      break;
    }
    // e-invoice
    case INVOICING_CHANGE_E_INVOICING_CHOOSE_BANK: {
      invoicingChangeDispatch({
        type: INVOICING_CHANGE_ORDER_FETCH_SUCCESSFUL,
        payload: { ...invoiceChangeObject },
      });
      break;
    }
    case INVOICING_CHANGE_E_INVOICING_SSN_FORM: {
      invoicingChangeDispatch({
        type: INVOICING_CHANGE_E_INVOICING_CHOOSE_BANK,
        payload: { ...invoiceChangeObject },
      });
      break;
    }
    default:
  }
};
