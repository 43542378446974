import React from 'react';

import PopupTrigger from './PopupTrigger';

import { POPUP_TYPE } from '../../../utils/util';

const MagazineImage = ({ magazine, className }) => (
  <div id="magazineImage" className={className}>
    <PopupTrigger type={POPUP_TYPE.MAGAZINE_IMAGE} magazine={magazine} />
  </div>
);

export default MagazineImage;
