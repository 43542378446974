import React from 'react';
import { Grid } from 'semantic-ui-react';

import PopupTrigger from './PopupTrigger';
import SelectButton from './SelectButton';

import {
  formatPrice,
  POPUP_TYPE,
  showPriceByOrderType,
  startingDateVisibilityByOrderType,
} from '../../../utils/util';

const MagazineInfoText = (props) => {
  const { magazine, selectButton, carouselExtraImage, singleItem } = props;

  return (
    <Grid id="magazineInfoText" className="magazine-info-text-grid">
      <Grid.Row className="no-padding mag-info-above-select">
        <Grid.Column width={16} className="no-padding selected-magazine-column">
          {/* Name + duration, e.g., "Kotiliesi Käsityö LEHTI 6 nroa 49 €" */}
          <div className="name-duration-wrapper">
            <span>{magazine.name}</span>
            <br />
            <b className="duration-and-price">
              {magazine.duration} {magazine.orderDurationType}{' '}
              {showPriceByOrderType(
                magazine.offerPrice,
                magazine.discountPercentage,
                magazine.kayakOrderType,
              )}
            </b>
          </div>
          {/* Normal price, e.g., "(norm. 94,20 €)" */}
          {magazine.dontShowNormalPrice !== '1' && (
            <div className="font-12">
              (norm. {formatPrice(magazine.normalPrice)})
            </div>
          )}
          <Grid.Column
            width={16}
            className={`no-padding ${
              singleItem ? 'text-align-single' : 'text-align-multiple'
            }`}
          >
            {/* Order type, e.g., "Jatkuva tilaus" */}
            {magazine.salesType !== ''
              && magazine.salesType !== 'Tuntematon' && (
              <p className="font-12 selected-magazine-column">
                {magazine.salesType}
                {startingDateVisibilityByOrderType(
                  magazine.startBeginning,
                  magazine.orderStarts,
                  magazine.kayakOrderType,
                )}
              </p>
            )}
            {/* Mag/brand image */}
          </Grid.Column>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="no-padding">
        <Grid.Column
          width={16}
          className={`no-padding ${
            singleItem ? 'text-align-single' : 'text-align-multiple'
          }`}
        >
          {selectButton ? (
            <SelectButton
              value={magazine.id}
              name="selectedMagazineId"
              singleItem={singleItem}
            />
          ) // if there is no button there should some space between
            : null}
        </Grid.Column>
        <Grid.Column
          width={16}
          className={`mobile-text-align-center package-description-wrapper ${
            singleItem ? 'text-align-single' : 'text-align-multiple'
          }`}
        >
          {/* Lue lisää >> */}
          {magazine.packageDescription && (
            <PopupTrigger type={POPUP_TYPE.MAGAZINE} magazine={magazine} />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default MagazineInfoText;
